import { useRef } from "react";
import { useUserDispatch } from "../../../Context";
import Map from "../Map";

import PaymentForm from "./Form";

const PaymentrDetails = ({ onTabChange }) => {
  const userDispatch = useUserDispatch();
  const formRef = useRef();

  const onSubmit = (data) => {
    onTabChange("confirm");
    // userDispatch({ type: "PAYMENTDETAILS", payload: { data: data } });
  };

  return (
    <div className="md:flex">
      <div className="overflow-auto md:pt-12 md:w-1/2">
        <PaymentForm onSubmit={onSubmit} formRef={formRef} />
      </div>
      <div className="inner-page md:w-1/3 md:px-10 md:flex-grow">
        <Map onTabChange={onTabChange} page="payment" formRef={formRef} />
      </div>
    </div>
  );
};

export default PaymentrDetails;
