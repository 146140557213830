import { DirectionsRenderer } from "@react-google-maps/api";
const { Component } = require("react");
const {
  GoogleMap,
  LoadScript,
  DirectionsService,
} = require("@react-google-maps/api");

class Directions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response: null,
      travelMode: "DRIVING",
      origin: this.props.source,
      destination: this.props.destination,
      hasSetState: false,
    };

    this.directionsCallback = this.directionsCallback.bind(this);
    this.checkDriving = this.checkDriving.bind(this);
    this.checkBicycling = this.checkBicycling.bind(this);
    this.checkTransit = this.checkTransit.bind(this);
    this.checkWalking = this.checkWalking.bind(this);
    this.getOrigin = this.getOrigin.bind(this);
    this.getDestination = this.getDestination.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
  }

  directionsCallback(response) {
    // console.log(response);

    if (!this.state.hasSetState && response !== null) {
      if (response.status === "OK") {
        this.setState(() => ({
          response,
          hasSetState: true,
        }));
      } else {
        console.log("response: ", response);
      }
    }
  }

  checkDriving({ target: { checked } }) {
    checked &&
      this.setState(() => ({
        travelMode: "DRIVING",
      }));
  }

  checkBicycling({ target: { checked } }) {
    checked &&
      this.setState(() => ({
        travelMode: "BICYCLING",
      }));
  }

  checkTransit({ target: { checked } }) {
    checked &&
      this.setState(() => ({
        travelMode: "TRANSIT",
      }));
  }

  checkWalking({ target: { checked } }) {
    checked &&
      this.setState(() => ({
        travelMode: "WALKING",
      }));
  }

  getOrigin(ref) {
    this.origin = ref;
  }

  getDestination(ref) {
    this.destination = ref;
  }

  onClick() {
    if (this.origin.value !== "" && this.destination.value !== "") {
      this.setState(() => ({
        origin: this.origin.value,
        destination: this.destination.value,
      }));
    }
  }

  onMapClick(...args) {
    console.debug("onClick args: ", args);
  }

  render() {
    return (
      <div className="map">
        <div className="map-container">
          <GoogleMap
            // required
            id="direction-example"
            // required
            mapContainerStyle={{
              height: "200px",
              width: "100%",
            }}
            // required
            zoom={2}
            // required
            center={{
              lat: 0,
              lng: -180,
            }}
            // optional
            onClick={this.onMapClick}
            // optional
            onLoad={(map) => {
              console.debug("DirectionsRenderer onLoad map: ", map);
            }}
            // optional
            onUnmount={(map) => {
              console.debug("DirectionsRenderer onUnmount map: ", map);
            }}
          >
            {this.state.destination !== "" && this.state.origin !== "" && (
              <DirectionsService
                // required
                options={{
                  destination: this.state.destination,
                  origin: this.state.origin,
                  travelMode: this.state.travelMode,
                }}
                // required
                callback={this.directionsCallback}
                // optional
                onLoad={(directionsService) => {
                  console.debug(
                    "DirectionsService onLoad directionsService: ",
                    directionsService
                  );
                }}
                // optional
                onUnmount={(directionsService) => {
                  console.debug(
                    "DirectionsService onUnmount directionsService: ",
                    directionsService
                  );
                }}
              />
            )}

            {this.state.response !== null && (
              <DirectionsRenderer
                // required
                options={{
                  directions: this.state.response,
                }}
                // optional
                onLoad={(directionsRenderer) => {
                  console.debug(
                    "DirectionsRenderer onLoad directionsRenderer: ",
                    directionsRenderer
                  );
                }}
                // optional
                onUnmount={(directionsRenderer) => {
                  console.debug(
                    "DirectionsRenderer onUnmount directionsRenderer: ",
                    directionsRenderer
                  );
                }}
              />
            )}
          </GoogleMap>
        </div>
      </div>
    );
  }
}

export default Directions;
