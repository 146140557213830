import { Field, useFormikContext } from "formik";
import { components } from "react-select";
import Places from "./AutoLocation";
import classNames from "classnames";
import { useEffect, useState } from "react";

const DropOffComponent = ({ setFieldValue, type }) => {
  const [destination, setDestination] = useState()

  // useEffect(() => {
  //   setDestination(setFieldValue(type, "").label || "");
  //   console.log(destination);
  // }, []);

  const onChange = (data, setErrors, errors) => {
    setFieldValue(type, data);
    setErrors({ ...errors, [type]: "" });
  };

  // const removeValue = () => {
  //   setFieldValue(type, "");
  // };

  return (
    <Field name={type}>
      {({ form: { errors, setErrors }, meta, field: { value } }) => {
        // setDestination(value.label ? value : "")
        // console.log("value", value);
        return (
          <div className="w-full ">
            <Places
              onChange={(value) => {
                if (value) {
                  onChange(value, setErrors, errors)
                }
              }}
              meta={meta}
              value={value}
              errors={errors}
              type={type}
              placeholder={
                type == "source" ? "Pickup location" : "Dropoff location"
              }
            />
            {meta.error && (
              <p className={classNames(["text-red-500 text-xs pt-2"])}>
                {meta.error}
              </p>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default DropOffComponent;
