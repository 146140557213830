import React from "react";
import "./welcome.css";

const Welcome = () => {
  return (
    <div class="wrapper">
      <h1>
        coming soon<span class="dot">.</span>
      </h1>
      <div class="para">
        <p>
          For booking call us at <a href="tel:0208 432 4325"> 0208 432 4325 </a>
        </p>
      </div>
      {/* <div class="icons">
        <a href="">
          <i class="fa fa-twitter"></i>
        </a>
        <a href="">
          <i class="fa fa-youtube-play"></i>
        </a>
        <a href="">
          <i class="fa fa-paper-plane"></i>
        </a>
      </div> */}
    </div>
  );
};

export default Welcome;
