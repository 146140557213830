import { useState } from "react";
import { Button } from "@bigbinary/neetoui";
import { DatePicker } from "baseui/datepicker";
import {
  ThemeProvider,
  createTheme,
  darkThemePrimitives
} from "baseui";
import { TimePicker } from "baseui/timepicker";
import { Checkbox as FormikCheckbox } from "@bigbinary/neetoui/formik";
import classNames from "classnames";
import { Field, FieldArray, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import DurationIcon from "../../assets/duration.svg";
import * as dayjs from "dayjs";
import enGB from 'date-fns/locale/en-GB';

import { useUserDispatch, useUserState } from "../../Context";

import ServicesApi from "../../api/passengerDetails";
import PickupComponent from "./PickupComponent";
import DropOffComponent from "./DropOffComponent";
import Places from "./AutoLocation";
import close from '../../assets/close.png'
import { dateTimeCombine } from "../../Utils/payloadTransform";
import { dateFormater } from "../../Utils/payloadTransform";
import { PICKUP_SCHEMA } from "../../Utils/formikValidation";
import { HOURLY_SCHEMA } from "../../Utils/formikValidation";

import Arrow from "../../assets/bothArrow.svg";
import { useEffect } from "react";

const PickupForm = ({ setFormType, setShowAvailability, showAvailability }) => {
  useEffect(() => {
    // console.log(userState);
    let isMounted = true;
    const changeTab = async () => {
      try {
        if (isMounted && formType === 'airport2airport') {
          let payload = {
            ...userState,
            destination: "",
            source: "",
            time: "",
            date: "",
            stops: [],
            returnBooking: false
          }
          userDispatch({ type: "JOURNEYDETAILS", payload });
          userDispatch({ type: "FORMTYPE", payload: 'airport' })
        }
      } catch (error) {
        console.error('Error handling tab change:', error);
      }
    };
    changeTab();
    return () => {
      isMounted = false;
    };
  }, [])
  const currentDate = new Date()
  const userState = useUserState().journeyInfo;
  const busOnly = useUserState().busOnly
  const formType = useUserState().formType
  const [exchangeState, setExchangeState] = useState(true);
  const [tripHour, setTripHour] = useState(5);
  const [pickupDate, setPickupDate] = useState(
    userState.date ? userState.date : new Date()
  );
  const [pickupTime, setPickupTime] = useState(
    userState.time ? userState.time : new Date(
      Math.ceil(new Date().getTime() / (5 * 60 * 1000)) * (5 * 60 * 1000)
    )
  );
  const minTime = pickupDate.getDate() === currentDate.getDate() ? new Date(
    Math.ceil(new Date().getTime() / (5 * 60 * 1000)) * (5 * 60 * 1000)
  ) : ""
  const [dropoffTime, setDropoffTime] = useState()
  const [isSameDate, setIsSameDate] = useState(false);
  const history = useHistory();
  const userDispatch = useUserDispatch();
  // const formatDate = (date) => {
  //   // Format the date as DD/MM/YY
  //   return date.toLocaleDateString('en-GB', {
  //     day: 'numeric',
  //     month: 'numeric',
  //     year: '2-digit',
  //   });
  // };
  // console.debug("uservalue", userState);

  // useEffect(() => {
  // console.log(
  //   "dateValuesTesting",
  //   pickupTime,
  //   new Date(),
  //   dayjs(new Date()).isSame(pickupTime)
  // );
  // if (dayjs(new Date()).isSame(pickupTime)) {
  //   setIsSameDate(true);
  // } else if (isSameDate) {
  //   setIsSameDate(false);
  // }
  //   console.log("destination", userState.destination);
  // }, [userState.destination]);

  const onExchangeClick = (values, setFieldValue, setErrors, errors, e) => {
    e.preventDefault()
    let temp = values.source;
    setFieldValue("source", values.destination);
    setFieldValue("destination", values.source);
    setExchangeState((state) => !state);
    setErrors({ ...errors, source: "", destination: "" });
  };

  const AddStopClick = (values, setFieldValue) => {
    if (values.stops.length < 3) setFieldValue("stops", [...values.stops, ""]);
  };

  const removeItem = (values, setFieldValue, index) => {
    let newArray = [...values.stops];
    newArray.splice(index, 1);

    setFieldValue("stops", newArray);
  };

  const intialState = {
    source: userState.source,
    destination: userState.destination,
    stops: userState.stops,
    returnBooking: userState.returnBooking,
    date: userState.date,
    time: userState.time,
    returnDate: userState.returnDate,
    returnTime: userState.returnTime,
  };

  const handleTabChange = (tab) => {
    let payload = {
      ...userState,
      destination: "",
      source: "",
      time: "",
      date: "",
      stops: [],
    }
    userDispatch({ type: "JOURNEYDETAILS", payload });
    userDispatch({ type: "FORMTYPE", payload: tab })
    // console.log(userState);
  }

  const onSubmit = async (data) => {
    data.date = pickupDate;
    data.time = pickupTime;
    let payload

    data.isAirport = data.source._id ? true : false;
    let dropOfflist = [...data.stops];
    let labelList = dropOfflist.map((item) => {
      return item.label;
    });
    labelList.push(data.destination.label);

    if (formType === "hourly") {
      userDispatch({ type: "JOURNEYDETAILS", payload: data });
      payload = {
        pickup: data.source.label,
        trip_date_time: dateFormater(
          dateTimeCombine(data.date, new Date(data.time))
        ),
        trip_hr: parseInt(tripHour, 10),
      }
      // userDispatch({ type: "JOURNEYDETAILS", payload: data });
      const { data: res } = await ServicesApi.HourlyBooking(payload);
      const newData = {
        ...res,
        duration: tripHour + ' hr(s)'
      };
      userDispatch({ type: "VEHICLELIST", payload: newData });
      // console.log("Data",newData);
      history.push("/tabs/vehicle");
    } else if (formType === "intercity") {
      payload = {
        pickup: data.source.label,
        dropoff: labelList,
        is_return: data.returnBooking,
        airpot_id: data.source?._id,
        airpot_name: data.source?.Label,
        is_source_airpot: data.source._id ? true : false,
        trip_date_time: dateFormater(
          dateTimeCombine(data.date, new Date(data.time))
        ),
      }
      if (data.returnBooking)
        payload.return_date_time = dateTimeCombine(
          data.returnDate,
          data.returnTime
        );
      userDispatch({ type: "JOURNEYDETAILS", payload: data });
      const { data: res } = await ServicesApi.IntercityBooking(payload);
      // console.log("Data",res);
      userDispatch({ type: "VEHICLELIST", payload: res });
      history.push("/tabs/vehicle");
    }
    else {
      payload = {
        pickup: data.source.label,
        dropoff: labelList,
        is_return: data.returnBooking,
        airpot_id: data.source?._id,
        airpot_name: data.source?.Label,
        is_source_airpot: data.source._id ? true : false,
        trip_date_time: dateFormater(
          dateTimeCombine(data.date, new Date(data.time))
        ),
      }
      if (data.returnBooking)
        payload.return_date_time = dateTimeCombine(
          data.returnDate,
          data.returnTime
        );
      userDispatch({ type: "JOURNEYDETAILS", payload: data });
      const { data: res } = await ServicesApi.availbleServicesApi(payload);
      // console.log("Data",res);
      userDispatch({ type: "VEHICLELIST", payload: res });
      history.push("/tabs/vehicle");
    }
  };

  return (
    <div className="pickup-form-wrap">
      <div
        className={`flex flex-col items-center w-[92%] md:min-w-[450px] mx-[15px] md:mx-auto h-auto md:px-[15px] py-4 overflow-y-auto rounded-[30px] bg-[#6D6C6AA3] overflow-hidden relative ${showAvailability ? "z-[100]" : ""}
        `}>
        {showAvailability &&
          <div className="w-full flex justify-end pr-[20px] py-[10px]">
            <img
              src={close}
              className="h-[17px] w-[17px] rounded-full text-black hover:cursor-pointer absolute top-[20px] right-[25px]"
              onClick={() => {
                setShowAvailability(false)
                userDispatch({ type: "BUSONLY", payload: false });
              }}
            ></img>
          </div>
        }

        <div className="h-[61px] w-[90%] md:w-[30rem] bg-[#7952B3] rounded-[20px] mb-[36px] flex items-center justify-evenly select-none mx-[20px] md:mx-[30px] mt-4 py-[7px]">
          <span
            className={`px-[10px] text-[16px] md:text-[18px] text-center font-[500] hover:cursor-pointer ${formType === 'airport' ? 'text-[#FFD705]' : 'text-[#FFFFFF]'}`}
            onClick={() => {
              // userDispatch({ type: "FORMTYPE", payload: 'airport' })
              handleTabChange('airport')
            }}
          >Airport Transfer</span>

          <span
            className={`px-[10px] text-[16px] md:text-[18px] text-center font-[500] hover:cursor-pointer ${formType === 'hourly' ? 'text-[#FFD705]' : 'text-[#FFFFFF]'}`}
            onClick={() => {
              // userDispatch({ type: "FORMTYPE", payload: 'hourly' })
              handleTabChange('hourly')
            }}
          >Hourly Bookings</span>
          {!busOnly &&
            <span
              className={`px-[10px] text-[16px] md:text-[18px] text-center font-[500] hover:cursor-pointer ${formType === 'intercity' ? 'text-[#FFD705]' : 'text-[#FFFFFF]'}`}
              onClick={() => {
                // userDispatch({ type: "FORMTYPE", payload: 'intercity' })
                handleTabChange('intercity')
              }}
            >Intercity Transfer</span>
          }
        </div>

        <div className="w-full flex justify-center">
          <ThemeProvider
            theme={createTheme(
              darkThemePrimitives,
              {
                colors: {
                  calendarHeaderForeground: "#000000",
                  calendarDayBackgroundSelectedHighlighted: "#000000",
                  inputFillDisabled: "#FFFFFF",
                  inputFill: "#00000",
                  inputBorder: "#FFFFFF"
                }
              }
            )}
          >
            {/* Airport Transfer */}
            {formType === 'airport' &&
              <Formik
                initialValues={intialState}
                onSubmit={onSubmit}
                validationSchema={PICKUP_SCHEMA}
                validateOnChange={false}
              >
                {({ values, setFieldValue, setErrors, errors }) => {
                  return (
                    <Form className="pickup-form flex flex-col items-center justify-center w-full space-y-5">
                      {exchangeState ? (
                        <PickupComponent
                          setFieldValue={setFieldValue}
                          type={"source"}
                        />
                      ) : (
                        <DropOffComponent
                          setFieldValue={setFieldValue}
                          type={"source"}
                        />
                      )}
                      <button
                        className="px-6"
                        onClick={(e) => {
                          onExchangeClick(values, setFieldValue, setErrors, errors, e);
                        }}
                        aria-label="Switch locations"
                      >
                        <img src={Arrow} width="20px" height="20px" />
                      </button>
                      <FieldArray
                        name="stops"
                        render={(arrayHelpers) => (
                          <>
                            {values?.stops?.map((item, index) => {
                              console.debug("items", item);
                              return (
                                <div className="flex flex-col items-center justify-center w-full space-y-5">
                                  <div
                                    key={index}
                                    className="relative flex justify-between w-full "
                                  >
                                    <Field name={`stops.${index}`}>
                                      {({ form: { errors }, meta }) => {
                                        return (
                                          <div className="w-full">
                                            <Places
                                              onChange={(data) => {
                                                setFieldValue(`stops.${index}`, data);
                                                let stops = errors.stops
                                                  ? [...errors.stops]
                                                  : [];
                                                stops[index] = "";
                                                setErrors({
                                                  ...errors,
                                                  stops: stops,
                                                });
                                              }}
                                              meta={meta}
                                              errors={errors}
                                              placeholder={"Stop " + (index + 1)}
                                              close={true}
                                              onClose={(data) => {
                                                console.debug("onclose");
                                                arrayHelpers.remove(index);
                                              }}
                                              value={item}
                                            />
                                            <p
                                              className={classNames([
                                                "text-red-500 text-xs pt-2",
                                              ])}
                                            >
                                              {meta.error ? errors.stops[index] : ""}
                                            </p>
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      />

                      <div className="relative flex flex-col w-full space-x-3 space-y-3 mx-auto">
                        {!exchangeState ? (
                          <PickupComponent
                            setFieldValue={setFieldValue}
                            type={"destination"}
                          />
                        ) : (
                          <DropOffComponent
                            setFieldValue={setFieldValue}
                            type={"destination"}
                            destination={useUserState.destination}
                          />
                        )}
                      </div>
                      <div className="relative flex flex-col w-full space-x-3 space-y-3 mx-auto">
                        <div className="pickup-form__btn-add-stop-wrap">
                          <div className="pickup-form__btn-add-stop border border-white rounded-sm">
                            <Button
                              style="secondary"
                              label="Add Stop"
                              onClick={() => AddStopClick(values, setFieldValue)}
                              icon={() => (
                                <i className="ri-map-pin-line "></i>
                              )}
                            // iconPosition="left"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center space-x-2">
                        <label htmlFor="returnBooking" className="text-white">
                          Return Booking
                        </label>
                        <FormikCheckbox name="returnBooking" id="returnBooking" />
                      </div>
                      <div className="w-full flex justify-between space-x-6 datePickerWrap ">
                        <DatePicker
                          className="datePicker"
                          formatString="d/MM/yy"
                          placeholder="D/MM/YY"
                          locale={enGB}
                          value={pickupDate}
                          onChange={({ date }) => setPickupDate(date)}
                          minDate={new Date()}
                          clearable
                          overrides={{
                            InputWrapper: {
                              style: () => ({
                                backgroundColor: "transparent",
                                color: "white",
                                // border: "1px solid #c2c8cc",
                                borderRadius: "2px",
                              }),
                            }
                          }}
                        />
                        <TimePicker
                          value={pickupTime}
                          onChange={(time) => setPickupTime(time)}
                          placeholder="pickup time"
                          step={300}
                          format="24"
                          minTime={isSameDate ? pickupTime : minTime}
                          overrides={{
                            InputWrapper: {
                              style: () => ({
                                backgroundColor: "transparent",
                                color: "white",
                              }),
                            },
                          }}
                        />
                      </div>
                      {values?.returnBooking && (
                        <div className="w-full flex justify-between space-x-6 datePickerWrap">
                          <Field name="returnDate">
                            {({ field, form: { touched, errors }, meta }) => (
                              <DatePicker
                                className="text-white bg-transparent "
                                placeholder="Date"
                                // locale={enGB}
                                // formatDisplayValue={(date) => formatDate(date)}
                                value={field.value}
                                onChange={({ date }) => {
                                  // console.log("pickupTime -- date", date);
                                  // console.log("pickupTime", pickupTime);
                                  // if (!isSameDate && dayjs(date).isSame(pickupTime)) {
                                  //   setIsSameDate(true);
                                  // } else if (isSameDate) {
                                  //   setIsSameDate(false);
                                  // }
                                  setFieldValue("returnDate", date);
                                }}
                                minDate={pickupDate}
                                clearable
                                overrides={{
                                  InputWrapper: {
                                    style: () => ({
                                      backgroundColor: "transparent",
                                      color: "white",
                                      // border: "1px solid #c2c8cc",
                                      borderRadius: "2px",
                                    }),
                                  },
                                }}
                              />
                            )}
                          </Field>
                          <Field name="returnTime">
                            {({ field, form: { touched, errors }, meta }) => (
                              <TimePicker
                                value={field.value}
                                onChange={(time) => setFieldValue("returnTime", time)}
                                placeholder="Time"
                                step={300}
                                // minTime={new Date()}
                                minTime={isSameDate ? pickupTime : minTime}
                                format="12"
                                overrides={{
                                  InputWrapper: {
                                    style: () => ({
                                      backgroundColor: "transparent",
                                      color: "white",
                                    }),
                                  },
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      )}
                      <div className="flex justify-center w-full pt-4">
                        <div className="w-60">
                          <Button
                            label="Show available services"
                            fullWidth
                            type="sumbit"
                            className="neeto-ui-btn neeto-ui-btn_md"
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            }
            {/* Hourly Transfer */}
            {formType === 'hourly' &&
              <Formik
                initialValues={intialState}
                onSubmit={onSubmit}
                validationSchema={HOURLY_SCHEMA}
                validateOnChange={true}
              >
                {({ values, setFieldValue, setErrors, errors }) => {
                  return (
                    <Form className="pickup-form flex flex-col items-center justify-center w-full space-y-5">
                      <DropOffComponent
                        setFieldValue={setFieldValue}
                        type={"source"}
                      />
                      <FieldArray
                        name="stops"
                        render={(arrayHelpers) => (
                          <>
                            {values?.stops?.map((item, index) => {
                              console.debug("items", item);
                              return (
                                <div className="flex flex-col items-center justify-center w-full space-y-5">
                                  <div
                                    key={index}
                                    className="relative flex justify-between w-full "
                                  >
                                    <Field name={`stops.${index}`}>
                                      {({ form: { errors }, meta }) => {
                                        return (
                                          <div className="w-full">
                                            <Places
                                              onChange={(data) => {
                                                setFieldValue(`stops.${index}`, data);
                                                let stops = errors.stops
                                                  ? [...errors.stops]
                                                  : [];
                                                stops[index] = "";
                                                setErrors({
                                                  ...errors,
                                                  stops: stops,
                                                });
                                              }}
                                              meta={meta}
                                              errors={errors}
                                              placeholder={"Stop " + (index + 1)}
                                              close={true}
                                              onClose={(data) => {
                                                console.debug("onclose");
                                                arrayHelpers.remove(index);
                                              }}
                                              value={item}
                                            />
                                            <p
                                              className={classNames([
                                                "text-red-500 text-xs pt-2",
                                              ])}
                                            >
                                              {meta.error ? errors.stops[index] : ""}
                                            </p>
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      />
                      <div className="w-full flex justify-center datePickerWrap">
                        <div className="border border-white rounded-[2px] px-[10px] flex items-center hover:cursor-pointer"
                          onClick={(e) => tripHour != 5 ? setTripHour(tripHour - 1) : ""}
                        >-</div>
                        <input
                          type="number"
                          value={tripHour}
                          placeholder="Duration"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (/^\d+$/.test(inputValue) || inputValue === "") {
                              setTripHour(Math.max(5, inputValue));
                            }
                          }}
                          className="bg-inherit w-[100%] border-[1px] border-[white] px-[10px] py-[7px] rounded-[2px] placeholder:text-[#c2c8cc] mx-[10px] focus:border-white focus:outline-none"
                        >
                          {/* <img src={DurationIcon} alt="Your Icon" className="icon" /> */}
                        </input>
                        <div className="border-[1px] border-white rounded-[2px] px-[10px] flex items-center hover:cursor-pointer"
                          onClick={() => setTripHour(tripHour + 1)}
                        >+</div>
                      </div>
                      {tripHour === 0 &&
                        <span className="">Minimum duration required</span>
                      }
                      <div className="w-full flex justify-between space-x-6 datePickerWrap">
                        <DatePicker
                          // className="datePicker"
                          formatString="d/MM/yy"
                          placeholder="D/MM/YY"
                          locale={enGB}
                          value={pickupDate}
                          onChange={({ date }) => setPickupDate(date)}
                          minDate={new Date()}
                          clearable
                          overrides={{
                            Input: {
                              style: ({ $theme }) => ({
                                border: "none", // Remove the border
                              }),
                            },
                            InputWrapper: {
                              style: () => ({
                                backgroundColor: "transparent",
                                color: "white",
                                // border: "1px solid #c2c8cc",
                                borderRadius: "2px",
                              }),
                            },
                          }}
                        />
                        <TimePicker
                          value={pickupTime}
                          onChange={(time) => setPickupTime(time)}
                          placeholder="pickup time"
                          step={300}
                          format="24"
                          label="Pickup Time"
                          // minTime={new Date()}
                          minTime={isSameDate ? pickupTime : minTime}
                          overrides={{
                            InputWrapper: {
                              style: () => ({
                                backgroundColor: "transparent",
                                color: "white",
                              }),
                            },
                          }}
                        />
                      </div>

                      <div className="flex justify-center w-full pt-4">
                        <div className="w-60">
                          <Button
                            label="Show available services"
                            fullWidth
                            type="sumbit"
                            className="neeto-ui-btn neeto-ui-btn_md"
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            }
            {/* Intercity Transfer */}
            {formType === 'intercity' &&
              <Formik
                initialValues={intialState}
                onSubmit={onSubmit}
                validationSchema={PICKUP_SCHEMA}
                validateOnChange={false}
              >
                {({ values, setFieldValue, setErrors, errors }) => {
                  return (
                    <Form className="pickup-form flex flex-col items-center justify-center w-full space-y-5">
                      <div className="relative flex flex-col w-full space-x-3 space-y-3 mx-auto">
                        <DropOffComponent
                          setFieldValue={setFieldValue}
                          type={"source"}
                        />
                      </div>
                      <FieldArray
                        name="stops"
                        render={(arrayHelpers) => (
                          <>
                            {values?.stops?.map((item, index) => {
                              console.debug("items", item);
                              return (
                                <div className="flex flex-col items-center justify-center w-full space-y-5">
                                  <div
                                    key={index}
                                    className="relative flex justify-between w-full "
                                  >
                                    <Field name={`stops.${index}`}>
                                      {({ form: { errors }, meta }) => {
                                        return (
                                          <div className="w-full">
                                            <Places
                                              onChange={(data) => {
                                                setFieldValue(`stops.${index}`, data);
                                                let stops = errors.stops
                                                  ? [...errors.stops]
                                                  : [];
                                                stops[index] = "";
                                                setErrors({
                                                  ...errors,
                                                  stops: stops,
                                                });
                                              }}
                                              meta={meta}
                                              errors={errors}
                                              placeholder={"Stop " + (index + 1)}
                                              close={true}
                                              onClose={(data) => {
                                                console.debug("onclose");
                                                arrayHelpers.remove(index);
                                              }}
                                              value={item}
                                            />
                                            <p
                                              className={classNames([
                                                "text-red-500 text-xs pt-2",
                                              ])}
                                            >
                                              {meta.error ? errors.stops[index] : ""}
                                            </p>
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      />


                      <div className="relative flex flex-col w-full space-x-3 space-y-3 mx-auto">
                        <DropOffComponent
                          setFieldValue={setFieldValue}
                          type={"destination"}
                        />
                      </div>
                      <div className="relative flex flex-col w-full space-x-3 space-y-3 mx-auto">
                        <div className="pickup-form__btn-add-stop-wrap">
                          <div className="pickup-form__btn-add-stop border border-white rounded-sm">
                            <Button
                              style="secondary"
                              label="Add Stop"
                              onClick={() => AddStopClick(values, setFieldValue)}
                              icon={() => (
                                <i className="ri-map-pin-line "></i>
                              )}
                            // iconPosition="left"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center space-x-2">
                        <label htmlFor="returnBooking" className="text-white">
                          Return Booking
                        </label>
                        <FormikCheckbox name="returnBooking" id="returnBooking" />
                      </div>
                      <div className="w-full flex justify-between space-x-6 datePickerWrap">
                        <DatePicker
                          className="datePicker"
                          formatString="d/MM/yy"
                          placeholder="D/MM/YY"
                          locale={enGB}
                          value={pickupDate}
                          onChange={({ date }) => setPickupDate(date)}
                          minDate={new Date()}
                          clearable
                          overrides={{
                            InputWrapper: {
                              style: () => ({
                                backgroundColor: "transparent",
                                color: "white",
                                // border: "1px solid #c2c8cc",
                                borderRadius: "2px",
                              }),
                            },
                          }}
                        />
                        <TimePicker
                          value={pickupTime}
                          onChange={(time) => setPickupTime(time)}
                          placeholder="pickup time"
                          step={300}
                          format="24"
                          minTime={isSameDate ? pickupTime : minTime}
                          overrides={{
                            InputWrapper: {
                              style: () => ({
                                backgroundColor: "transparent",
                                color: "white",
                              }),
                            },
                          }}
                        />
                      </div>
                      {values?.returnBooking && (
                        <div className="w-full flex justify-between space-x-6 datePickerWrap">
                          <Field name="returnDate">
                            {({ field, form: { touched, errors }, meta }) => (
                              <DatePicker
                                className="text-white bg-transparent"
                                placeholder="Date"
                                value={field.value}
                                onChange={({ date }) => {
                                  // console.log("pickupTime -- date", date);
                                  // console.log("pickupTime", pickupTime);
                                  // if (!isSameDate && dayjs(date).isSame(pickupTime)) {
                                  //   setIsSameDate(true);
                                  // } else if (isSameDate) {
                                  //   setIsSameDate(false);
                                  // }
                                  setFieldValue("returnDate", date);
                                }}
                                minDate={pickupDate}
                                clearable
                                overrides={{
                                  InputWrapper: {
                                    style: () => ({
                                      backgroundColor: "transparent",
                                      color: "white",
                                      // border: "1px solid #c2c8cc",
                                      borderRadius: "2px",
                                    }),
                                  },
                                }}
                              />
                            )}
                          </Field>
                          <Field name="returnTime">
                            {({ field, form: { touched, errors }, meta }) => (
                              <TimePicker
                                value={field.value}
                                onChange={(time) => setFieldValue("returnTime", time)}
                                placeholder="Time"
                                step={300}
                                minTime={isSameDate ? pickupTime : minTime}
                                format="12"
                                overrides={{
                                  InputWrapper: {
                                    style: () => ({
                                      backgroundColor: "transparent",
                                      color: "white",
                                    }),
                                  },
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      )}
                      <div className="flex justify-center w-full pt-4">
                        <div className="w-60">
                          <Button
                            label="Show available services"
                            fullWidth
                            type="sumbit"
                            className="neeto-ui-btn neeto-ui-btn_md"
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            }
          </ThemeProvider>
        </div>
      </div>
    </div >
  );
};

export default PickupForm;
