import React from 'react'
import Footer from '../../common/Footer'
import Navbar from '../../common/Navbar'
import BlogListing from './BlogListing'

const Blog = () => {
  return (
    <div className="bg-black">
      <div className="flex flex-col bg-black">
        <Navbar />
        <main className="main min-h-[80vh]">
          <div className="text-center flex flex-col mt-[30px] md:mt-[63px] ">
            <span className="text-[34px] md:text-[48px]">Blogs</span>
            {/* <span className="text-[18px] md:text-[24px]">One stop solution for premium airport transfers</span> */}
          </div>
          <BlogListing />
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default Blog