import React from "react";
import { Input as FormikInput } from "@bigbinary/neetoui/formik";
import { Radio, DatePicker } from "@bigbinary/neetoui";
import { Field, Form, Formik } from "formik";
import Visa from "../../../assets/visa.svg";
import VisaMaster from "../../../assets/visamaster.svg";

const PaymentForm = ({ onSubmit, formRef }) => {
  const intialState = {
    isCreditCard: true,
    cardName: "Peter Pack",
    cardNumber: "0011 22 23323223",
    date: new Date(),
    cvv: "232",
  };

  return (
    <div className="w-full px-6 pt-4 pb-8 border-b border-gray-600 md:border-0">
      <Formik
        initialValues={intialState}
        onSubmit={onSubmit}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="flex flex-col justify-center space-y-10">
                <div>
                  <Field name="isCreditCard">
                    {({ field, form: { touched, errors }, meta }) => (
                      <div className="space-y-10">
                        <Radio.Item
                          name="isCreditCard"
                          className="flex w-full p-2 space-x-2 border"
                          label={
                            <div className="flex items-center justify-between w-full space-x-28">
                              <span>Credit Card</span>
                              <img
                                src={VisaMaster}
                                width="40px"
                                height="40px"
                              />
                            </div>
                          }
                          checked={values.isCreditCard}
                          onChange={() => {
                            setFieldValue("isCreditCard", true);
                          }}
                          {...field}
                        />
                        <Radio.Item
                          name="isCreditCard"
                          className="flex p-2 space-x-2 border"
                          label={
                            <div className="flex items-center justify-between w-full space-x-28">
                              <span>Debit Card</span>
                              <img src={Visa} width="40px" height="40px" />
                            </div>
                          }
                          checked={!values.isCreditCard}
                          {...field}
                          onChange={() => {
                            setFieldValue("isCreditCard", false);
                          }}
                        />

                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <FormikInput
                  name="cardName"
                  label="Cardholder name"
                  required
                  className="w-full"
                />
                <div className="md:flex md:space-x-10">
                  <FormikInput
                    name="cardNumber"
                    label="Card number"
                    required
                    className="w-full pb-8"
                  />
                  <div className="space-y-1">
                    <div className="flex space-x-10">
                      <Field name="date">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => (
                          <div>
                            <DatePicker
                              label="Date"
                              className="text-white bg-transparent"
                              placeholder="Date"
                              {...field}
                            />
                            {meta.touched && meta.error && (
                              <div className="error">{meta.error}</div>
                            )}
                          </div>
                        )}
                      </Field>
                      <FormikInput
                        label="CVV"
                        name="cvv"
                        required
                        className="w-1/2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PaymentForm;
