import React, { useState, useEffect } from 'react'
import { carsoelData } from "./constants";
import ReviewGoogle from "../../assets/googlereview.svg";
import { Button } from "@bigbinary/neetoui";
import ServicesApi from '../../api/passengerDetails';
import Trustpilot from '../../assets/trustpilot.png'

const Testimonials = () => {
  const [carsouelCount, setCarsouelCount] = useState(0);
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    const getReview = async () => {
      const data = await ServicesApi.Reviews()
      setReviews(data)
    }
    getReview()
  }, []);

  return (
    <>
      {reviews && reviews[carsouelCount] &&
        <div className="w-full bg-yellow-1000 xs:hidden pt-[50px] pb-[70px] px-[2%] black" >
          <div className="container-xl mx-auto flex flex-col md:flex-row flew-wrap justify-between px-[3%] md:px-0">
            <div className="flex justify-center items-center max-w-[257px]">
              <div className="border-l-[10px] border-[#7952B3] pl-[17px] text-[36px] leading-[40px] md:text-[48px] md:leading-[50px]">What Our Customers Say</div>
            </div>

            <div className="flex flex-col lg:flex-row w-[100%] md:w-[74%] justify-between items-center">
              <img src={ReviewGoogle} alt="" className="h-[108px] md:h-[178px] mt-[15px]" />
              <div className="testimonials__slider">
                <div className="bg-white rounded-md h-[40px]">
                  <Button
                    style="secondary"
                    onClick={() =>
                      setCarsouelCount((state) => {
                        if (state === 0) return reviews.length - 1;
                        else return state - 1;
                      })
                    }
                    icon={() => (
                      <i className="text-xl text-black ri-arrow-left-line"></i>
                    )}
                  />
                </div>
                <div className="flex flex-col items-center justify-start h-[200px]">
                  <h6 className="testimonials__name">
                    {reviews[carsouelCount].author_name}
                  </h6>
                  <p className="testimonials__text min-w-[220px] w-full mt-[35px]">
                    {reviews[carsouelCount].text.length === 0 ? "No Review" : reviews[carsouelCount].text}
                  </p>
                </div>
                <div className="bg-white rounded-md h-[40px]">
                  <Button
                    style="secondary"
                    onClick={() =>
                      setCarsouelCount((state) => {
                        if (state >= reviews.length - 1) return 0;
                        else return state + 1;
                      })
                    }
                    icon={() => (
                      <i className="text-xl text-black ri-arrow-right-line"></i>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <img src={Trustpilot} alt="" className='h-[40px] mx-auto mt-5 hover:cursor-pointer' onClick={() => window.open('https://www.trustpilot.com/review/ridewing.co.uk', '_blank')} />
        </div>}
    </>
  )
}

export default Testimonials