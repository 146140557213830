export const SERVICE_AIRPORT = [
  { name: "London Heathrow Airport", name2: "All Terminal" },
  { name: "Windsor Suite", name2: "London Heathrow Airport" },
  { name: "Gatwick Airport", name2: "North Terminal" },
  { name: "Gatwick Airport", name2: "South Terminal" },
  { name: "Signature Flight Support", name2: "-LGW-London Gatwick1" },
  { name: "London Biggin Hill Airport", name2: "" },
  { name: "London Luton Airport", name2: "" },
  { name: "Signature Flight Support.", name2: "-London Luton Airport" },
  { name: "Harrods Aviation London", name2: "Luton Airport" },
  { name: "Farnborough Airport", name2: "Farnborough" },
  { name: "Blackbushe Airport", name2: "Cambrly" },
  { name: "London Stansted Airport", name2: "" },
  { name: "Harrods Aviation London", name2: "stansted" },
  { name: "Signature Flight Support", name2: "London Stansted" },
  { name: "Universal Aviation", name2: "London Stansted" },
  { name: "London City Airport", name2: "" },
];
