import React, { useState, useMemo } from "react";
import Navbar from "../../common/Navbar";
import sell from '../../assets/sell.svg'
import discount from '../../assets/discounts.svg'
import standard from '../../assets/standard.svg'
import support from '../../assets/support.svg'
import img1 from '../../assets/agentimage1.svg'
import img2 from '../../assets/agentimage2.svg'
import close from '../../assets/close.png'
import { Button } from "@bigbinary/neetoui";
import { Input as FormikInput, Select } from "@bigbinary/neetoui/formik";
import Footer from "../../common/Footer";
import ServicesApi from "../../api/passengerDetails";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import codes from "country-calling-code";
import { Field, FieldArray, Form, Formik } from "formik";

const AgentRegister = () => {
  const history = useHistory();
  const [showRegister, setShowRegister] = useState(false)
  const initialState = {
    company_name: "",
    name: "",
    country: "",
    contact_number: "",
    email: "",
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Company Name is required"),
    name: Yup.string().required("Name is required"),
    contact_number: Yup.string()
      .required("Contact Number is required")
      .matches(/^\d{10}$/, "Invalid Contact Number"),
    email: Yup.string().required("Email is required").email("Invalid Email"),
    country: Yup.object().required("Country is required"),
  });

  const options = useMemo(
    () =>
      codes.map((item) => {
        // console.log(item);
        return { label: item.country, value: item.countryCodes };
      }),
    []
  );
  const colourStyles = {
    indicatorsContainer: (base, state) => ({
      background: "inherit",
    }),
    control: (base, state) => ({
      ...base,
      background: "#5C5F63",
    }),
    singleValue: (provided) => ({
      ...provided,

      color: "white",
    }),
    // option: (styles, state) =>
    //   state.isFocused || {
    //     ...styles,
    //   },
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      // console.log(values);
      // Make the POST request to the server
      const response = await ServicesApi.AgentRegEmail(values);
      // Handle the response as needed
      // console.log("Form submitted successfully!", response);
      history.push("/agentregsuccessful");
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col  text-white bg-black">
        <Navbar />
        <main className="agents-main">
          <div className="container container-xl flex flex-col items-center w-full lg:flex-row justify-evenly lg:justify-start lg:items-start">

            {/* <div className="agents-first-col flex flex-col flex-1 items-start w-full px-4 lg:pl-0 py-16 justify-items-start lg:w-1/2">
            <h1 className="h2">Agent Registration</h1>
            <h2 className="h3 mt-5">Why choose us?</h2>
            <ul className="dispatcher-advantages-list mt-10 lg:mt-14">
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Affordable and high quality facilities.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Best service you can provide for your client profitably.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Personalized assistance for your client.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Option to choose from a wide range of vehicles.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Supervision and 24/7 customer support.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Hassle free registration.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Attractive incentives and commission.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Transparent and trustworthy.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>All classes of cars from economy to premium.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Professional and skilled chauffeurs.</p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>Easy to use personal account .</p>
              </li>
            </ul>
          </div> */}
            <div className="container-xl min-h-[80vh] flex flex-col items-center">
              <div className="text-center flex flex-col mt-[129px] lg:mt-[63px]">
                <span className="text-[34px] md:text-[48px]">TRAVEL AGENCY REGISTRATION</span>
                <span className="text-[18px] md:text-[24px] mt-[10px]">Create your agency account and start making bookings with us! <br />
                  Sell transfers and get a special travel agency discount for each booking.</span>
              </div>

              <div className="my-[60px] flex">
                <div className="hidden lg:block my-auto">
                  <div className="flex flex-col justify-evenly pr-[30px] ">
                    <div className="max-w-[272px] w-full h-[166px] rounded-[15px] bg-[#FFD369] flex flex-col items-center text-center py-[30px] text-black">
                      <span className="lg:text-[18px] xl:text-[20px] font-[500] px-[10px]">ALL INCLUSIVE RATES</span>
                      <span className="lg:text-[14px] xl:text-[18px] font-[400] lg-px-[10px] xl:px-[20px] lg:pt-[5px] xl:pt-[10px]">Confirmed all-inclusive rates,
                        without hidden fees</span>
                    </div>
                    <img src={img1} alt="" className="h-[247px] my-[20px]" />
                    <div className="max-w-[272px] w-full h-[166px] rounded-[15px] bg-[#7952B3] flex flex-col items-center text-center py-[30px] text-white">
                      <span className="lg:text-[18px] xl:text-[20px] font-[500] px-[10px]">PROFESSIONAL DRIVERS</span>
                      <span className="lg:text-[14px] xl:text-[18px] font-[400] lg-px-[10px] xl:px-[20px] lg:pt-[5px] xl:pt-[10px]">Reach your destination safely with a licensed and professional chauffeur</span>
                    </div>

                  </div>
                </div>

                <div className="max-w-[557px] xl:max-w-[648px] rounded-[30px] border-[1px] border-[#FFD369] flex flex-col items-center pt-[25px] text-center">
                  <span className="text-[20px] font-[500]">TRANSFER SERVICE FOR TRAVEL AGENCY CUSTOMERS</span>
                  <span className="text-[20px] font-[400] py-[30px]">Register with us for ultimate travel solutions</span>
                  <span className="text-[16px] text-center p-[25px]">With Ridewing, easy travel from A to B . Whether it’s to the airport, train station or hotel, on a business trip, to a client meeting or during a business event – ridewing offers a high level of comfort at fixed prizes.</span>
                  <div className="grid grid-cols-1 md:grid-cols-2 my-[40px] gap-[40px] text-left">

                    <div className="flex">
                      <img src={sell} alt="" className="h-[50px] mr-[10px]" />
                      <div className="flex flex-col">
                        <span className="text-[14px] font-[500]">Sell</span>
                        <span className="text-[12px] font-[400]">We organize the transfer<br />
                          Your client enjoys the service</span>
                      </div>
                    </div>
                    <div className="flex">
                      <img src={discount} alt="" className="h-[50px] mr-[10px]" />
                      <div className="flex flex-col">
                        <span className="text-[14px] font-[500]">Discounts</span>
                        <span className="text-[12px] font-[400]">Register now and get discounts</span>
                      </div>
                    </div>
                    <div className="flex">
                      <img src={standard} alt="" className="h-[50px] mr-[10px]" />
                      <div className="flex flex-col">
                        <span className="text-[14px] font-[500]">High standards of service</span>
                        <span className="text-[12px] font-[400]">Only licensed drivers and vehicles</span>
                      </div>
                    </div>
                    <div className="flex">
                      <img src={support} alt="" className="h-[50px] mr-[10px]" />
                      <div className="flex flex-col">
                        <span className="text-[14px] font-[500]">24/7 customer support</span>
                        <span className="text-[12px] font-[400]">We organize the transfer<br />
                          Your client enjoys the service</span>
                      </div>
                    </div>

                  </div>

                  <div className="w-full h-[1px] bg-white "></div>


                  <button
                    className="bg-[#7952B3] rounded-[12px] w-[180px] h-[46px] flex justify-center items-center my-[22px]"
                    onClick={() => {
                      setShowRegister(true)
                    }}
                  >Join Now</button>

                  <span className="text-[20px] font-[500]">TRANSFER SERVICE FOR TRAVEL AGENCY CUSTOMERS</span>
                  <span className="text-[16px] text-center p-[25px]">With Ridewing, easy travel from A to B . Whether it’s to the airport, train station or hotel, on a business trip, to a client meeting or during a business event – ridewing offers a high level of comfort at fixed prizes.</span>

                </div>

                <div className="hidden lg:block my-auto">
                  <div className="flex flex-col justify-evenly pl-[30px] ">
                    <div className="max-w-[272px] w-full h-[166px] rounded-[15px] bg-[#FFD369] flex flex-col items-center text-center py-[30px] text-black">
                      <span className="lg:text-[18px] xl:text-[20px] font-[500] px-[10px]">24 HOUR SUPPORT</span>
                      <span className="lg:text-[14px] xl:text-[18px] font-[400] lg-px-[10px] xl:px-[20px] lg:pt-[5px] xl:pt-[10px]">Help via phone and WHATS APP</span>
                    </div>
                    <img src={img2} alt="" className="h-[247px] my-[20px]" />
                    <div className="max-w-[272px] w-full h-[166px] rounded-[15px] bg-[#7952B3] flex flex-col items-center text-center py-[30px] text-white">
                      <span className="lg:text-[14px] xl:text-[18px] font-[400] lg-px-[10px] xl:px-[20px] lg:pt-[5px] xl:pt-[10px]">Thousands of customers trust us for seamless, travel solutions</span>
                    </div>
                  </div>
                </div>

              </div>
              <div className="lg:hidden pb-[30px]">
                <div className="flex flex-col justify-evenly">
                  <div className="flex flex-col md:flex-row justify-evenly items-center mt-[20px]">
                    <div className="max-w-[272px] w-full h-[166px] rounded-[15px] bg-[#FFD369] flex flex-col items-center justify-evenly text-center py-[30px] text-black px-[15px]">
                      <span className="lg:text-[18px] xl:text-[20px] font-[600] px-[10px]">ALL INCLUSIVE RATES</span>
                      <span className="lg:text-[16px] xl:text-[18px] font-[400] lg-px-[10px] xl:px-[20px] lg:pt-[5px] xl:pt-[15px]">Confirmed all-inclusive rates,
                        without hidden fees</span>
                    </div>
                    <img src={img1} alt="" className="h-[247px] m-[20px]" />
                    <div className="max-w-[272px] w-full h-[166px] rounded-[15px] bg-[#7952B3] flex flex-col items-center justify-evenly text-center py-[30px] text-white px-[15px]">
                      <span className="lg:text-[18px] xl:text-[20px] font-[600] px-[10px]">PROFESSIONAL DRIVERS</span>
                      <span className="lg:text-[16px] xl:text-[18px] font-[400] lg-px-[10px] xl:px-[20px] lg:pt-[5px] xl:pt-[15px]">Reach your destination safely with a licensed and professional chauffeur</span>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-evenly items-center mt-[20px]">
                    <div className="max-w-[272px] w-full h-[166px] rounded-[15px] bg-[#FFD369] flex flex-col items-center justify-evenly text-center py-[30px] text-black px-[15px]">
                      <span className="lg:text-[18px] xl:text-[20px] font-[600] px-[10px]">24 HOUR SUPPORT</span>
                      <span className="lg:text-[16px] xl:text-[18px] font-[400] lg-px-[10px] xl:px-[20px] lg:pt-[5px] xl:pt-[15px]">Help via phone and WHATS APP</span>
                    </div>
                    <img src={img2} alt="" className="h-[247px] m-[20px]" />
                    <div className="max-w-[272px] w-full h-[166px] rounded-[15px] bg-[#7952B3] flex flex-col items-center justify-evenly text-center py-[30px] text-white px-[15px]">
                      <span className="lg:text-[16px] xl:text-[18px] font-[400] lg-px-[10px] xl:px-[20px] lg:pt-[5px] xl:pt-[15px]">Thousands of customers trust us for seamless, travel solutions</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
        {showRegister &&
          <div className="fixed h-[100vh] w-[100vw] flex justify-center items-center bg-black z-[100]">
            <div className='max-w-[721px] bg-[#5C5F63] rounded-[20px] flex flex-col items-center text-black py-[20px] md:pb-[30px] md:pt-[10px] px-[30px] m-[10px]'>
              <div className="w-full flex justify-end py-[10px]">
                <img src={close} alt="" className="h-[25px] hover:cursor-pointer" onClick={() => setShowRegister(false)} />
              </div>
              <span className="text-[22px] md:text-[24px] font-[600] text-center text-white ">REGISTER AS AN AGENT FOR SPECIAL RATES</span>
              <span className="text-[16px] md:text-[20px] font-[400] py-[15px] md:py-[30px] text-center text-white ">Fill the form below and become a ridewing agent</span>
              <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form className="space-y-1 form">
                    <FormikInput
                      nakedInput
                      name="company_name"
                      placeHolder="Travel agency name*"
                      className="mx-2 border-b border-gray-400"
                      error={touched.company_name && errors.company_name}
                    />
                    <FormikInput
                      name="name"
                      nakedInput
                      placeHolder="Contact Person Full Name*"
                      className="mx-2 border-b border-gray-400"
                      error={touched.name && errors.name}
                    />
                    <div className="flex">
                      {/* <select
                        nakedInput
                        name="country"
                        // options={options}
                        // placeHolder="Country*"
                        className="mx-2 border-b border-gray-400 bg-inherit text-white w-[30%]"
                        onChange={(e) => setFieldValue("country", e.target.value)}
                        error={touched.country && errors.country}
                      >
                        <option value="" label="Country" disabled className="bg-[#5C5F63] text-white"/>
                        {options.map((option, id) => (
                          <option key={id} value={option.value} label={option.label} className="bg-[#5C5F63] text-white"/>
                        ))}
                      </select> */}
                      <Select
                        name="country"
                        required
                        styles={colourStyles}
                        options={options}
                        placeHolder="Country*"
                        size="small"
                        maxlength="3"
                        error={touched.country && errors.country}
                      />
                      <FormikInput
                        nakedInput
                        placeHolder="Contact Number*"
                        name="contact_number"
                        className="mx-2 border-b border-gray-400"
                        error={touched.contact_number && errors.contact_number}
                      />
                    </div>
                    <FormikInput
                      nakedInput
                      name="email"
                      placeHolder="Email*"
                      className="mx-2 border-b border-gray-400"
                      error={touched.email && errors.email}
                    />
                    <div className="mx-1">
                      <span className="text-white">By registering, you agree Ridewing’s travel agent to Terms & Conditions</span>
                    </div>
                    <div className="flex justify-center">
                      <Button
                        className="btn-register"
                        size="large"
                        label="Register"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div >
          </div >
        }
      </div >
    </>
  );
};

export default AgentRegister;
