import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { DatePicker } from "baseui/datepicker";
import enGB from 'date-fns/locale/en-GB';
import ServicesApi from '../../api/passengerDetails';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  bookingId: Yup.string().required('Booking ID is required'),
  mail: Yup.string().email('Invalid email').required('Email is required'),
  pickupDate: Yup.date().required('Pickup date is required'),
});

const SearchBooking = ({ setShowForm, setBookingList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    bookingId: '',
    mail: '',
    pickupDate: null,
  };

  const onSubmit = async (values) => {
    try {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const inputDate = values.pickupDate
        ? values.pickupDate.toLocaleDateString('en-IN', options)
        : null;
      const parts = inputDate.split('/');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

      let booking = await ServicesApi.FindBooking(
        values.bookingId,
        values.mail,
        formattedDate
      );

      if (booking && booking.length > 0) {
        setBookingList(booking);
        setShowForm(false)
      } else {
        console.log('Booking is empty');
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      }
    } catch (error) {
      console.error('Error while fetching booking:', error);
    }
  };
  return (
    <section className='px-8 pb-10'>
      <div className='container mx-auto flex flex-col items-center'>
        <div className="text-center flex flex-col mt-[30px] mb-10">
          <span className="text-[34px] md:text-[48px]">Search Booking</span>
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ setFieldValue }) => (
            <Form className="w-[100%] max-w-[450px] flex flex-col gap-5 border-[1px] border-white rounded-[10px] px-4 py-6">
              <div className="flex flex-col mt-3">
                <label htmlFor="bookingId">Booking ID:</label>
                <Field
                  type="text"
                  id="bookingId"
                  name="bookingId"
                  className="bg-inherit border-b-[1px] border-white placeholder-[slate-400] py-[10px]"
                />
                <ErrorMessage name="bookingId" component="div" />
              </div>

              <div className='flex flex-col mt-3'>
                <label htmlFor='mail'>Mail:</label>
                <Field
                  type='text'
                  id='mail'
                  name='mail'
                  className='bg-inherit border-b-[1px] border-white placeholder-[slate-400] py-[10px]'
                />
                <ErrorMessage name='mail' component='div' />
              </div>

              <div className="flex flex-col mt-3">
                <label htmlFor="pickupDate" className='mb-3'>Pickup Date:</label>
                <DatePicker
                  className="datePicker"
                  formatString="d/MM/yy"
                  placeholder="D/MM/YY"
                  locale={enGB}
                  value={initialValues.pickupDate}
                  onChange={({ date }) =>
                    setFieldValue('pickupDate', date)
                  }
                  minDate={new Date()}
                  clearable
                  overrides={{
                    InputWrapper: {
                      style: () => ({
                        backgroundColor: 'transparent',
                        color: 'white',
                        borderRadius: '2px',
                      }),
                    },
                  }}
                />
                <ErrorMessage name="pickupDate" component="div" />
              </div>

              <button
                type="submit"
                className={`mt-3 bg-yellow-1000 py-3 text-black ${isLoading ? 'wiggle bg-gray-500 text-white' : 'bg-yellow-1000'}`}
                disabled={isLoading}
                onClick={onSubmit}
              >
                {isLoading ? 'NO BOOKING FOUND' : 'SEARCH BOOKING'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  )
}

export default SearchBooking