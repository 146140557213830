import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import copy from 'clipboard-copy'
// Import Components
import ServicesApi from '../../api/passengerDetails'
import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'
// Import Resources
import copyImg from '../../assets/copy.svg'
import arrow from '../../assets/arrowr.svg'

const BlogView = () => {
  const [blogData, setBlogData] = useState()
  const [copied, setCopied] = useState(false)
  const history = useHistory()
  // Access the location object using the useLocation hook
  const location = useLocation();
  const path = location.pathname.split('/')
  const currentPath = location.pathname;
  let url = 'https://ridewing.co.uk' + currentPath

  useEffect(() => {
    fetchBlog()
  }, [])

  const fetchBlog = async () => {
    try {
      let response = await ServicesApi.GetBlog(path[path.length - 1])
      setBlogData(response)
      // console.log(blogData)
    } catch (error) {
      console.log(error);
    }
  }


  // Copy to clipboard
  const handleCopyClick = async () => {

    try {
      // await navigator.clipboard.writeText(url);
      copy(url);
      setCopied(true)
      setTimeout(() => {
        setCopied(false);
      }, 1500);
      // console.log('Text copied to clipboard:', url);
    } catch (err) {
      console.error('Unable to copy text to clipboard:', err);
    }
  };

  return (
    <div className="bg-black">
      <div className="flex flex-col bg-black">
        <Navbar />
        {blogData &&
          <main className="main ">
            <div className="flex flex-col mt-[30px] md:mt-[63px] container-xl mx-auto px-[20px] mb-[40px]">
              <p className='text-[24px] text-[#FFD369] italic font-semibold'>{blogData.title}</p>
              <div className='rounded-[30px] border-[#FFD369] border-[1px] w-[100%] mt-[40px] px-[15px] pb-[40px] bg-[#51545B]'>
                <div className='w-[95%] max-h-[539px] my-[25px] mx-auto '>
                  <img src={blogData.image_url} alt="blog_image" className='h-[100%] max-h-[539px] object-cover mx-auto' />
                </div>

                <div className='flex justify-between'>
                <p>{new Date(blogData.create_date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                  <div className='flex'>
                    <div className='flex flex-col items-end'>
                      <img src={copyImg} alt="copy" className='h-[25px] mr-[20px] hover:cursor-pointer' onClick={() => handleCopyClick()} />
                      <p className={`text-[#FFD369] ${copied ? "opacity-100" : "opacity-0"}  transition-opacity duration-500 ease-in-out`}>Link copied to clipboard</p>
                    </div>
                  </div>
                </div>
                <div className='px-[10px] md:px-[60px] pt-[20px] md:pt-[40px]'>
                  <p>
                    {blogData.content}
                  </p>
                  <div
                    className="flex items-center justify-between border-[#FFD369] border-[1px] py-[20px] px-[15px] md:py-[40px] md:px-[30px] max-w-[486px] mt-[40px] hover:cursor-pointer "
                    onClick={() => { history.push("/blog") }}
                  >
                    <span className="text-[#FFD369] text-[24px] md:text-[32px]">View All Latest News</span>
                    <img src={arrow} alt="" className="h-[26px] ml-[10px]" />
                  </div>
                </div>
              </div>
            </div>
          </main>
        }
      </div>
      <Footer />
    </div>
  )
}

export default BlogView