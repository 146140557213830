import React, { useState } from "react";
import Navbar from "../../common/Navbar";
import BritishIcon from "../../assets/britishIcon.png";
import ImgAirport from "../../assets/airport.webp";
import ImgMeet from "../../assets/meet.webp";
import arrow from '../../assets/arrowr.svg'
import { SERVICE_AIRPORT } from "./constants";
import Footer from "../../common/Footer";
import { useHistory } from 'react-router-dom';

import spairport from '../../assets/spairport.svg'
import sphourly from '../../assets/sphourly.svg'
import spintercity from '../../assets/spintercity.svg'
import spbus from '../../assets/spbus.svg'
import BlogListing from "../Blog/BlogListing";

const Service = () => {
  const history = useHistory();
  return (
    <div className="bg-black">
      <div className="flex flex-col bg-black">
        <Navbar />
        <main className="main ">
          <div>
            <div className="container-xl min-h-[80vh] flex flex-col items-center justify-center mx-auto">
              <div className="text-center flex flex-col mt-[30px] md:mt-[63px] ">
                <span className="text-[34px] md:text-[48px]">Ridewing Services</span>
                {/* <span className="text-[18px] md:text-[24px]">One stop solution for premium airport transfers</span> */}
              </div>

              <div className="flex flex-col py-[60px] gap-[30px] px-[4%] ">

                {/* Airport Transfer */}
                <div className="lg:max-w-[1102px] w-full bg-[#51545B] rounded-[10px] flex flex-wrap-reverse lg:flex-nowrap justify-center items-center p-[15px] md:p-[32px]">
                  <div className="flex flex-col lg:pr-[32px] lg:w-[50%]">
                    <span className="text-[24px] md:text-[30px] font-[700] mt-[20px] lg:mt-0">Airport Transfer</span>
                    <span className="pt-[10px] md:pt-[22px] text-[12px] lg:text-[16px]">Exhausted after a long flight? We’ve got your back. Get to your destination directly from any airport in the UK. Wherever you go, our chauffeurs can track your flight and adjust for any delays.<br />
                      Premium vehicles, high quality service, safe and secure transfer. You can reserve your airport transfer in any city of your choice in the UK through our ridewing website or ridewing app made available for your android or apple device.<br />
                      The booking is simple and straightforward - enter your pickup and destination information. After you have confirmed the payment details, you will receive a confirmation email shortly afterwards. Our services are transparent, and there are no hidden charges.</span>
                  </div>
                  <div className="lg:w-[50%] lg:h-full h-[160px] md:h-[300px] w-full overflow-hidden">
                    <img src={spairport} alt="" className="w-full h-full object-cover " />
                  </div>
                </div>

                {/* Hourly */}
                <div className="lg:max-w-[1102px] w-full bg-[#51545B] rounded-[10px] flex flex-wrap lg:flex-nowrap justify-center items-center p-[15px] md:p-[32px]">
                  <div className="flex lg:w-[50%] lg:h-full h-[160px] md:h-[360px] w-full overflow-hidden">
                    <img src={sphourly} alt="" className="w-full h-full md:h-[360px] object-cover object-left" />
                  </div>
                  <div className="flex flex-col lg:pl-[32px]  lg:w-[50%]">
                    <span className="text-[24px] md:text-[30px] font-[700] mt-[20px] lg:mt-0">Hourly booking London LIMOUSINE service</span>
                    <span className="pt-[10px] md:pt-[22px] text-[12px] lg:text-[16px]">a high class travel experience, or need a premium ride service for whatever reason, we can have a limousine and local chauffeur pick you up anywhere in London . you can enjoy a safe and professional journey with your own personal chauffeur with ridewing limousine service.
                      <br /><br /><br />
                      -Professional drivers-<br />
                      Reach your destination safely and relaxed with a licensed, insured and professional driver.</span>
                  </div>
                </div>

                {/* Intercity transfers */}
                <div className="lg:max-w-[1102px] w-full bg-[#51545B] rounded-[10px] flex flex-wrap-reverse lg:flex-nowrap justify-center items-start p-[15px] md:p-[32px]">
                  <div className="flex flex-col lg:pr-[32px] lg:w-[50%]">
                    <span className="text-[24px] md:text-[30px] font-[700] mt-[20px] lg:mt-0">Intercity transfers</span>
                    <span className="pt-[10px] md:pt-[22px] text-[12px] lg:text-[16px]">The better way between cities
                      comfort rides with our inter-City private car service.
                    </span>
                    <ul className="text-[12px] lg:text-[16px] pl-[20px]" style={{ listStyleType: 'disc' }}>
                      <li>Save time: Door-to-door rides mean no waiting in lines or switching between modes of transportation.</li>
                      <li>Peace of mind: Travel in comfort in a premium vehicle with reliable chauffeur drivers</li>
                      <li>Fixed fares: The price for your journey is fixed, Taxes and tolls are included</li>
                    </ul>
                  </div>
                  <div className="lg:w-[50%] lg:h-full h-[160px] md:h-[300px] w-full overflow-hidden">
                    <img src={spintercity} alt="" className="w-full h-full object-cover " />
                  </div>
                </div>

                {/* Bus Service */}
                <div className="lg:max-w-[1102px] w-full bg-[#51545B] rounded-[10px] flex flex-wrap lg:flex-nowrap justify-center items-center p-[15px] md:p-[32px]">
                  <div className="lg:w-[50%] lg:h-full h-[160px] md:h-[300px] w-full overflow-hidden">
                    <img src={spbus} alt="" className="w-full h-full object-cover" />
                  </div>
                  <div className="flex flex-col lg:pl-[32px] lg:w-[50%]">
                    <span className="text-[24px] md:text-[30px] font-[700] mt-[20px] lg:mt-0">Book your large group transfers through us.</span>
                    <span className="pt-[10px] md:pt-[22px] text-[12px] lg:text-[16px]">We work with licensed coach operators across the United Kingdom to provide you with the best possible transfer experience. As an agent for this specific vehicle category, we will find the best price for your transfer and get back to you with a quote shortly.Our prices are optimal in terms of both price and quality of service, and we offer an individual approach to each trip, request, and offer. Our drivers are professionals with many years of passenger transportation experience, and our dispatchers work 24/7 to assist you at any time. We have all kinds of vehicles with drivers, including cars, vans, minibuses, and buses, to suit your needs.In our email terms, we offer a quote and confirmation for all our services. We are acting as an agent with licensed coach operators across the United Kingdom, and we strive to make our services attractive to clients by offering great service alongside competitive rates. We offer professional group transport management services, including tours, large events, and contract work, and we have experience dealing with a range of clients from private individuals to multinational businesses. We aim to make every trip bespoke to your specific needs and preferences, and our clients receive exceptional care, including one-on-one support from our dedicated transport and logistical team.
                      <br /><br /><br />
                    </span>
                    <span className="font-semibold">
                      *For large group airport pick-up we will provide arrival meet and greet travel cordinator included with the fare.
                    </span>
                  </div>
                </div>
              </div>
            </div>


            <div className="pt-14 p-4 container-xl mx-auto">
              <div className="pb-4">
                <div className="text-[34px] md:text-[48px] pb-4 font-bold flex justify-start px-[25px] md:px-0">
                  Airports We Operate
                  <img src={BritishIcon} width="50px" height="50px" className="ml-4" />
                </div>
                <div className="py-[40px]">
                  <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                    We operate across all the airports in London. We strive to offer the best customer service and provide modern and high-quality fleet.

                    Heathrow airport is a major international airport in London, England. it is the largest of the six international airports serving London. Heathrow airport is used by over 80 airlines flying to 185 destinations in 84 countries, and it is the primary hub of British Airways. A total number of around 220,000 passengers use Heathrow airport everyday (80 million annually).
                  </div>
                  <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                    The airport is well served by Heathrow Express trains to Paddington,Piccadilly line trains,national express coaches, buses and hundreds of private Hire Car services. Of all the available options, nothing beats a professional chauffeur service that prioritize your comfort.
                  </div>
                  <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                    We are a TFL Licenced premium private hire firm with professional chauffeurs and quality cars. All the chauffeurs are CRB (criminal record bureau) checked and highly trained individuals.
                  </div>
                </div>
              </div>
              <div className="h-92">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 h-full ">
                  {SERVICE_AIRPORT.map((item, index) => {
                    return (
                      <div className="flex items-center h-20 px-2 py-2 m-4 space-x-6 text-black bg-gray-100 rounded-lg ">
                        <div className="px-4 py-2 font-semibold rounded-lg bg-yellow-1000">
                          {index + 1}
                        </div>
                        <div className="text-base font-semibold text-left">
                          {item.name},<div>{item.name2}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>


            </div>
            <BlogListing />
            <div className="container-xl mx-auto px-[5%] pb-[50px]">
              <div
                className="flex items-center justify-between border-[#FFD369] border-[1px] py-[20px] px-[15px] md:py-[40px] md:px-[30px] max-w-[326px] mt-[40px] hover:cursor-pointer "
                onClick={() => { history.push("/blog") }}
              >
                <span className="text-[#FFD369] text-[24px] md:text-[32px]">Load More</span>
                <img src={arrow} alt="" className="h-[26px] ml-[10px]" />
              </div>
            </div>
          </div>

        </main>
      </div >
      <Footer />
    </div >
  );
};

export default Service;
