import { boolean, number } from "yup";

export const INTIAL_VALUES = {
  isLoggedIn: false,
  busOnly: false,
  formType: 'airport',
  userInfo: {
    name: null,
    email: null,
    AUTH: null,
  },
  showNotification: false,
  notificationProperties: {
    showNotification: false,
    kind: "",
    message: "",
  },
  journeyInfo: {
    source: {},
    destination: "",
    stops: [],
    returnBooking: false,
    date: "",
    time: "",
    returnDate: "",
    returnTime: "",
    isAirport: true,
  },
  vechileInfo: {
  },
  flightInfo: {
    flightNumber: "",
    arrivalTime: "",
    nameBoard: "",
  },
  passengerInfo: {
    id: "",
    area: "",
    street_number: "",
    city: "",
    state: null,
    zip_code: "",
    ssn: "",
  },
  totalPrice: "",
  id: "",
  vehicleList: [],
  distance: number,
  duration: "",
};
