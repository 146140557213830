import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Navbar from "../../common/Navbar";
import { Input, Button } from "@bigbinary/neetoui";
import Footer from "../../common/Footer";
import ServicesApi from "../../api/passengerDetails";
import { useParams } from "react-router-dom";

import { useUserDispatch } from "../../Context";

const OtpPage = () => {
  const userDispatch = useUserDispatch();
  const { email } = useParams();
  const [otp, setOtp] = useState("");
  const history = useHistory();

  const onSubmit = async () => {
    const payload = {
      email: email,
      role: "user",
      password: otp,
    };
    try {
      const resp = await ServicesApi.OTPApi(payload);
      if (!resp.data.access_token) {
        history.push("/404");
        return null;
      }
      const decodeResponse = await ServicesApi.decodeAuthentication(
        resp.data.access_token
      );
      if (!decodeResponse.data.user._id) {
        throw new Error(decodeResponse.errorMessage || decodeResponse.data.errorMessage);
      }
      localStorage.setItem("user", JSON.stringify(resp.data));
      userDispatch({
        type: "SIGNIN",
        payload: {
          isLoggedIn: true,
          userInfo: {
            access_token: resp.data,
            ...(decodeResponse.data.user._id ? decodeResponse.data.user : {}),
          },
        },
      });
      history.push("/");
    } catch (error) {
      history.push("/");
      userDispatch({
        type: "NOTIFICATION",
        payload: {
          showNotification: true,
          kind: "error",
          notificationMessage: error,
        },
      });
      // console.log(error);
    }
  };

  return (
    <div className="flex flex-col  inner-page text-white bg-black authentication-page">
      <Navbar />
      <div className="authentication pb-4 lg:py-16">
        <div className="container container-xl">
          <div className="authentication__box">
            <div className="authentication__heading-wrap">
              <h1 className="h2">Welcome</h1>
              <p>{email}</p>
            </div>
            <div>
              <Input
                nakedInput
                placeholder="Enter your code"
                className="border-b border-gray-400"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
            </div>
            <div className="authentication__button-wrap">
              <Button
                label="Next"
                className="authentication__submit"
                onClick={() => onSubmit()}
              />
              <Link
                title="Create account"
                onClick={() => history.push("/signup")}
                className="authentication__alt-link "
              >
                Create account
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OtpPage;
