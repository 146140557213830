import classNames from "classnames";
import PassagerForm from "./Form";
import { Button } from "@bigbinary/neetoui";

const PassagerDetails = ({ onTabChange }) => {
  return (
    <div className="flex justify-center">
      <div className={classNames("max-w-2xl w-full")}>
        <PassagerForm />
        <div className="flex justify-center mx-4 w-72">
          <Button label="Get Quote" size="large" fullWidth />
        </div>
      </div>
    </div>
  );
};

export default PassagerDetails;
