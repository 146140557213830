import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@bigbinary/neetoui";
import useComponentVisible from "../Utils/OutsideClick";
import Logo from "../assets/logo.png";
import LogoYellow from "../assets/logo-yellow.png";
import { useHistory } from "react-router-dom";
import Headroom from "react-headroom";
import { useUserDispatch, useUserState } from "../Context";
import ServicesApi from "../api/passengerDetails";
import ModifyBooking from "../pages/MyBookings/ModifyBooking";

const Navbar = ({ setShowBookingModify }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const userDispatch = useUserDispatch();
  const history = useHistory();
  const userState = useUserState();
  const [isLoggedIn, setIsLoggedIn] = useState(userState.isLoggedIn);


  const servicePage = () => {
    history.push("/services");
  };
  const contactPage = () => {
    history.push("/contact");
  };

  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 40) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const btnLoginAction = () => {
    if (isLoggedIn) {
      userDispatch({
        type: "SIGNIN",
        payload: { isLoggedIn: false, userInfo: {} },
      });
      ServicesApi.logOut();
      setIsLoggedIn(false);
      history.push("/");
    } else history.push("/signin");
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  const keepFocus = (e) => {
    e.target.classList.add("nav__dropdown_active");
  };

  const removeFocus = (e) => {
    e.target.classList.remove("nav__dropdown_active");
  };

  return (
    <>
      <nav
        ref={ref}
        className={`nav-mobile ${isComponentVisible && "nav-mobile_visible"}`}
      >
        <div className="self-end">
          <Button
            style="secondary"
            onClick={() => setIsComponentVisible((state) => !state)}
            icon={() => <i className="text-2xl ri-close-line "></i>}
          />
        </div>
        <div className="nav-mobile__links">
          <Link onClick={() => {
            setIsComponentVisible(false)
            history.push("/")
            setShowBookingModify(false)
          }}>Home</Link>
          <Link onClick={() => servicePage()}>Services</Link>
          <Link onClick={() => contactPage()}>Contact Us</Link>
          <div className="nav-mobile__dropdown" tabIndex={0}>
            <span className="nav-mobile__dropdown-title">Travel Agent</span>
            <ul className="nav-mobile__dropdown-sublist">
              <li className="nav-mobile__dropdown-li">
                <Link onClick={() => history.push("/agents")}>Login</Link>
              </li>
              <li className="nav-mobile__dropdown-li">
                <Link onClick={() => history.push("/agents")}>Register</Link>
              </li>
            </ul>
          </div>
          <div className="nav-mobile__dropdown" tabIndex={0}>
            <span className="nav-mobile__dropdown-title">Partner</span>
            <ul className="nav-mobile__dropdown-sublist">
              <li className="nav-mobile__dropdown-li">
                <Link onClick={() => history.push("/dispatcher")}>Login</Link>
              </li>
              <li className="nav-mobile__dropdown-li">
                <Link onClick={() => history.push("/dispatcher")}>
                  Register
                </Link>
              </li>
            </ul>
          </div>
          <Link onClick={() => history.push("/blog")}>Blogs</Link>
          {isLoggedIn ? (
            <Link onClick={() => history.push("/my-booking")}>My Bookings</Link>
          ) : (
            <Link onClick={() => {
              history.push("/my-booking-modify")
              // setIsComponentVisible(false)
              // setShowBookingModify(true)
            }}>My Bookings</Link>
          )}
          <Link
            icon="ri-user-fill"
            // iconPosition="left"
            onClick={() => btnLoginAction()}
          >
            {isLoggedIn ? "Sign Out" : "Login"}
          </Link>
        </div>
      </nav>
      <div className="hamburger-wrap">
        <div className="p-4 hamburger-inner">
          <Button
            style="secondary"
            className="hamburger"
            role="navigation"
            onClick={() => setIsComponentVisible((state) => !state)}
            icon={() => <i className="ri-menu-line "></i>}
          />
        </div>
        <div
          className={
            navbar
              ? "logo-mobile__wrap logo-mobile__wrap_active"
              : "logo-mobile__wrap"
          }
        >
          <div className="navbar__text-banner">
            <h6 className="navbar__text-banner-text">
              <span className="visually-hidden">Ridewing</span>
              One stop solution for premium airport transfers!
            </h6>
          </div>
          <Link
            onClick={() => {
              history.push("/");
            }}
            className="logo-mobile__link logo-mobile__link_logo"
          >
            <img className="logo-mobile__img" src={LogoYellow} alt="Ridewing" />
          </Link>
        </div>
      </div>
      {/* Headroom helps create a fixed header that smoothly transitions in and out of view */}
      {/* <Headroom> */}
      <div className="bg-yellow-1000 w-full fixed top-0 z-[7]">
        <div className="nav__header">
          <h6 className="container container-xl">
            <span>No hidden costs</span>
            <span className="px-[10px] md:px-[30px]">|</span>
            <span>24/7 Customer support</span>
            <span className="px-[10px] md:px-[30px]">|</span>
            <span>Free cancellation up to 24 hours before departure</span>
          </h6>
        </div>
        <nav className="justify-between hidden px-6 text-black lg:flex container container-xl">
          <div className="flex items-center">
            <Link
              onClick={() => {
                history.push("/");
              }}
              className="logo"
            >
              <img className="logo__img" src={Logo} alt="Ridewing" />
            </Link>
            <p className="text-[14px] font-semibold pl-[10px]">Limousine Service London</p>
          </div>
          <div className="nav__links">
            <Link onClick={() => history.push("/")}>Home</Link>
            <Link onClick={() => servicePage()}>Services</Link>
            <Link onClick={() => contactPage()}>Contact Us</Link>
            <div
              className="nav__dropdown"
              tabIndex={0}
              onFocus={keepFocus}
              onBlur={removeFocus}
            >
              <span className="nav__dropdown-title">Travel Agent</span>
              <ul className="visually-hidden">
                <li className="nav__dropdown-li">
                  <Link
                    to={{ pathname: "https://agent.ridewing.co.uk" }}
                    target="_blank"
                  >
                    Login
                  </Link>
                </li>
                <li className="nav__dropdown-li">
                  <Link onClick={() => history.push("/agents")}>
                    Register
                  </Link>
                </li>
              </ul>
            </div>
            <div className="nav__dropdown" tabIndex={0}>
              <span className="nav__dropdown-title">Partner</span>
              <ul className="visually-hidden">
                <li className="nav__dropdown-li">
                  <Link
                    to={{ pathname: "https://partner.ridewing.co.uk" }}
                    target="_blank"
                  >
                    Login
                  </Link>
                </li>
                <li className="nav__dropdown-li">
                  <Link onClick={() => history.push("/dispatcher")}>
                    Register
                  </Link>
                </li>
              </ul>
            </div>
            <Link onClick={() => history.push("/blog")}>
              Blogs
            </Link>
            {isLoggedIn ? (
              <Link onClick={() => history.push("/my-booking")}>
                My Bookings
              </Link>
            ) : (
              <Link onClick={() => history.push("/my-booking-modify")}>
                My Bookings
              </Link>
            )}


            <Link
              className="button bg-gray-100"
              icon="ri-user-fill"
              // iconPosition="left"
              onClick={() => btnLoginAction()}
            >
              {isLoggedIn ? "Sign Out" : "Login"}
            </Link>
          </div>
        </nav>
      </div>
      {/* </Headroom> */}
      <div className="md:h-[100px]"></div>

    </>
  );
};

export default Navbar;
