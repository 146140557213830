import { useUserDispatch, useUserState } from "../../../Context";
// import { VEHCILEDETAILS } from "./constants";
import EachVehicle from "./Eachvehicle";
import Map from "../Map";
import { useHistory } from "react-router-dom";

import { useState, useEffect } from "react";
import axios from 'axios'

const VehicleDetails = ({ onTabChange }) => {
  const userDispatch = useUserDispatch();
  const { vehicleList, journeyInfo, busOnly, formType } = useUserState();
  const history = useHistory();

  useEffect(() => {
    // console.log("Vehicle list", );
  }, [])

  const onSubmit = (data) => {
    // if (data?.vehicle_cat_dt?.is_quote) {
    //   history.push("/modify");
    // } else {

    // Check if the selected vehicle is quote or not
    data.vehicle_cat_dt.passenger_no > 10 ? userDispatch({ type: "BUSONLY", payload: true }) : userDispatch({ type: "BUSONLY", payload: false });
    userDispatch({ type: "VEHCILEDETAILS", payload: data });
    if (journeyInfo.isAirport) {
      onTabChange("flight");
    } else {
      onTabChange("passenger");
    }
    // }
  };


  if (vehicleList?.length && vehicleList?.length > 0) {
    return (
      <div className="inner-page-tab-content">
        <div className="md:pt-5">
          {/* if form!=hourly and busOnly!=true below code works */}
          {busOnly ?
            vehicleList
              .filter(item => (busOnly ? item.vehicle_cat_dt.passenger_no > 10 : true))
              .map((item, index) => {
                return (
                  <EachVehicle
                    item={item}
                    isAirport={journeyInfo.isAirport}
                    busOnly={busOnly}
                    key={index}
                    onTabChange={onTabChange}
                    onSubmit={onSubmit}
                    formType={formType}
                  />)
              })
            :
            vehicleList
              // .filter(item => (busOnly ? item.vehicle_cat_dt.passenger_no > 10 : true)) //if busOnly is true? check is_quote=true, else map
              // .filter(item => !(formType === 'hourly' && item.vehicle_cat_dt.is_quote)) //No quote booking in hourly booking
              .map((item, index) => {
                return (
                  <EachVehicle
                    item={item}
                    isAirport={journeyInfo.isAirport}
                    busOnly={busOnly}
                    key={index}
                    onTabChange={onTabChange}
                    onSubmit={onSubmit}
                    formType={formType}
                  />)
              })
          }
        </div>
        <div className="lg:pt-12">
          <Map page="vehicle" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="inner-page-tab-content">
        <div className="md:pt-5">
          <p>
            Pick up time is so too close to schedule a ride through website, Or you refreshed this page. We
            may still able to help you, please feel free to contact us on:
            <div className="new-line">
              Phone: <a href="tel:+44 333 444 1248">+44 333 444 1248</a>|
              <a href="tel:+44 208 432 4325">+44 208 432 4325</a>
            </div>
            <div className="new-line">
              Message us in WhatsApp
              <a href="https://wa.me/+447500200570" target="_blank">
                {" "}
                :+44 7500 200 570
              </a>
            </div>
            <div className="new-line">
              Email:{" "}
              <a href="mailto:info@ridewing.co.uk">info@ridewing.co.uk</a>
            </div>
          </p>
        </div>
        <div className="lg:pt-12">
          <Map page="vehicle" />
        </div>
      </div>
    );
  }
};

export default VehicleDetails;
