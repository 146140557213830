import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Button } from "@bigbinary/neetoui";
import Footer from "../../common/Footer";
import { useHistory } from "react-router-dom";
import PastDetails from "./Past";
import UpcomingDetails from "./Upcoming";
import CancelDetails from "./Cancel";
import ServicesApi from "../../api/passengerDetails";
import { useUserState } from "../../Context";
import ModifyBooking from "./ModifyBooking";
import Navbar from "../../common/Navbar";

const Tabs = () => {
  const userState = useUserState();
  const [userId, setUserId] = useState(userState.userInfo._id || null);
  const [state, setState] = useState("upcoming");
  const [showBookingModify, setShowBookingModify] = useState(false);
  const [modifyTripDetails, setModifyTripDetails] = useState({
    booking_id: "",
    mail: "",
    name: "",
    phone: ""
  });
  const [bookingList, setBookingList] = useState([]);
  const [userBookingState, setPickupTime] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!userId) history.push("/");
    onTabChange("upcoming");
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [showBookingModify]);

  useEffect(() => {
    if (state === "confirm") {
      history.push("/confirm");
    }
  }, [state]);

  const tableItemsListFormat = (itemList) => {
    if (!Array.isArray(itemList) || itemList?.length === 0) return [];
    return itemList.map((item) => ({
      id: item.booking_number,
      customerName: item.cust_name,
      pickUpLoc: item.pick_up_loc,
      dropOffLoc: item.drop_off_loc,
      pickupTime: item.pickup_time,
      fare: item.fare,
    }));
  };

  const onTabChange = async (value) => {
    if (!value) return null;
    setLoading(true);
    setState(value);
    const tabCorrespondingAPINames = {
      upcoming: "upcomingbooking",
      past: "pastbooking",
      cancelled: "cancelledbooking",
    };
    const bookingList = await ServicesApi.getUserBookingsApi(
      userId,
      tabCorrespondingAPINames[value]
    );
    // console.log(bookingList);
    setBookingList(bookingList)
    // Array.isArray(bookingList) &&
    //   setBookingList(tableItemsListFormat(bookingList));
    setLoading(false);
  };

  return (
    <div className="flex flex-col  inner-page text-white bg-black ">
      <Navbar />
      {showBookingModify ?
        <div className="md:mt-0 mt-[80px]">
          <Button icon="ri-arrow-left-circle-line pl-2 pt-2 text-4xl" style="icon" onClick={() => setShowBookingModify(false)} />
          <ModifyBooking modifyTripDetails={modifyTripDetails} />
        </div>
        :
        <div className="md:mt-0 my-[100px] min-h-[75vh]">
          <Button icon="ri-arrow-left-circle-line pl-2 pt-2 text-4xl" style="icon" onClick={() => history.push("/")} />
          <div className="flex flex-col flex-1 ">
            <div className="flex self-center w-full space-x-2 justify-evenly md:w-1/2 ">
              <div
                className={classNames(["text-white py-1 hover:cursor-pointer"], {
                  "border-white border-b-2": state === "upcoming",
                })}
                onClick={() => onTabChange("upcoming")}
              >
                Upcoming
              </div>
              <div
                className={classNames(["text-white  py-1 hover:cursor-pointer"], {
                  "border-white border-b-2": state === "past",
                })}
                onClick={() => onTabChange("past")}
              >
                Past
              </div>
              <div
                className={classNames(["text-white py-1 hover:cursor-pointer"], {
                  "border-white border-b-2": state === "cancelled",
                })}
                onClick={() => onTabChange("cancelled")}
              >
                Cancelled
              </div>
            </div>
            {!loading ? (
              <>
                <div>
                  {state === "upcoming" && <UpcomingDetails bookingList={bookingList} setShowBookingModify={setShowBookingModify} setModifyTripDetails={setModifyTripDetails} />}
                </div>
                <div>{state === "past" && <PastDetails {...bookingList} />}</div>
                <div>
                  {state === "cancelled" && <CancelDetails {...bookingList} />}
                </div>
              </>
            ) : (
              <></>
            )}
            {(bookingList.length === 0) ?
              <div className="container mx-auto flex justify-center items-center pt-10">
                <span>No bookings available</span>
              </div>
              : ""
            }
          </div>
        </div>
      }
      <Footer />

    </div>
  );
};

export default Tabs;
