import { components } from "react-select";
import { Field } from "formik";
import { useEffect, useState } from "react";
import { Select } from "@bigbinary/neetoui/formik";
import ServicesApi from "../../api/passengerDetails";
import classNames from "classnames";

const colourStyles = {
  singleValue: (provided, { data }) => ({
    ...provided,
    color: "white",
  }),
};

const PickupComponent = ({ setFieldValue, type }) => {
  const [AirportList, setAirportList] = useState([]);
  const fetchAirport = async () => {
    try {
      const airportList = await ServicesApi.AirportListApi();
      const AirportLabelList = airportList.map((item) => {
        return { label: item.name, value: item.code, _id: item._id };
      });
      setAirportList(AirportLabelList);
    } catch (error) {
      console.debug("api error", error);
    }
  };

  useEffect(() => {
    fetchAirport();
    // console.log("fieldValue", setFieldValue);
  }, []);

  const removeValue = () => {
    setFieldValue(type, "");
  };
  
  return (
    <Field name={type}>
      {({ field, form: { touched, errors, setErrors }, meta, props }) => {
        return (
          <div className="flex w-full justify-evenly">
            <Select
              name={type}
              {...props}
              placeholder={
                type === "destination" ? "Dropoff location" : "Pickup location"
              }
              styles={colourStyles}
              options={AirportList}
              onChange={(e) => {
                if (e) {
                  setFieldValue(type, e);
                  setErrors({ ...errors, [type]: "" });
                }
              }}
              components={{
                SingleValue: ({ children, ...props }) => {
                  return (
                    <components.SingleValue {...props}>
                      <i className="self-center pr-2 ri-plane-line " />
                      {children}
                    </components.SingleValue>
                  );
                },
                Input: ({ children, ...props }) => {
                  return (
                    <components.Input {...props} className="px-5">
                      {children}
                    </components.Input>
                  );
                },
                Placeholder: ({ children, ...props }) => {
                  return (
                    <components.Placeholder {...props}>
                      <i className="self-center pr-2 ri-plane-line " />
                      {children}
                    </components.Placeholder>
                  );
                },
                DropdownIndicator: ({ hasValue }) => {
                  if (hasValue) {
                    return (
                      <i
                        className="self-center pr-2 ri-close-line "
                        onClick={() => removeValue()}
                      />
                    );
                  } else {
                    return null;
                  }
                },
                IndicatorSeparator: () => null,
              }}
            />
            {/* {meta.error && (
              <p className={classNames(["text-red-500 text-xs pt-2"])}>
                {meta.error}
              </p>
            )} */}
          </div>
        );
      }}
    </Field>
  );
};

export default PickupComponent;
