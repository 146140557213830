import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// production ip
axios.defaults.baseURL = "https://api.ridewing.co.uk";
// development ip
// axios.defaults.baseURL = "http://18.216.17.142:3000";
// axios.defaults.baseURL = "http://localhost:3000";
axios.defaults.headers.post["Content-Type"] = "application/json";

let paymentProd = `https://secure.worldpay.com/wcc/purchase?instId=E767379E0A5F31CDA9BDA7D1FF5&currency=GBP&testMode=0`;
// let paymentTest = `https://secure.worldpay.com/wcc/purchase?instId=1445371&currency=GBP&testMode=100`;

const availbleServicesApi = (payload) =>
  axios.post("api/vehicle/availableservices", payload);
// Hourly Booking
const HourlyBooking = (payload) =>
  // https://api.ridewing.co.uk/api/vehicle/availableserviceshrly
  axios.post("/api/vehicle/availableserviceshrly", payload);
// Intercity Booking
const IntercityBooking = (payload) =>
  axios.post("/api/vehicle/availableservicesintercity", payload);
const FindBooking = async (booking, mail, date) =>
  await axios.get(`/api/booking/users?booking_number=${booking}&cust_email=${mail}&pickup_date=${date}`).then((res) => {
    return res.data;
  });
const AirportListApi = async () =>
  await axios.get("/api/booking/airport").then((res) => {
    return res.data;
  });
// Airport2Airport Home page card listing
const Airport2AirportList = async () =>
  await axios.get("/api/adminsettings/airport-to-airport").then((res) => {
    return res.data;
  });
//VehicleList of Chosen airport2airport
const Airport2Airport = async (id) =>
  await axios.get(`/api/vehicle/airport-to-airport/availableservices?airport_to_airport_id=${id}`).then((res) => {
    return res.data;
  });
// Manage Booking Form
const UserSupport = (payload) =>
  axios.post("/api/users/support", payload);
const BuffertimeApi = (id, payload) =>
  axios.get(`api/booking/buffertime`).then((res) => {
    return res.data;
  });
const ChildCountApi = (id, payload) =>
  axios.get(`/api/booking/childseat`).then((res) => {
    return res.data;
  });

const SignupApi = async (payload) => {
  const res = await AxiosPost(payload, "/api/users");
  return res;
};
// Get all blogs
const Blogs = () =>
  axios.get("/api/adminsettings/blog?isactive=true").then((res) => {
    return res.data;
  });
const Reviews = () =>
  axios.get("/api/users/reviews").then((res) => {
    return res.data;
  });
// Get single full blog
const GetBlog = (payload) =>
  axios.get(`/api/adminsettings/blog/${payload}`).then((res) => {
    return res.data;
  });
const ContactUsEmail = (payload) =>
  axios.post("/api/users/support", payload).then((res) => {
    return res.data;
  });

const AgentRegEmail = (payload) =>
  axios.post("/api/agent/agent_reg", payload).then((res) => {
    return res.data;
  });

const DispatcherRegEmail = (payload) =>
  axios.post("/api/dispatcher/dispatcher_reg", payload).then((res) => {
    return res.data;
  });

const SigninApi = (payload) =>
  axios.post("api/users/checkuser", payload).then((res) => {
    return res.data;
  });

const OTPApi = async (payload) => {
  const resp = await axios.post("/api/users/login", payload);
  return resp;
};

const getUserBookingsApi = async (user_id, booking_type) => {
  try {
    const response = await axios.get(`/api/users/${booking_type}/${user_id}`);
    // const response = await axios.get(`/api/users/${booking_type}/80`);
    return response.data;
  } catch (error) {
    // console.log("getUserBookingsApi error", error);
    return null;
  }
};

const decodeAuthentication = async (token) => {
  try {
    const response = await axios.post(
      "/api/users/decodetoken",
      { token },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    // console.log("decode api error", error);
    return null;
  }
};

const getAgents = async (agentId, AUTHX) => {
  try {
    const response = await axios.post("/api/agents/create", {
      company_name: "",
      contact_person: "",
      email: "",
      address: "",
      vat_reg_number: "",
      phone: "",
      vat_cert_exp_date: "",
    });
    return response.data;
  } catch (error) {
    // console.log("agents error", error);
    return null;
  }
};

const getDispatcher = async () => {
  try {
    const response = await axios.post(`/api/dispatcher/create`, {
      company_name: "",
      contact_person: "",
      email: "",
      address: "",
      vat_reg_number: "",
      cmpy_res_cert: "",
      phone: "",
    });
    return response.data;
  } catch (error) {
    // console.log("agents error", error);
    return null;
  }
};

const logOut = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const createHourlyBooking = async (payload) =>
  await axios.post("api/booking/hrlycreate", payload).then((res) => {
    return res.data;
  });

const createBooking = async (payload) =>
  await axios.post("api/booking/create", payload).then((res) => {
    return res.data;
  });

const createQuote = async (payload) =>
  await axios.post("api/quote/create", payload).then((res) => {
    return res.data;
  });

const Payment = (id, amount) => {
  if ((id, amount))
    window.open(paymentProd + `&cartId=${id}&amount=${amount}`, "_self");
};

async function AxiosPost(payload, url) {
  return await axios
    .post(url, payload)
    .then((response) => response)
    .catch((error) =>
      toast.warning(error.response.data.error, {
        // Set to 15sec
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      })
    );
}

const ServicesApi = {
  availbleServicesApi,
  AirportListApi,
  Airport2AirportList,
  Airport2Airport,
  HourlyBooking,
  UserSupport,
  BuffertimeApi,
  SignupApi,
  SigninApi,
  OTPApi,
  ChildCountApi,
  createHourlyBooking,
  createBooking,
  Payment,
  logOut,
  getCurrentUser,
  getUserBookingsApi,
  getDispatcher,
  getAgents,
  decodeAuthentication,
  ContactUsEmail,
  AgentRegEmail,
  DispatcherRegEmail,
  createQuote,
  Blogs,
  GetBlog,
  Reviews,
  IntercityBooking,
  FindBooking
};

export default ServicesApi;
