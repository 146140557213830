import React, { useMemo } from "react";
import { Select, Button } from "@bigbinary/neetoui";
import { Input as FormikInput } from "@bigbinary/neetoui/formik";
import { Field, Form, Formik } from "formik";
import ServicesApi from "../../api/passengerDetails";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import codes from "country-calling-code";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  control: (base) => ({
    ...base,
    border: "none !important",
    color: "white !important",
    boxShadow: "none",
  }),
  option: (provided) => ({
    ...provided,
    color: "white !important",
    border: "none !important",
  }),
  control: (provided) => ({
    ...provided,
    color: "white !important",
    border: "none !important",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white !important",
    border: "none !important",
  }),
};

const Signup = () => {
  const history = useHistory();
  const intialState = {
    salutation: [
      {
        label: "Mr",
        value: "mr",
      },
    ],
    firstName: "",
    lastName: "",
    email: "",
    phone: { id: "+44", number: "" },
  };

  const options = useMemo(
    () =>
      codes.map((item) => {
        return { label: item.country, value: item.countryCodes };
      }),
    []
  );

  const onSubmit = async (data) => {
    const payload = {
      email: data.email,
      role: "user",
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phone,
      salutation: data.salutation,
    };
    const res = await ServicesApi.SignupApi(payload);
    // console.log(res);
    if (res.status == 201 && !res.error) {
      toast.success("Thanks for Registering with Ridewing!!!");
      history.push("/");
    }
    // else if (res.error && res.statusCode == 409) {
    //   toast.warning(res.error, {
    //     // Set to 15sec
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 5000,
    //   });
    // } else {
    //   toast.error(res.error ? res.error : "unknown error", {
    //     // Set to 15sec
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 5000,
    //   });
    // }
  };

  return (
    <div className="flex flex-col  inner-page text-white bg-black authentication-page">
      <Navbar />
      <div className="authentication pb-4 lg:py-16">
        <div className="container container-xl">
          <div className="authentication__box">
            <div className="authentication__heading-wrap">
              <h1 className="h2">Register</h1>
            </div>
            <Formik initialValues={intialState} onSubmit={onSubmit}>
              {({ values, setFieldValue }) => {
                return (
                  <Form>
                    <div className="flex flex-col justify-center space-y-10">
                      <Field name="salutation">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => (
                          <div>
                            <Select
                              label="Salutation"
                              styles={style}
                              className="w-20 pr-2 border-0"
                              nakedInput
                              size="small"
                              options={[
                                {
                                  label: "Mrs",
                                  value: "mrs",
                                },
                                {
                                  label: "Mr",
                                  value: "mr",
                                },
                              ]}
                              {...field}
                            />
                            {meta.touched && meta.error && (
                              <div className="error">{meta.error}</div>
                            )}
                          </div>
                        )}
                      </Field>
                      <div className="flex justify-between md:space-x-16 gap-4">
                        <FormikInput
                          name="firstName"
                          placeholder="First Name"
                          label="First Name"
                          required
                          nakedInput
                          className="border-b border-gray-400"
                        />
                        <FormikInput
                          name="lastName"
                          label="Last Name"
                          placeholder="Last Name"
                          required
                          nakedInput
                          className="border-b border-gray-400"
                        />
                      </div>
                      <FormikInput
                        name="email"
                        label="Email"
                        required
                        className="border-b border-gray-400 "
                        nakedInput
                      />
                      {/* <div className="flex md:w-1/2">
                        <Select
                          name="Phone.id"
                          required
                          options={options}
                          className="border-b border-gray-400 "
                          size="small"
                          maxlength="3"
                        />
                      </div> */}
                      <div className="flex md:w-1/2">
                        <FormikInput
                          label="Phone number"
                          name="phone.number"
                          required
                          className="border-b border-gray-400 "
                          nakedInput
                        />
                      </div>
                      <p className="text-sm">
                        With the registration you accept the
                        <Link to="/terms-and-conditions" target="_blank">
                          <span className="px-1 font-semibold underline ">
                            Terms and Conditions
                          </span>
                        </Link>
                        as well as the
                        <Link to="/privacy-policy" target="_blank">
                          <span className="px-1 font-semibold underline ">
                            Privacy Policy
                          </span>
                        </Link>
                      </p>
                      <div className="authentication__button-wrap">
                        <Button
                          label="Register"
                          className="authentication__submit"
                          type="submit"
                        />
                        <Link
                          title="Login to your account"
                          onClick={() => history.push("/signin")}
                          className="authentication__alt-link "
                        >
                          Already have an account? Sign in
                        </Link>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
