import React, { useEffect, useRef, useState } from "react";
import { Select, TimePicker, Button } from "@bigbinary/neetoui";
import { Input as FormikInput } from "@bigbinary/neetoui/formik";
import { Field, Form, Formik } from "formik";
import ServicesApi from "../../../api/passengerDetails";
import * as dayjs from "dayjs";
import { FLIGHT_SCHEMA } from "../../../Utils/formikValidation";
import classNames from "classnames";

const colourStyles = {
  indicatorsContainer: (base, state) => ({
    background: "#393e46",
  }),
  control: (base, state) => ({
    ...base,
    background: "#393e46",
  }),
  singleValue: (provided) => ({
    ...provided,

    color: "white",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "red" : "black",
  }),
};

const FlightForm = ({ modify, formRef, onSubmit }) => {
  const [bufferTimeList, setBufferTimeList] = useState([]);

  const intialState = {
    flightNumber: "",
    arrivalTime: "",
    pickupText: bufferTimeList[0],
    nameBoard: "",
  };
  const fetchBufferTime = async () => {
    try {
      const List = await ServicesApi.BuffertimeApi();

      const transformedList = List.map((item) => {
        return {
          label: item.buffer_time_str,
          value: item.buffer_time_mins,
          id: item._id,
        };
      });
      setBufferTimeList(transformedList);
    } catch (error) {
      console.debug("api error", error);
    }
  };

  useEffect(() => {
    fetchBufferTime();
  }, []);

  const calculateTime = (values) => {
    let updatedTime = dayjs(values.arrivalTime);
    if (values?.pickupText?.value) {
      updatedTime = updatedTime.add(values.pickupText.value, "minute");
      return updatedTime.format("HH:mm");
    } else {
      if (updatedTime.format("HH:mm") !== "Invalid Date")
        return updatedTime.format("HH:mm");
      else {
        return null;
      }
    }
  };

  return (
    <div className="w-full">
      <Formik
        initialValues={intialState}
        onSubmit={onSubmit}
        innerRef={formRef}
        validationSchema={FLIGHT_SCHEMA}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <div className="flex flex-col justify-center space-y-10 md:w-96">
                <FormikInput
                  name="flightNumber"
                  label="Flight Number"
                  required
                  placeholder="Flight Number"
                  // prefix={<i className="ri-map-pin-line"></i>}
                  // suffix={<i className="ri-focus-3-line"></i>}
                  className="w-full"
                />
                <div className="w-1/2">
                  <Field name="arrivalTime">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }) => (
                      <div>
                        <TimePicker
                          label="Flight Arrival Time"
                          className="text-white bg-transparent "
                          placeholder="Time"
                          {...field}
                          onSelect={(e) => {
                            setFieldValue("arrivalTime", e);
                          }}
                        />
                        {meta.touched && meta.error && (
                          <p
                            className={classNames([
                              "text-red-500 text-xs pt-2",
                            ])}
                          >
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>

                <Field name="pickupText">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <div>
                      <Select
                        {...field}
                        styles={colourStyles}
                        size="small"
                        label="When should your driver pick you up
                      from the airport?*"
                        options={bufferTimeList}
                        onChange={(e) => {
                          setFieldValue("pickupText", e);
                        }}
                      />
                      {meta.touched && meta.error && (
                        <p
                          className={classNames(["text-red-500 text-xs pt-2"])}
                        >
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>

                <div className="w-1/2">
                  Updated pickup time:
                  {calculateTime(values)}
                </div>
                <FormikInput
                  name="nameBoard"
                  label="Name on the Board"
                  placeholder="Name on the Board"
                  // prefix={<i className="ri-map-pin-line"></i>}
                  // suffix={<i className="ri-focus-3-line"></i>}
                  className="w-full"
                />
              </div>
              <div className="flex px-2 py-4 mt-4 text-xs border border-gray-200">
                <div className="pr-4">
                  <i className="ri-time-line"></i>
                </div>
                <div>
                  We will adjust your pickup time to your actual landing time
                  plus the additional time you have selected ({" "}
                  {values?.pickupText?.label} ) so that we can pick you on time
                  even if your flight is delayed.
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      {/* {modify && (
        <div className="flex justify-center w-72">
          <Button label="Next" size="large" fullWidth />
        </div>
      )} */}
    </div>
  );
};

export default FlightForm;
