import FlightForm from "./Form";

const FlightDetails = ({ onTabChange, state }) => {
  return (
    <div className="justify-center md:flex">
      <div className="max-w-3xl md:mt-5 md:w-full">
        <FlightForm state={state} onTabChange={onTabChange} />
      </div>
    </div>
  );
};

export default FlightDetails;
