import { useRef, useState } from "react";
import { useUserDispatch } from "../../../Context";
import Map from "../Map";
import FlightForm from "./Form";

const FlightDetails = ({ onTabChange, modify = false }) => {
  const userDispatch = useUserDispatch();
  const formRef = useRef();

  const onSubmit = (data) => {
    userDispatch({ type: "FLIGHTDETAILS", payload: data });

    onTabChange("passenger");
  };

  return (
    <div className="inner-page-tab-content">
      <div className="pt-6 md:pt-12">
        <FlightForm modify={true} onSubmit={onSubmit} formRef={formRef} />
      </div>
      {!modify && (
        <div className="lg:pt-12">
          <Map onTabChange={onTabChange} page="flight" formRef={formRef} />
        </div>
      )}
    </div>
  );
};

export default FlightDetails;
