import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";

const Terms = () => {
  return (
    <div className="flex flex-col  inner-page text-white bg-black ">
      <Navbar />
      <div className="flex flex-col items-center justify-center px-4 overflow-auto">
        <div className="px-2 pt-[100px] md:pt-4 space-x-2 justify-evenly md:w-3/5 self-center pb-[40px]">
          <div>
            <h3 className="text-center font-bold">
              Terms and conditions of use
            </h3>
            <br />
            <h3>Introduction</h3>
            <div className="p-2 text-justify">
              <p>
                These terms and conditions apply between you, the User of this
                Website (including any sub-domains, unless expressly excluded by
                their own terms and conditions), and SS Carriage LTD (trading as
                Ridewing), the owner and operator of this Website. Please read
                these terms and conditions carefully, as they affect your legal
                rights. Your agreement to comply with and be bound by these
                terms and conditions is deemed to occur upon your first use of
                the Website. If you do not agree to be bound by these terms and
                conditions, you should stop using the Website immediately.
              </p>
              <br />
              <p>
                In these terms and conditions, <b>User</b> or <b>Users</b> means
                any third party that accesses the Website and is not either (i)
                employed by SS Carriage LTD (trading as Ridewing) and acting in
                the course of their employment or (ii) engaged as a consultant
                or otherwise providing services to SS Carriage LTD (trading as
                Ridewing) and accessing the Website in connection with the
                provision of such services.
              </p>
              <br />
              <p>
                You must be at least 18 years of age to use this Website. By
                using the Website and agreeing to these terms and conditions,
                you represent and warrant that you are at least 18 years of age.
              </p>
            </div>
            <br />
            <h3>Intellectual property and acceptable use</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li key={1} className="text-justify p-2">
                  All Content included on the Website, unless uploaded by Users,
                  is the property of SS Carriage LTD (trading as Ridewing), our
                  affiliates or other relevant third parties. In these terms and
                  conditions, Content means any text, graphics, images, audio,
                  video, software, data compilations, page layout, underlying
                  code and software and any other form of information capable of
                  being stored in a computer that appears on or forms part of
                  this Website, including any such content uploaded by Users. By
                  continuing to use the Website you acknowledge that such
                  Content is protected by copyright, trademarks, database rights
                  and other intellectual property rights. Nothing on this site
                  shall be construed as granting, by implication, estoppel, or
                  otherwise, any licence or right to use any trademark, logo or
                  service mark displayed on the site without the owner's prior
                  written permission.
                </li>
                <li key={2} className="p-2">
                  You may, for your own personal, non-commercial use only, do
                  the following:
                  <div className="p-2 content-padding-left">
                    <ol className="list-alphabetic list-outside">
                      <li key="a" className="text-justify p-2">
                        Retrieve, display and view the Content on a computer
                        screen
                      </li>
                    </ol>
                  </div>
                </li>
                <li key={3} className="p-2">
                  You must not otherwise reproduce, modify, copy, distribute or
                  use for commercial purposes any Content without the written
                  permission of SS Carriage LTD (trading as Ridewing)
                </li>
              </ol>
            </div>
            <h3>Prohibited use</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={4} key={4} className="text-justify p-2">
                  You may not use the Website for any of the following purposes:
                  <div className="p-4">
                    <ol className="list-alphabetic list-outside">
                      <li key="a" className="text-justify p-2">
                        in any way which causes, or may cause, damage to the
                        Website or interferes with any other person's use or
                        enjoyment of the Website;
                      </li>
                      <li key="b" className="text-justify p-2">
                        in any way which is harmful, unlawful, illegal, abusive,
                        harassing, threatening or otherwise objectionable or in
                        breach of any applicable law, regulation, governmental
                        order;
                      </li>
                      <li key="c" className="text-justify p-2">
                        making, transmitting or storing electronic copies of
                        Content protected by copyright without the permission of
                        the owner.
                      </li>
                    </ol>
                  </div>
                </li>
              </ol>
            </div>
            <h3>Registration</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={5} key={5} className="text-justify p-2">
                  You must ensure that the details provided by you on
                  registration or at any time are correct and complete.
                </li>
                <li key={6} className="text-justify p-2">
                  You must inform us immediately of any changes to the
                  information that you provide when registering by updating your
                  personal details to ensure we can communicate with you
                  effectively.
                </li>
                <li key={7} className="text-justify p-2">
                  We may suspend or cancel your registration with immediate
                  effect for any reasonable purposes or if you breach these
                  terms and conditions.
                </li>
                <li key={8} className="text-justify p-2">
                  You may cancel your registration at any time by informing us
                  in writing to the address at the end of these terms and
                  conditions. If you do so, you must immediately stop using the
                  Website. Cancellation or suspension of your registration does
                  not affect any statutory rights.
                </li>
              </ol>
            </div>
            <h3>Password and security</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={9} key={9} className="text-justify p-2">
                  When you register on this Website, you will be asked to create
                  a password, which you should keep confidential and not
                  disclose or share with anyone.
                </li>
                <li key={10} className="text-justify p-2">
                  If we have reason to believe that there is or is likely to be
                  any misuse of the Website or breach of security, we may
                  require you to change your password or suspend your account.
                </li>
              </ol>
            </div>
            <br />
            <h3>Privacy Policy</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={11} key={11} className="text-justify p-2">
                  Use of the Website is also governed by our Privacy Policy,
                  which is incorporated into these terms and conditions by this
                  reference. To view the Privacy Policy, please click on the
                  following:{" "}
                  <a href="https://ridewing.co.uk/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
              </ol>
            </div>
            <br />
            <h3>Availability of the Website and disclaimers</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={12} className="text-justify p-2">
                  Any online facilities, tools, services or information that SS
                  Carriage LTD (trading as Ridewing)
                </li>
                <li className="text-justify p-2">
                  makes available through the Website (the Service) is provided
                  "as is" and on an "as available" basis. We give no warranty
                  that the Service will be free of defects and/or faults. To the
                  maximum extent permitted by the law, we provide no warranties
                  (express or implied) of fitness for a particular purpose,
                  accuracy of information, compatibility and satisfactory
                  quality. SS Carriage LTD (trading as Ridewing) is under no
                  obligation to update information on the Website.
                </li>
                <li className="text-justify p-2">
                  Whilst SS Carriage LTD (trading as Ridewing) uses reasonable
                  endeavours to ensure that the Website is secure and free of
                  errors, viruses and other malware, we give no warranty or
                  guarantee in that regard and all Users take responsibility for
                  their own security, that of their personal details and their
                  computers.
                </li>
                <li className="text-justify p-2">
                  SS Carriage LTD (trading as Ridewing) accepts no liability for
                  any disruption or non-availability of the Website.
                </li>
                <li className="text-justify p-2">
                  SS Carriage LTD (trading as Ridewing) reserves the right to
                  alter, suspend or discontinue any part (or the whole of) the
                  Website including, but not limited to, any products and/or
                  services available. These terms and conditions shall continue
                  to apply to any modified version of the Website unless it is
                  expressly stated otherwise.
                </li>
              </ol>
            </div>
            <br />
            <h3>Limitation of liability</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={16} className="text-justify p-2">
                  Nothing in these terms and conditions will: (a) limit or
                  exclude our or your liability for death or personal injury
                  resulting from our or your negligence, as applicable; (b)
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation; or (c) limit or exclude any of our or your
                  liabilities in any way that is not permitted under applicable
                  law.
                </li>
                <li className="text-justify p-2">
                  We will not be liable to you in respect of any losses arising
                  out of events beyond our reasonable control.
                </li>
                <li className="text-justify p-2">
                  To the maximum extent permitted by law, SS Carriage LTD
                  (trading as Ridewing)
                </li>
                <li className="text-justify p-2">
                  SS Carriage LTD (trading as Ridewing) accepts no liability for
                  any of the following:
                  <div className="p-4">
                    <ol className="list-alphabetic list-outside">
                      <li key="a" className="text-justify p-2">
                        any business losses, such as loss of profits, income,
                        revenue, anticipated savings, business, contracts,
                        goodwill or commercial opportunities;
                      </li>
                      <li key="b" className="text-justify p-2">
                        loss or corruption of any data, database or software;
                      </li>
                      <li key="c" className="text-justify p-2">
                        any special, indirect or consequential loss or damage.
                      </li>
                    </ol>
                  </div>
                </li>
              </ol>
            </div>
            <br />
            <h3>General</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={19} className="text-justify p-2">
                  You may not transfer any of your rights under these terms and
                  conditions to any other person. We may transfer our rights
                  under these terms and conditions where we reasonably believe
                  your rights will not be affected.
                </li>
                <li className="text-justify p-2">
                  These terms and conditions may be varied by us from time to
                  time. Such revised terms will apply to the Website from the
                  date of publication. Users should check the terms and
                  conditions regularly to ensure familiarity with the then
                  current version.
                </li>
                <li className="text-justify p-2">
                  These terms and conditions together with the Privacy Policy
                  contain the whole agreement between the parties relating to
                  its subject matter and supersede all prior discussions,
                  arrangements or agreements that might have taken place in
                  relation to the terms and conditions.
                </li>
                <li className="text-justify p-2">
                  The Contracts (Rights of Third Parties) Act 1999 shall not
                  apply to these terms and conditions and no third party will
                  have any right to enforce or rely on any provision of these
                  terms and conditions.
                </li>
                <li className="text-justify p-2">
                  If any court or competent authority finds that any provision
                  of these terms and conditions (or part of any provision) is
                  invalid, illegal or unenforceable, that provision or
                  part-provision will, to the extent required, be deemed to be
                  deleted, and the validity and enforceability of the other
                  provisions of these terms and conditions will not be affected.
                </li>
                <li className="text-justify p-2">
                  Unless otherwise agreed, no delay, act or omission by a party
                  in exercising any right or remedy will be deemed a waiver of
                  that, or any other, right or remedy.
                </li>
                <li className="text-justify p-2">
                  This Agreement shall be governed by and interpreted according
                  to the law of England and Wales and all disputes arising under
                  the Agreement (including non-contractual disputes or claims)
                  shall be subject to the exclusive jurisdiction of the English
                  and Welsh courts.
                </li>
              </ol>
            </div>
            <br />
            <h3>SS Carriage LTD (trading as Ridewing) details</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={26} className="text-justify p-2">
                  SS Carriage LTD (trading as Ridewing) is a company
                  incorporated in England and Wales with registered number
                  11730934 whose registered address is 491 Sipson Road, Sipson,
                  West Drayton, England, UB7 0JB and it operates the Website
                  www.ridewing.co.uk.
                </li>
              </ol>
            </div>
            <div className="p-2 flex flex-row">
              <p>
                You can contact SS Carriage LTD (trading as Ridewing) by email
                on &nbsp;
              </p>
              <a href="mailto:info@ridewing.co.uk">info@ridewing.co.uk</a>
            </div>
            <div className="p-2 flex flex-row">
              <p>
                RIDEWING IS THE TRADING NAME OF SS CARRIAGES LIMITED (11730934)
                REGISTERED IN ENGLAND & WALES
              </p>
            </div>
          </div>
          <br />
          <h3>QUOTATIONS & RATES AND CHARGES</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li className="p-2 text-justify" value={27}>
                All Prices Are In GBP and are inclusive of VAT (where
                applicable).
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
                We are open 24/7. The operation contact number is{" "}
                <a href="tel:0208 897 2173">0208 897 2173</a>
                (International:{" "}
                <a href="tel:+44 (0)208 897 2173">+44 (0)208 897 2173</a>
                ).
              </li>
              <li className="p-2 text-justify">
                All Quotations Are Valid For 7 Days and Include Airport -Meet
                and Greet, Waiting Time*, Parking Or Tolls. Gratuities Are At
                The Customer's Discretion.
              </li>

              <li className="p-2 text-justify">
                You Will Automatically Receive An 'Ridewing Journey
                Acknowledgement' Email For The Journey(s) You Have Paid For .
                You Are Responsible For Checking That The Details Received from
                Us Are Correct .
              </li>
              <li className="p-2 text-justify">
                You Will Manually Receive a Journey Details' Email and Text
                Message When The Journey Has Been Assigned To the Driver . This
                Will Contains the Pick Up Instructions and The Drivers Photo Id
                and Contact Telephone Number .
              </li>
            </ul>
          </div>

          <br />
          <h3>FREE MEET & GREET</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={28} className="p-2">
                We Don't Charge For Meet and Greet Service. Its Complimentary.
              </li>
            </ol>
          </div>
          <br />
          <h3>Ridewing RULES</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={29} className="p-2">
                Flight Landing Time: This Is the Time Which Your Flight Is
                Scheduled To Land .
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
                After the Flight Landed, There Will Be A 60 Minutes FREE Waiting
                Time At The Airport. Our Driver Will Be Waiting For You In The
                Arrivals Hall, With An “Airport Pickup” Name Board With Your
                Name On It. They Will Then Accompany You To the Vehicle.
              </li>
              <li className="p-2 text-justify">
                If You Realise That You Will Not Be Able To Meet The Driver
                Within The 60 Minutes, Then If You Contact Us, Our Driver Will
                Continue At The Airport But There Will Be An Additional Charge
                Of £6.00 For Every 15 Minutes. It Will Be Payable Before The
                Journey Begins. If You Are Still Not Out Or Contacted Us Within
                the Time Then the Driver Will Be Pulled Off and The Job Will Be
                Registered As a No Show.
              </li>
              <li className="p-2 text-justify">
                For Example, If Your Flight Lands At 10:00 am, and You Have 60
                Mins Free Waiting Time After The Flight Arrival; Our Driver Will
                Be In The Terminal Till 11 am FREE Of Charge. After This Time,
                If Requested, the Driver Will Wait An Additional Time Chargeable
                At £6.00 For Every 15 Minutes.
              </li>
              <li className="p-2 text-justify">
                If You Cannot Locate Your Driver and You Seek An Alternative
                Method Of Transport Without First Contacting Us To Try and
                Resolve the Situation, You Will Be Charged the Tariff Rate Based
                On the Service Booked.
              </li>
              <li className="p-2 text-justify">
                Our 24/7 Contact Number Is +44 (0)208 897 2173
              </li>
            </ul>
          </div>
          <br />
          <h3>HOTEL AND ADDRESS PICK-UP RULES:</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={30} className="p-2">
                We Allow a 15 Minutes FREE Waiting Time From The Requested
                Pickup Time At Hotel and Address Pick-ups.
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
                Any Additional Time Chargeable At £0.80 For Every Minute.It Will
                Be Payable Before The Journey Began. If You Are Still Not Out
                within the 10 minutes extra time allocated with your request Or
                Contacted Us Within the Time Then the Driver Will Be Pulled Off
                and The Job Will Be Registered As a No Show.
              </li>
            </ul>
          </div>
          <br />
          {/* <h3>CRUISE / FERRY PORT PICKUP RULES:</h3>
          <div className="p-2 content-padding-left">
            <ul>
              <li className="p-2 text-justify">
                We Allow 60 Minutes Free Waiting Time From The Requested Pickup
                Time, At The Cruise and Ferry Ports.
              </li>
              <li className="p-2 text-justify">
                Additional Time Chargeable At £6.00 For Every 15 Minutes.
              </li>
            </ul>
          </div>
          <br />
          <h3>EUROSTAR AND STATION PICKUP RULES:</h3>
          <div className="p-2 content-padding-left">
            <ul>
              <li className="p-2 text-justify">
                Eurostar/Domestic Arrivals: There Will Be 30 Mins FREE Waiting
                Time After requested Pick Up Time. We Will Monitor Train Arrival
                Time and If There Is Any Late/Early Arrival it Will Change
                According To the Requested Pick Up Time.
              </li>
              <li className="p-2 text-justify">
                If You Realise That You Will Not Be Able To Meet the Driver
                Within 30 Minutes, Then If You Contact Us, Our Driver Will
                Continue Waiting But There Will Be Additional Charge Of £6.00
                For Every 15 Minutes. It Will Be Payable Before The Journey
                Begins.
              </li>
              <li className="p-2 text-justify">
                If You Are Still Not Out Or Contacted with us Within the
                requested Pick Up Time Then The Driver Will Be Pulled Off and
                The Job Will Be Registered As a No Show.
              </li>
            </ul>
          </div>
          <br /> */}
          <h3>GENERAL TERMS</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={31} className="p-2">
                We Cannot Be Held Responsible For Any Late Arrival To
                Destination, Whatever reason.
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
                i.e traffic delays , accidents, breakdown ,severe weather
                conditions or any unforeseen circumstances. Hence We Will Not
                Accept Any Responsibility For Missed Fights Or Ships.
              </li>
              <li className="p-2 text-justify">
                We advise passengers to plan to arrive at least 2 hours prior to
                flight departure to allow for possible unpredicted delays en
                route or from the airport.
              </li>
              <li className="p-2 text-justify">
                You are free of course to arrange to get to the airport for a
                time less then 2 hours prior to flight departure. However,
                Ridewing accepts no responsibility for any missed flight due to
                this.
              </li>
              <li className="p-2 text-justify">
                Ridewing does not accept any responsibility in any way if the
                passenger/luggage requirement exceed the capacity of the vehicle
                booked.
              </li>
              <li className="p-2 text-justify">
                if you are unsure about the capacity of the vehicle booked
                please contact airport transfer customer service team
                immediately.
              </li>
              <li className="p-2 text-justify">
                We Shall Be Under No Liability To the Customer Whatsoever For
                Any Indirect Loss and / Or Expense (Including Loss Of Profit)
                Suffered By the Customer arising out of a breach by the Company
                of these terms and conditions.
              </li>
              <li className="p-2 text-justify">
                In the event of any claim against Ridewing arising out of its
                performance of hire, the Company's liability shall be limited to
                a refund not exceeding the cost of the journey. Any other
                payments will be entirely at the discretion of the Company.
              </li>
              <li className="p-2 text-justify">
                We will not accept late bookings online (within 4 hours of
                transfer time)
              </li>
              <li className="p-2 text-justify">
                We Accept No Responsibility For Any Loss Or Damage To Property,
                Howsoever Such Loss Or Damage May Be Caused. In the Event Of
                Property Being Left In Vehicle, We Will Gladly Organise The
                Return Of Such Items If You Cover the Postage and Packaging
                Costs.
              </li>
              <li className="p-2 text-justify">
                All Our Vehicles Are Non-Smoking.
              </li>
              <li className="p-2 text-justify">
                We Will Charge a Vehicle Valeting Fee Of £60.00 For Any Damage
                Of Their Vehicle Made By Customer (e.g. Vomiting, Spilling
                Liquid Or Food To the Vehicle).
              </li>
              <li className="p-2 text-justify">
                We Reserve the Right To Refuse To Transport Any Passenger Who
                Behaves In a Disorderly, Threatening Or Abusive Manner.
              </li>
              <li className="p-2 text-justify">
                Please ensure your details are correct. Ridewing does not accept
                any responsibility for being given wrong details. please make
                sure your journey details email sent by us.
              </li>
              <li className="p-2 text-justify">
                If your flight has a serious delays please contact us as soon as
                possible.
              </li>
              <li className="p-2 text-justify">
                Ridewing used their own transport wherever possible but do use 3
                party companies where appropriate.
              </li>
              <li className="p-2 text-justify">
                Ridewing reserves right to provide an upgraded car type from the
                original selected if your chosen vehicle is unavailable.
              </li>
              <li className="p-2 text-justify">
                We Reserve The Right To Choose the Route. There Is Additional
                Charge Applicable If Customer Wants To Take a Different Route
                Than the Company Route . Extra Charge Will Be £2.00 Per Mile.
              </li>
              <li className="p-2 text-justify">
                Ridewing reserves the right to refuse carriages of animals that
                were not agreed at the point of booking. All animals must be
                secured in a suitable transport box/crate .Ridewing accept no
                responsibility for cost incurred from a failure to abide by
                these terms. We Apply a Flat £20.00 Vehicle Valeting Charge For
                Such Bookings. Pet Must Travel Within a Safe Cage Or Secure
                Safety Leash/Harness While In the Vehicle.
              </li>
            </ul>
          </div>
          <br />
          <h3>FREE CHILD SEAT</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={32} className="p-2 text-justify">
                We Will Provide Child Seats Free Of Charge, For Peace Of Mind
                Guarantee, As By Law London Private Hire Vehicles Are Exempt
                From This Law. If the Driver Doesn't Provide The Correct Child
                Car Seat, Children Can Travel Without One But Only If They
                Travel On a Rear Seat: and Wear An Adult Seat Belt If They’re 3
                Or Older Without a seat belt if they’re under 3.
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
                Please note that we provide child seats as a courtesy service.
                Whilst we make every effort to ensure child seats are available,
                we cannot guarantee, suitability for your child, or availability
                for your journey. Usage of child seats is entirely at the
                passenger's discretion, and we cannot be held responsible or
                liable for their usage
              </li>
            </ul>
          </div>
          <br />
          <h3>BOOKING CANCELLATION CHARGES</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={33} className="p-2 text-justify">
                Cancellation Notice Periods and Fees Are AS Follows:
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
                FREE Cancellation If, Cancellation Made More Than 24 Hours
                Before The Transfer Time.
              </li>
              <li className="p-2 text-justify">
                We Will Charge 10% of Administration Fee For The Refund As This
                Processing (Interchange) Fee, Charged By Merchant Account.
              </li>
              <li className="p-2 text-justify">
                50% Cancellation Charge If Cancellation Made between 12 hours
                and 24 Hours Before Pick Up Time.
              </li>
              <li className="p-2 text-justify">
                100% Cancellation Charge If Cancellation Made Within 12 Hours Of
                Pick Up Time.
              </li>
              <li className="p-2 text-justify">
                REFUNDS Could Take Up To 15 Working Days To Process.
              </li>
              <li className="p-2 text-justify">
                <i>
                  IF YOU DO NOT RECEIVE AN EMAIL FROM US CONFIRMING THE
                  CANCELLATION ,THEN WE HAVE NOT AWARE OF THE CANCELLATION ,in
                  this case please give us a call on our out of hours number
                  which is <a href="tel:07884488717">07884488717</a> as soon as
                  possible.
                </i>
              </li>
            </ul>
          </div>
          <h3>BOOKING CANCELLATION CHARGES</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={34} className="p-2 text-justify">
                Unexpected cancelled Flights due to weather conditions
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
                If the passenger flight been cancelled due to any unexpected
                reason this information must be informed to us immediately so
                that we can reschedule your journey at later date or time. In
                this case If the journey was pre paid and customer request for
                refund it will be refunded after deducting 10% for bank charges
                and admin fee. However if the passenger wishes to take a later
                flight we will provide the service at no extra cost. This is
                only applicable for arrivals.
              </li>
            </ul>
          </div>
          <br />
          <h3>NO-SHOW CHARGES</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={35} className="p-2 text-justify">
                If Customer Does Not Show Up For a Ride, We will Apply the
                Booking As No-Show and Will Charge the Full Amount of booking.
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
                Waiting Times and Pickup Rules Are Listed Above Of These Terms
                and Conditions.
              </li>
              <li className="p-2 text-justify">
                Fares inclusive all taxes and toll & congestion charge & meet
                and greet & car parking
              </li>
              <li className="p-2 text-justify">
                Any amendment must be made via an email or by telephone to us to
                which you will receive an email confirming the amendment.
              </li>
              <li className="p-2 text-justify">
                amendments must not be made with your driver.
              </li>
              <li className="p-2 text-justify">
                All bookings must be made through our website or telephone or
                email. In this way confirmations are sent out and the journey is
                insured.
              </li>
              <li className="p-2 text-justify">
                Any complaints regarding service should be raised in writing to
                our office, preferably by reply to your confirmation email .All
                complaints must be submitted within 30 days of the event giving
                rise to the event.
              </li>
            </ul>
          </div>
          <h3>Outsourcing</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={36} className="p-2 text-justify">
                We at S S carriages limited. will endeavour to carry out all
                bookings with our own contracted drivers. However, in
                exceptional circumstances where our contracted drivers are not
                available, we will outsource your booking to another reputable
                service provider. Rest assured that any alternative service
                providers used will also be licensed and regulated by the Public
                Carriage Office, who form part of Transport for London.
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
                Regardless of whether your booking is carried out by our
                contracted drivers or outsourced,S S carriages limited. will
                still be responsible for said booking. As a company, we will
                ensure that renumeration for outsourcing to a third party is
                taken care of and that your journey is completed in a
                satisfactory manner.
              </li>
            </ul>
          </div>
          <h3>Hourly booking</h3>
          <div className="p-2 content-padding-left">
            <ol className="list-decimal list-outside">
              <li value={37} className="p-2 text-justify">
              Your ride includes 20 km of travel per hour booked. Extra distance or time will result in extra charges. Free cancellation before 24 hours.
              </li>
            </ol>
            <ul>
              <li className="p-2 text-justify">
              By-the-hour bookings must end in the same city or metropolitan area as the pickup location, or an extra vehicle-return charge will apply. If you plan to go from one city to another, please book a one-way ride.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
