import { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { components } from "react-select";

const Places = ({
  onChange,
  meta,
  placeholder,
  close,
  onClose = () => { },
  value,
}) => {
  const [data, setData] = useState(value);

  // useEffect(() => {
  //   if (value) {
  //     console.log("value", value.label);
  //     // setData(value.label);
  //     console.log(data);
  //     // setData(data ? { label: data?.label, value: data?.value } : "");
  //   }
  // }, []);
  return (
    <GooglePlacesAutocomplete
      apiKey={"AIzaSyAjPMVvOxBIVCOljjzLx5O5dfRDy_9J7Sg"}
      autocompletionRequest={{
        componentRestrictions: {
          country: ["GB"], //to set the specific country
        },
      }}
      selectProps={{
        value: data,
        onChange: (data) => {
          setData(data ? { label: data?.label, value: data?.value } : "");
          onChange(data);
        }, //save the value gotten from google
        placeholder: placeholder,
        styles: {
          input: (provided) => ({
            ...provided,
            color: "white",
          }),
          control: (provided) => ({
            ...provided,
            color: "white",
            background: "transparent",
            border: `1px solid ${meta.error ? "#f56a58" : "white"}`,
            boxShadow: "none",
            "&:hover": {
              border: "1px solid white",
              cursor: "pointer",
            },
          }),
          option: (provided) => ({
            ...provided,
            color: "white",
            background: "#2f3941",
            cursor: "pointer",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "white",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "white",
          }),
        },
        components: {
          SingleValue: ({ children, ...props }) => {
            return (
              <components.SingleValue {...props}>
                <i className="self-center pr-2 ri-map-pin-line" />
                {children}
              </components.SingleValue>
            );
          },
          Input: ({ children, ...props }) => {
            return (
              <components.Input {...props} className="px-5 ">
                {children}
              </components.Input>
            );
          },
          Placeholder: ({ children, ...props }) => {
            return (
              <components.Placeholder {...props}>
                <i className="self-center pr-2 ri-map-pin-line " />
                {children}
              </components.Placeholder>
            );
          },
          DropdownIndicator: ({ hasValue, clearValue }) => {
            if (hasValue) {
              return (
                <i
                  className="self-center pr-2 ri-close-line"
                  onClick={() => {
                    clearValue();
                    onClose();
                  }}
                />
              );
            } else {
              return null;
            }
          },
          IndicatorSeparator: () => null,
        },
      }}
      onLoadFailed={(error) => {
        console.debug("api error", error);
      }}
    />
  );
};

export default Places;
