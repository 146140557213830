import React from 'react'
import Navbar from '../../common/Navbar'
import ModifyBooking from './ModifyBooking'
import Footer from '../../common/Footer'
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import ServicesApi from '../../api/passengerDetails'
import Upcoming from './Upcoming'
import { Button } from "@bigbinary/neetoui";
import SearchBooking from './SearchBooking'

const ModifyBookingPage = () => {
  const history = useHistory()
  const [bookingList, setBookingList] = useState([]);
  const [showBookingModify, setShowBookingModify] = useState(false);
  const [showForm, setShowForm] = useState(true)
  const [modifyTripDetails, setModifyTripDetails] = useState({
    booking_id: "",
    mail: "",
    name: "",
    phone: ""
  });
  
  return (
    <div className='bg-black pt-[30px]'>
      <Navbar />
      <div className="pt-[90px] md:pt-0 min-h-[80vh]">
        {/* <Button icon="ri-arrow-left-circle-line pl-2 pt-2 text-4xl" style="icon" onClick={() => setShowBookingModify(false)} /> */}
        {showForm ?
          <SearchBooking setShowForm={setShowForm} setBookingList={setBookingList}/>
          :
          <>
            {showBookingModify ?
              <div className="md:mt-0 mt-[80px]">
                <Button icon="ri-arrow-left-circle-line pl-2 pt-2 text-4xl" style="icon" onClick={() => setShowBookingModify(false)} />
                <ModifyBooking modifyTripDetails={modifyTripDetails} />
              </div>
              :
              <Upcoming bookingList={bookingList} setShowBookingModify={setShowBookingModify} setModifyTripDetails={setModifyTripDetails} />
            }
          </>
        }
      </div>
      <Footer />
    </div >
  )
}

export default ModifyBookingPage