import * as dayjs from "dayjs";
import * as moment from "moment";

export const dateFormater = (value) => {
  const transformedDate = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
  return transformedDate;
};

export const dateTimeCombine = (date, time) => {
  let calcluatedTime = moment(
    dayjs(date).format("YYYY-MM-DD") + dayjs(time).format("HH:mm:ss"),
    "YYYY-MM-DD HH:mm:ss"
  )?.format();
  return calcluatedTime;
};

export const PayloadTransform = (data) => {
  // console.log("data", data);
  const payload = {
    status: data?.vechileInfo?.vehicle_cat_dt?.is_quote ? 10 : 7,
    pick_up_loc: data?.journeyInfo?.source?.label,
    drop_off_loc: data?.journeyInfo?.destination?.label || 'No',
    pickup_date_time: dateFormater(
      dateTimeCombine(data?.journeyInfo?.date, data?.journeyInfo?.time)
    ),
    dateofbooking: dateFormater(new Date()),
    cust_name: data?.passengerInfo?.passengerName,
    cust_phone_ext: "+" + data?.passengerInfo?.phoneNumber?.id?.value[0],
    cust_phone: data?.passengerInfo?.phoneNumber?.number,
    cust_email: data?.passengerInfo?.passengerEmail,
    note: data?.passengerInfo?.notes,
    payment_type: "card",
    //is_quote fare is 0
    fare: data?.vechileInfo?.vehicle_cat_dt?.is_quote
      ? 0
      : data?.journeyInfo?.returnBooking
        ? data?.vechileInfo?.fare / 2
        : data?.vechileInfo?.fare,
    v_cat: {
      _id: data?.vechileInfo?.vehicle_cat_dt?._id,
    },
    user: {
      _id: data?.userInfo._id ? data?.userInfo._id : null,
    },
    passenger_no: data?.passengerInfo?.passengerCount?.value,
    luggage_no: data?.passengerInfo?.luggageCount?.value,

    child_seat_no: data?.passengerInfo?.childCount?.label,

    distance: data?.distance || 1,
    duration: data?.duration || 1,
    is_return: data?.journeyInfo?.returnBooking ? "1" : "0",
    is_source_airpot: data?.journeyInfo?.isAirport,
  };
  if (data.formType == 'hourly') {
    payload.is_hrly = true
  }
  if (data?.flightInfo?.pickupText) {
    payload.buffer_time = {
      _id: data?.flightInfo?.pickupText?.id,
    };
    payload.flight_no = data?.flightInfo?.flightNumber;
    payload.flight_date_time = dateFormater(
      dateTimeCombine(data?.journeyInfo?.date, data?.flightInfo?.arrivalTime)
    );
  }

  if (data?.journeyInfo?.returnBooking) {
    payload.return_pickup_date_time = dateFormater(
      dateTimeCombine(
        data?.journeyInfo?.returnDate,
        data?.journeyInfo?.returnTime
      )
    );

    payload.return_fare = data?.journeyInfo?.returnBooking
      ? data?.vechileInfo?.fare / 2
      : 0;
  }

  data?.journeyInfo?.stops?.map((item, index) => {
    payload[`extra_stop${index + 1}`] = item.label;
  });
  data?.passengerInfo?.childWeight?.map((item, index) => {
    payload[`child_seat_type${index + 1}`] = { _id: item?.id };
  });

  console.debug("payload", payload);

  return payload;
};
