import BookingRow from "./BookingItem";

const Cancel = (props) => {
  return (
    <div className="p-2 px-4">
      {/* <div className="mt-6 mb-4">Today, 1:20 </div> */}
      {/* <section className="booking-item-list"> */}
      <section className="">
        <div className="hidden md:block">
          <div className="grid grid-cols-8  text-black font-semibold mt-[25px]">
            <div className="col-span-1 ">
              <div className="h-[50px] bg-white flex items-center pl-[20px] text-[16px] ">
                Date
              </div>
              <div className="h-[69px] bg-[#7E7C81] flex pl-[20px] text-white text-[12px] flex-col pt-2">
                <span>Trip Date</span>
                <span>Pickup Time</span>
              </div>
            </div>
            <div className="col-span-1 ">
              <div className="h-[50px] bg-white flex items-center  text-[16px] ">
                Booking
              </div>
              <div className="h-[69px] bg-[#7E7C81] flex text-white text-[12px] flex-col pt-2">
                <span>Booking Id</span>
                <span>Vehicle Type</span>
              </div>
            </div>
            <div className="col-span-2 ">
              <div className="h-[50px] bg-white flex items-center  text-[16px] ">
                Journey Details
              </div>
              <div className="h-[69px] bg-[#7E7C81] flex text-white text-[12px] flex-col pt-2">
                <span>Pickup Location</span>
                <span>Dropoff Location</span>
                <span>Miles & Waiting Time</span>
              </div>
            </div>
            <div className="col-span-1 ">
              <div className="h-[50px] bg-white flex items-center  text-[16px] ">
                Cost
              </div>
              <div className="h-[69px] bg-[#7E7C81] flex text-white text-[12px] flex-col pt-2">
                <span>Actual</span>
                <span>Extras</span>
              </div>
            </div>
            <div className="col-span-2 ">
              <div className="h-[50px] bg-white flex items-center  text-[16px] ">
                Customer Details
              </div>
              <div className="h-[69px] bg-[#7E7C81] flex text-white text-[12px] flex-col pt-2">
                <span>Passenger Details</span>
                <span>Flight Details</span>
                <span>Comments</span>
              </div>
            </div>
            <div className="col-span-1 ">
              <div className="h-[50px] bg-white flex items-center  text-[16px] ">
                Status
              </div>
              <div className="h-[69px] bg-[#7E7C81] flex text-white text-[12px] flex-col pt-2">
                <span></span>
              </div>
            </div>
          </div>
        </div>
        {Object.values(props).map((item, id) => (
          <BookingRow {...item} id={id}/>
        ))}
      </section>
    </div>
  );
};

export default Cancel;
