import PassagerForm from "./Form";
import { useUserDispatch, useUserState } from "../../../Context";
import { useRef, useState } from "react";
import Map from "../Map";
import ServicesApi from "../../../api/passengerDetails";
import { PayloadTransform } from "../../../Utils/payloadTransform";
import { useHistory } from "react-router-dom";

const PassagerDetails = ({ onTabChange, modify = true, setWarning, setBookingDetails, bookingDetails }) => {
  const history = useHistory();
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  const formRef = useRef();
  const userFullData = useUserState();
  const [userId, setUserId] = useState(userState.userInfo._id || null);
  const { vechileInfo } = userFullData;
  let passengerCount = vechileInfo?.vehicle_cat_dt?.passenger_no;

  let luggageeCount = vechileInfo?.vehicle_cat_dt?.luggage_no;

  let passengerList = [];
  let pcount = 0;
  while (pcount < passengerCount) {
    passengerList.push({ label: pcount + 1, value: pcount + 1 });
    pcount++;
  }

  let luggageList = [];
  let lcount = 0;
  while (lcount < luggageeCount) {
    luggageList.push({ label: lcount + 1, value: lcount + 1 });
    lcount++;
  }

  const onSubmit = async (data) => {
    userDispatch({ type: "PASSENGERDETAILS", payload: data });
    // console.log(userFullData);
    data.userId = userId;
    let payload = PayloadTransform({ ...userFullData, passengerInfo: data });

    if (payload.status === 7) {
      // setWarning(true)
      let res;
      if (userFullData.formType === 'hourly') {
        res = await ServicesApi.createHourlyBooking(payload);
        // console.log("book", res.booking_number);
        ServicesApi.Payment(res.booking_number, res.fare)   
        setBookingDetails({
          ...bookingDetails,
          bookingNumber: res.booking_number,
          fare: res.fare
        });
      } else {
        res = await ServicesApi.createBooking(payload);
        // console.log(res);
        ServicesApi.Payment(res[0].booking_number, res[0].fare);
        setBookingDetails({
          ...bookingDetails,
          bookingNumber: res[0].booking_number,
          fare: res[0].fare
        });
      }
      // console.log(res[0].booking_number);
      // console.log(res[0].fare);
    } else {
      await ServicesApi.createQuote(payload);
      history.push("/quotesuccessful");
    }

  };

  return (
    <div className="inner-page-tab-content">
      <div className="py-6 md:pt-12">
        <PassagerForm
          modify={modify}
          onSubmit={onSubmit}
          formRef={formRef}
          passengerList={passengerList}
          luggageList={luggageList}
        />
      </div>
      {/* {!modify && ( */}
      <div className="lg:pt-12">
        <Map onTabChange={onTabChange} page="passenger" formRef={formRef} />
      </div>
      {/* )} */}
    </div>
  );
};

export default PassagerDetails;
