import React, { useEffect, useMemo, useState } from "react";
import { Select, Textarea, Label, Radio } from "@bigbinary/neetoui";
import { Input as FormikInput } from "@bigbinary/neetoui/formik";
import { Field, Form, Formik } from "formik";
import { PASSENGER_SCHEMA } from "../../../../Utils/formikValidation";
import ServicesApi from "../../../../api/passengerDetails";
import codes from "country-calling-code";

const colourStyles = {
  indicatorsContainer: (base, state) => ({
    background: "#393e46",
  }),
  control: (base, state) => ({
    ...base,
    background: "#393e46",
  }),
  singleValue: (provided) => ({
    ...provided,

    color: "white",
  }),
  // option: (styles, state) =>
  //   state.isFocused || {
  //     ...styles,
  //   },
};

// const colourStyles = {
//   indicatorsContainer: (base, state) => ({
//     background: "#393e46",
//     textColor: "#FFFFFF",
//   }),
//   control: (base, state) => ({
//     ...base,
//     background: "#393e46",
//     textColor: "#FFFFFF",
//   }),
//   placeholder: (base, state) => ({
//     ...base,
//     background: "#393e46",
//     textColor: "#FFFFFF",
//   }),
//   singleValue: (base, state) => ({
//     ...base,
//     background: "#393e46",
//     textColor: "#FFFFFF !important",
//   }),
//   valueContainer: (base, state) => ({
//     ...base,
//     background: "#393e46",
//     textColor: "#FFFFFF",
//   }),
// };

const PassagerForm = ({
  modify,
  formRef,
  onSubmit,
  passengerList,
  luggageList,
}) => {
  const [childCountState, setchildCountState] = useState([]);

  const fetchChildCount = async () => {
    try {
      const ChildList = await ServicesApi.ChildCountApi();

      const transformedList = ChildList.map((item) => {
        return {
          label: item.child_seat_type,
          value: item._id,
          id: item._id,
        };
      });
      setchildCountState(transformedList);
    } catch (error) {
      console.debug("api error", error);
    }
  };

  useEffect(() => {
    fetchChildCount();
  }, []);

  const CHILD_COUNT = [
    {
      label: 0,
      value: [],
    },
    {
      label: 1,
      value: [childCountState[0]],
    },
    {
      label: 2,
      value: [childCountState[0], childCountState[0]],
    },
    {
      label: 3,
      value: [childCountState[0], childCountState[0], childCountState[0]],
    },
  ];

  const options = useMemo(
    () =>
      codes.map((item) => {
        return { label: "+" + item.countryCodes, value: item.countryCodes };
      }),
    []
  );

  const intialState = {
    passengerName: "",
    passengerEmail: "",
    phoneNumber: { id: "", number: "" },
    passengerCount: "",
    luggageCount: "",
    childCount: "",
    childWeight: [],
    isSomeOneElse: false,
    someoneElseEmail: "",
    someoneElsePhone: { id: "", number: "" },
    isVat: false,
    vatNumber: "",
    vatAddress: "",
    notes: "",
  };

  return (
    <div className="w-full px-4 pt-4 pb-8 border-b border-gray-600 md:border-0">
      <Formik
        initialValues={intialState}
        onSubmit={onSubmit}
        innerRef={formRef}
        validationSchema={PASSENGER_SCHEMA}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="flex flex-col justify-center space-y-10">
                <div className="flex flex-col justify-between md:space-x-8 md:flex-row">
                  <FormikInput
                    name="passengerName"
                    label="Passenger Name"
                    required
                    className="py-4 md:w-1/2"
                  />
                  <FormikInput
                    name="passengerEmail"
                    label="Passenger Email"
                    required
                    className="py-4 md:w-1/2"
                  />
                </div>
                <div className=" md:space-x-8 md:flex">
                  <div className="py-4 space-y-1 md:w-1/2">
                    <Label>Passenger Phone Number*</Label>

                    <Field name="phoneNumber">
                      {({ meta }) => (
                        <div className="flex space-x-2">
                          <Select
                            name="phoneNumber.id"
                            required
                            styles={colourStyles}
                            options={options}
                            className="w-full"
                            size="small"
                            maxlength="3"
                          />
                          <FormikInput
                            name="phoneNumber.number"
                            required
                            maxlength="10"
                            className="w-full"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex flex-wrap justify-between">
                  <Field name="passengerCount">
                    {({ field, form: { touched, errors }, meta }) => (
                      <Select
                        className="w-full pt-4 md:pb-4 md:w-10 md:pt-0 "
                        {...field}
                        label="Passengers"
                        required
                        name="passengerCount"
                        styles={colourStyles}
                        size="small"
                        options={passengerList}
                        onChange={(value) => {
                          setFieldValue("passengerCount", value);
                        }}
                      />
                    )}
                  </Field>

                  <Field name="luggageCount">
                    {({ field, form: { touched, errors }, meta }) => (
                      <Select
                        className="w-full pt-4 md:pb-4 md:w-10 md:pt-0 md:px-2"
                        {...field}
                        label="Baggage"
                        name="luggageCount"
                        required
                        styles={colourStyles}
                        size="small"
                        options={luggageList}
                        onChange={(value) => {
                          setFieldValue("luggageCount", value);
                        }}
                      />
                    )}
                  </Field>
                  <Field name="childCount">
                    {({ field, form: { touched, errors }, meta }) => (
                      <Select
                        className="w-full pt-4 md:pb-4 md:w-10 md:pt-0 "
                        {...field}
                        label="Child Seat"
                        name="childCount"
                        styles={colourStyles}
                        size="small"
                        options={CHILD_COUNT}
                        onChange={(value) => {
                          setFieldValue("childCount", value);
                          setFieldValue("childWeight", value.value);
                        }}
                        required
                        suffix={<i className="ri-user-4-fill"></i>}
                      />
                    )}
                  </Field>
                </div>
                {values.childCount > 0 && (
                  <div className="md:w-1/2">
                    <Field name="childWeight">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div>
                          <Select
                            // styles={  colourStyles}
                            size="small"
                            options={[
                              {
                                label: "Child Weight (1)  0-36 Kg",
                                value: "1",
                              },
                            ]}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                )}

                <div className="space-y-3">
                  <div>
                    <Field name="isSomeOneElse">
                      {({ field, form: { touched, errors }, meta }) => (
                        <div>
                          <Radio label="Are you booking for someone else?">
                            <Radio.Item
                              name="isSomeOneElse"
                              label="Yes"
                              checked={values.isSomeOneElse}
                              onChange={() => {
                                setFieldValue("isSomeOneElse", true);
                              }}
                              {...field}
                            />
                            <Radio.Item
                              name="isSomeOneElse"
                              label="No"
                              checked={!values.isSomeOneElse}
                              {...field}
                              onChange={() => {
                                setFieldValue("isSomeOneElse", false);
                              }}
                            />
                          </Radio>
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  {values.isSomeOneElse && (
                    <div className="md:flex md:space-x-4">
                      <FormikInput
                        name="someoneElseEmail"
                        label="Email"
                        required
                        className="w-full md:w-1/2"
                      />
                      <div className="pt-2 space-y-1 md:pt-0">
                        <Label required>Phone Number</Label>

                        <div className="flex space-x-2">
                          <FormikInput
                            name="someoneElsePhone.id"
                            required
                            className="w-full md:w-24"
                          />
                          <FormikInput
                            name="someoneElsePhone.number"
                            required
                            className="w-full "
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="space-y-3">
                  <div>
                    <Field name="isVat">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div>
                          <Radio label="Do you need a VAT invoice?">
                            <Radio.Item
                              label="Yes"
                              checked={values.isVat}
                              value="yes"
                              onChange={() => {
                                setFieldValue("isVat", true);
                              }}
                            />
                            <Radio.Item
                              label="No"
                              checked={!values.isVat}
                              value="no"
                              onChange={() => {
                                setFieldValue("isVat", false);
                              }}
                            />
                          </Radio>
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  {values.isVat && (
                    <div className="flex space-x-2">
                      <FormikInput
                        name="vatNumber"
                        label="Vat number"
                        required
                        className="w-full"
                      />
                      <FormikInput
                        name="vatAddress"
                        label="Vat Address"
                        required
                        className="w-full"
                      />
                    </div>
                  )}
                </div>

                <Field name="notes">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: meta,
                  }) => (
                    <div>
                      <Textarea {...field} label="Notes to the Driver" />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PassagerForm;
