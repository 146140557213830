import React, { useEffect, useState } from 'react'
import logo from '../../assets/a2a.svg'
import ServicesApi from "../../api/passengerDetails";
import { Button } from "@bigbinary/neetoui";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { useUserDispatch, useUserState } from "../../Context";
import { DatePicker, TimePicker } from 'baseui/datepicker';
import enGB from 'date-fns/locale/en-GB';
import {
  ThemeProvider,
  createTheme,
  darkThemePrimitives
} from "baseui";

import close from '../../assets/close.png'
import arrow from '../../assets/bothArrow.svg'

const Airport2Airport = ({ data }) => {
  const [selectedAirport, setSelectedAirport] = useState({})
  const [switchFromTo, setSwitchFromTo] = useState(false)
  const [showAirportForm, setShowAirportForm] = useState(false)
  const history = useHistory()
  const userDispatch = useUserDispatch();
  const { journeyInfo } = useUserState();
  const userState = useUserState().journeyInfo;
  const [pickupDate, setPickupDate] = useState(
    userState.date ? userState.date : new Date()
  );
  const [pickupTime, setPickupTime] = useState(
    userState.time ? userState.time : new Date()
  );

  const getAirportDetails = async (id) => {
    let airport = await ServicesApi.Airport2Airport(id);
    // console.log("airport2airport list", airport);
    userDispatch({ type: "VEHICLELIST", payload: airport });
    setShowAirportForm(true)
  }

  const showVehicles = () => {
    let payload = {
      source: {
        label: switchFromTo ? data.to_airport : data.from_airport,
      },
      destination: {
        label: switchFromTo ? data.from_airport : data.to_airport,
      },
      time: pickupDate,
      date: pickupDate,
    }
    userDispatch({ type: "FORMTYPE", payload: 'airport2airport' })
    userDispatch({ type: "JOURNEYDETAILS", payload });

    // console.log("after updating", journeyInfo);
    history.push("/tabs/vehicle");
  }

  // useEffect(() => {
  //   console.log(data);
  // })
  return (
    <>
      <div className='h-[250px] md:h-[372px] w-[100%] max-w-[292px] bg-[#717171A3] rounded-[30px] md:rounded-[55px] flex flex-col items-center justify-evenly py-[20px] mx-auto'>
        <img src={logo} alt="" className='w-[70px] md:w-[115px]' />
        <div className='flex flex-col justify-center items-center text-center'>
          <p className='text-[20px] md:text-[30px] leading-[28px]'>{data.from_airport}</p>
          <p className='text-[22px] md:text-[34px] leading-[18px] md:leading-[22px]'>-</p>
          <p className='text-[20px] md:text-[30px] leading-[28px]'>{data.to_airport}</p>
        </div>
        <div
          className='w-[100%] overflow-hidden rounded-[30px] flex hover:cursor-pointer font-[600]'
          onClick={() => getAirportDetails(data._id)}
        >
          <div className='w-[50%] h-[35px] md:h-[58px] bg-[#7952B3] flex justify-center items-center text-[16px] md:text-[24px]'>£{data.lowestFare}</div>
          <div className='w-[50%] h-[35px] md:h-[58px] bg-[#FFD369] flex justify-center items-center text-[black] text-[12px] md:text-[20px]'>Book Now</div>
        </div>
        <span className="px-[10px] text-[9px] md:text-[16px] ">*The price shown is for economy</span>
      </div>
      {
        showAirportForm &&

        <div
          className="fixed top-0 left-0 bg-black h-[100vh] w-[100vw] z-[8] flex flex-col items-center justify-center"
        >
          <div className='bg-[#6D6C6AA3] min-h-[363px] w-[90%] max-w-[400px] rounded-[15px] pb-[20px]'>
            <div className='w-full flex justify-end p-[15px] '>
              <img src={close} alt="" onClick={() => setShowAirportForm(false)} className='h-[20px] hover:cursor-pointer' />
            </div>
            <div className='flex flex-col mx-3 px-[15px] gap-5 mt-[15px]'>
              <span className='w-full border-white border-[1px] p-[10px] rounded-[2px]'>{switchFromTo ? data.to_airport : data.from_airport}</span>
              <img src={arrow} alt="" className='h-[20px] hover:cursor-pointer' onClick={() => {
                setSwitchFromTo(!switchFromTo)
              }} />
              <span className='w-full border-white border-[1px] p-[10px] rounded-[2px]'>{switchFromTo ? data.from_airport : data.to_airport}</span>
              <ThemeProvider
                theme={createTheme(
                  darkThemePrimitives,
                  {
                    colors: {
                      calendarHeaderForeground: "#000000",
                      calendarDayBackgroundSelectedHighlighted: "#000000",
                      inputFillDisabled: "#FFFFFF",
                      inputFill: "#00000",
                      inputBorder: "#FFFFFF"
                    }
                  }
                )}
              >
                <div className="w-full flex justify-between space-x-6 datePickerWrap ">
                  <DatePicker
                    className="datePicker"
                    formatString="d/MM/yy"
                    placeholder="D/MM/YY"
                    locale={enGB}
                    value={pickupDate}
                    onChange={({ date }) => setPickupDate(date)}
                    minDate={new Date()}
                    clearable
                    overrides={{
                      InputWrapper: {
                        style: () => ({
                          backgroundColor: "transparent",
                          color: "white",
                          // border: "1px solid #c2c8cc",
                          borderRadius: "2px",
                        }),
                      }
                    }}
                  />
                  <TimePicker
                    value={pickupTime}
                    onChange={(time) => setPickupTime(time)}
                    placeholder="pickup time"
                    step={300}
                    format="24"
                    // minTime={new Date()}
                    overrides={{
                      InputWrapper: {
                        style: () => ({
                          backgroundColor: "transparent",
                          color: "white",
                        }),
                      },
                    }}
                  />
                </div>
              </ThemeProvider>

              <Button
                label="Show available services"
                fullWidth
                onClick={() => showVehicles()}
                className="neeto-ui-btn neeto-ui-btn_md"
              />
            </div>

          </div>
        </div>
      }
    </>
  )
}

export default Airport2Airport