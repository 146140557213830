// import { vehicleList } from "../common/ApiConstants";

const UserReducer = (state, { type, payload }) => {
  // console.log("Type & Payload",type, payload);
  switch (type) {
    case 'FORMTYPE':
      return {
        ...state,
        formType: payload,
      }
    case "BUSONLY":
      return {
        ...state,
        busOnly: payload,
      };
    case "SIGNIN": {
      return {
        ...state,
        isLoggedIn: payload.isLoggedIn,
        userInfo: payload.userInfo,
      };
    }
    case "NOTIFICATION": {
      return {
        ...state,
        showNotification: payload.showNotification,
        notificationProperties: {
          showNotification: payload.showNotification,
          kind: payload.kind,
          message: payload.notificationMessage,
        },
      };
    }
    case "JOURNEYDETAILS": {
      return {
        ...state,
        journeyInfo: payload,
      };
    }
    case "VEHCILEDETAILS": {
      return {
        ...state,
        vechileInfo: payload,
      };
    }
    case "FLIGHTDETAILS": {
      return {
        ...state,
        flightInfo: payload,
        flightSkip: false,
      };
    }
    case "PASSENGERDETAILS": {
      return {
        ...state,
        passengerInfo: payload,
      };
    }
    case "PAYMENTDETAILS": {
      return {
        ...state,
        paymentInfo: payload,
      };
    }
    case "VEHICLELIST": {
      return {
        ...state,
        vehicleList: payload.cal_vehicle_fair,
        distance: payload.distance,
        duration: payload.duration,
        // vehicleList: vehicleList.cal_vehicle_fair,
        // distance: vehicleList.distance,
        // duration: vehicleList.duration,
      };
    }
    case "SKIPFLIGHT": {
      return {
        ...state,
        flightSkip: true,
        flightInfo: {},
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export default UserReducer;
