import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useUserDispatch, useUserState } from "../../Context";
import ServicesApi from "../../api/passengerDetails";
import { Button, Accordion } from "@bigbinary/neetoui";
import MeetImag from "../../assets/meet.webp";
import PhoneImag from "../../assets/app.webp";
import AppleStore from "../../assets/applestore.png";
import GoogleStore from "../../assets/googlestore.png";
import Opeartes from "../../assets/operates.webp";
import Intercity from "../../assets/intercityservice.svg";
import Airport from "../../assets/airportservice.svg";
import Hourly from "../../assets/hourlyservice.svg";
import Bus from "../../assets/bus.svg"
import WorldPay from '../../assets/worldpay.png'
import Signature from '../../assets/signature.svg'
// import Airport from "../../assets/airport.webp";
import Safety from "../../assets/Safety.svg";
import Travel from "../../assets/travel.svg";
import Driver from "../../assets/Driver.svg";
import Navbar from "../../common/Navbar";
import PickupForm from "./PickUpForm";
import Footer from "../../common/Footer";
import { useHistory, Link } from "react-router-dom";
import Whatsapp from "../../assets/whatsapp.svg";
import Airport2Airport from "./Airport2Airport";
import ReadMore from "../../common/ReadMore";
import ModifyBooking from "../MyBookings/ModifyBooking";
import Testimonials from "./Testimonials";

const Home = () => {
  const [showAvailability, setShowAvailability] = useState(false);
  const [AirportToAirport, setAirportToAirport] = useState('')
  const [showBookingModify, setShowBookingModify] = useState(false)
  const [formType, setFormType] = useState('airport');
  const history = useHistory();
  const userDispatch = useUserDispatch();

  const onRegisterNow = () => {
    history.push("/agents");
  };
  // const userState = useUserState().journeyInfo
  // let payload = {
  //   ...userState,
  //   destination: "",
  //   source: "",
  //   time: "",
  //   date: "",
  // }
  // userDispatch({ type: "JOURNEYDETAILS", payload });
  useEffect(() => {
    userDispatch({ type: "BUSONLY", payload: false });
    getA2A()
  }, [])

  const getA2A = async () => {
    const data = await ServicesApi.Airport2AirportList();
    setAirportToAirport(data);
  }
  return (
    <div className="bg-black">
      <Navbar setShowBookingModify={setShowBookingModify} />
      {showBookingModify ?
        <div className="pt-[90px] md:pt-0 ">
          <Button icon="ri-arrow-left-circle-line pl-2 pt-2 text-4xl" style="icon" onClick={() => setShowBookingModify(false)} />
          <ModifyBooking />
        </div>
        :
        <main>
          {/* home-header */}
          <div className="home-header flex flex-col items-center">
            <div className="hidden lg:block">
              <div className="w-[100%] flex justify-center pt-[25px] md:pt-[50px]">
                <span className="text-[white] text-[16px] sm:text-[18px] italic">- One Stop Solution for Premium Airport Transfers -</span>
              </div>
            </div>
            <div className="pickup-form-wrap-bg w-full">
              <div className="min-h-[722px]">
                <PickupForm formType={formType} setFormType={setFormType} />
              </div>
              {/* Airport to Airport */}
              <div className="w-[100%] container-2xl mx-auto px-[5%] pb-[30px] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[10px]">
                {AirportToAirport && AirportToAirport.map((e, id) => {
                  return (
                    <Airport2Airport data={e} key={e._id} />
                  )
                })}
              </div>
            </div>
            <a
              className="whatsapp-link"
              title="Message us in WhatsApp"
              href="https://wa.me/+447500200570"
              target="_blank"
            >
              <img src={Whatsapp} alt="whatsapp logo" />
            </a>
          </div>

          {/* How it works */}
          <div className="bg-[#FFD369] w-[100%] text-black  ">
            <div className="py-[70px] px-[5%] font-semibold  flex flex-col md:flex-row justify-evenly md:justify-between items-center  container-xl mx-auto ">
              <div className="flex justify-center items-center">
                <div className="border-l-[10px] border-[#7952B3] pl-[17px] md:text-[48px] text-[36px] leading-[50px]">How It Works</div>
              </div>
              <div className="flex flex-col md:flex-row justify-center items-center md:pl-[20px] py-[30px] ">
                <div className="flex flex-col items-center md:h-[200px] mt-[20px] md:mt-0 justify-evenly">
                  <img src={Travel} className="xl:h-[72px] md:h-[48px] h-[35px]" />
                  <div className="xl:text-[36px] lg:text-[24px] text-[18px] font-[600] lg:py-[40px]">Travel Details</div>
                  <div className="text-center xl:text-[18px] lg:text-[16px] text-[12px]">
                    Fill in your travel details so that we could find the best
                    service for you
                  </div>
                </div>
                <div className="flex flex-col items-center md:h-[200px] mt-[20px] md:mt-0 justify-evenly">
                  <img src={Driver} className="xl:h-[72px] md:h-[48px] h-[35px]" />
                  <div className="xl:text-[36px] lg:text-[24px] text-[18px] font-[600] lg:py-[40px]">Get A Driver</div>
                  <div className="text-center xl:text-[18px] lg:text-[16px] text-[12px]">
                    We will assign a professional driver for you to travel
                    happily and safely.
                  </div>
                </div>
                <div className="flex flex-col items-center md:h-[200px] mt-[20px] md:mt-0 justify-evenly">
                  <img src={Safety} className="xl:h-[72px] md:h-[48px] h-[35px]" />
                  <div className="xl:text-[36px] lg:text-[24px] text-[18px] font-[600] lg:py-[40px]">Arrive Safely</div>
                  <div className="text-center xl:text-[18px] lg:text-[16px] text-[12px]">
                    We offer a safe a secure journey in a neat and spacious car
                    with a skillful driver.
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Services */}
          <div className="w-[100%] pb-[50px] font-semibold text-white bg-backgroundgrey relative flex  justify-center mx-auto">
            <div className="pt-16 container-xl h-auto px-[15px]">
              <div className="flex flex-col md:flex-row items-center justify-center w-full leading-[20px]">
                <span className="text-[30px] lg:text-[48px] py-[25px] md:py-[50px]">Ridewing Services - </span>
                <span className="text-[16px] lg:text-[22px] italic pt-[10px] text-[#FFF369] text-center">One Stop Solution for Premium Airport Transfers </span>
              </div>
              <div className="w-[100%] container-2xl mx-auto px-[5%] py-[30px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                {/* Intercity */}
                <div className="flex flex-col items-center justify-between bg-[#51545B] rounded-[30px] h-[543px] max-w-[378px] w-[100%] overflow-hidden pb-[30px] mx-auto">
                  <img src={Intercity} alt="" className="object-cover min-h-[264px]" />
                  <span className="text-[26px]">Intercity Transfers</span>
                  <p className={`text-[16px] font-[400] px-[15px]`}>Stress-free solution for traveling between cities, with chauffeurs and new, state of the art vehicles
                    <span onClick={() => { history.push('\services') }} className="text-[#FFD369] hover:cursor-pointer">
                      ...read more
                    </span>
                  </p>
                  <button
                    className="w-[80%] h-[41px] bg-[#7952B3] rounded-[10px] hover:cursor-pointer"
                    onClick={() => {
                      userDispatch({ type: "BUSONLY", payload: false });
                      setShowAvailability(true)
                      userDispatch({ type: "FORMTYPE", payload: 'intercity' })
                    }}
                  >Show Availability</button>
                </div>
                {/* Airport */}
                <div className="flex flex-col items-center justify-between bg-[#51545B] rounded-[30px] h-[543px] max-w-[378px] w-[100%] overflow-hidden pb-[30px] mx-auto">
                  <img src={Airport} alt="" className="object-cover min-h-[264px]" />
                  <span className="text-[26px]">Airport Transfers</span>

                  <p className={`text-[16px] font-[400] px-[15px]`}>Our premium airport transfer service offers the best geographic coverage across UK & London airports
                    <span onClick={() => { history.push('\services') }} className="text-[#FFD369] hover:cursor-pointer">
                      ...read more
                    </span>
                  </p>
                  <button
                    className="w-[80%] h-[41px] bg-[#7952B3] rounded-[10px] hover:cursor-pointer"
                    onClick={() => {
                      userDispatch({ type: "BUSONLY", payload: false });
                      setShowAvailability(true)
                      userDispatch({ type: "FORMTYPE", payload: 'airport' })
                    }
                    }
                  >Show Availability</button>
                </div>
                {/* Hour */}
                <div className="flex flex-col items-center justify-between bg-[#51545B] rounded-[30px] h-[543px] max-w-[378px] w-[100%] overflow-hidden pb-[30px] mx-auto">
                  <img src={Hourly} alt="" className="object-cover min-h-[264px]" />
                  <span className="text-[26px]">Reliable Hourly Service</span>
                  <p className={`text-[16px] font-[400] px-[15px]`}>Book Your Chauffeur For Up To An Entire Day, Enjoy Stress-Less Freedom And Convenience To
                    <span onClick={() => { history.push('\services') }} className="text-[#FFD369] hover:cursor-pointer">
                      ...read more
                    </span>
                  </p>
                  <button
                    className="w-[80%] h-[41px] bg-[#7952B3] rounded-[10px] hover:cursor-pointer"
                    onClick={() => {
                      userDispatch({ type: "BUSONLY", payload: false });
                      setShowAvailability(true)
                      userDispatch({ type: "FORMTYPE", payload: 'hourly' })
                    }}
                  >Show Availability</button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[100%] pb-[150px] sm:pb-[220px] font-semibold text-white bg-backgroundgrey relative flex  justify-center mx-auto">
            <div className="pt-16 container-xl h-auto px-[15px]">
              <span className="text-[30px] md:text-[48px] mt-[50px] md:mt-[80px]">Book your large group transfers through us...</span>
              <div className="flex md:min-h-[450px] justify-end mx-[10px] md:pt-[60px] pt-[0px]">
                <img src={Bus} alt="" className="w-[70%] lg:w-[302px] xl:w-[520px] h-[377px] absolute left-[0px] md:left-[-50px] lg:left-0 top-[70px] sm:top-[100px] md:top-[170px] lg:top-[165px]" />
                <div className="bg-[#51545B] rounded-[10px] p-[10px] md:p-[20px] max-w-[600px] h-auto text-[18px] font-[400] mx-auto lg:mx-0 mt-[220px] sm:mt-[250px] md:mt-[290px] lg:mt-0">
                  <p className={`text-[16px] font-[400] px-[15px]`}>
                    We work with licensed coach operators across the United Kingdom to provide you with the best possible transfer experience. As an agent for this specific vehicle category, we will find the best price for your transfer and get back to you with a quote shortly.
                    Our prices are optimal in terms of both price and quality of service, and we offer an individual approach to each trip, request, and offer. Our drivers are professionals with many years of passenger transportation experience, and our dispatchers work 24/7 to assist you at any time. We have all kinds of vehicles with drivers, including cars, vans, minibuses, and buses, to suit your needs
                    <span onClick={() => { history.push('\services') }} className="text-[#FFD369] hover:cursor-pointer">
                      ...read more
                    </span>
                  </p>
                  <div className="flex justify-end w-full">
                    <button
                      className="bg-[#7952B3] w-[200px] h-[34px] rounded-[10px] mt-[20px] hover:cursor-pointer"
                      onClick={() => {
                        setShowAvailability(true)
                        setFormType('intercity')
                        userDispatch({ type: "BUSONLY", payload: true });
                        userDispatch({ type: "FORMTYPE", payload: 'airport' })
                      }}
                    >Get Quote</button>
                  </div>
                </div>
              </div>
              <div className="h-[100px] flex items-center px-[20px] pt-[20px]">
                <span className="">*For large group airport pick-up we will provide arrival meet and greet travel coordinator included with the fare.</span>
              </div>
            </div>
          </div >

          {/* Download the app */}
          <div className="download px-[2%]" >
            <div className="download__inner container container-l">
              <img className="download__image" src={PhoneImag} alt="app" />
              <div className="download__text-wrap">
                <h2 className="h3 download__title h1 black">
                  Download
                  <br />
                  The App Now
                </h2>
                <div className="download__links-wrap">
                  <a href="/welcome" className="download__link">
                    <img src={AppleStore} alt="apple appstore" />
                  </a>
                  <a href="/welcome" className="download__link">
                    <img src={GoogleStore} alt="google appstore" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Testimonials */}

          <Testimonials />

          <div className="container container-xl agent-program" >
            <div className="agent-program__text-wrap">
              <h2 className="agent-program__title">B2B Travel Agent Program</h2>
              <p className="agent-program__text">
                Ridewing provides elite service tailored to travel agencies. We go out of our way to ensure safety, quality and comfort of your customers. In addition, we offer attractive commissions to help you grow your business.
              </p>
              <Link
                className="agent-program__button neeto-ui-btn neeto-ui-btn neeto-ui-btn_md neeto-ui-btn--style-primary neeto-ui-btn--width-full"
                onClick={() => history.push("/agents")}
              >
                Register Now
              </Link>
            </div>
            <div className="faq">
              <Accordion className="agent-program__faq-item" defaultActiveKey={0}>
                <Accordion.Item title="Immediate availability">
                  Book online 24/7 with immediate confirmation. With Ridewing, you
                  have real time availability and hassle free booking.
                </Accordion.Item>
              </Accordion>

              <Accordion className="agent-program__faq-item">
                <Accordion.Item title="Priority support">
                  A professional support team is accessible 24/7 to assist with
                  any day to day needs including on-site requirements. Our
                  services ensure customer satisfaction.
                </Accordion.Item>
              </Accordion>

              <Accordion className="agent-program__faq-item">
                <Accordion.Item title="Best service guaranteed">
                  Make bookings for your clients with assurance of offering them
                  the best possible price and getting the best benefit for you and
                  your client.
                </Accordion.Item>
              </Accordion>
            </div>
          </ div>
          <div className="flex flex-wrap w-full container-xl mx-auto justify-between  p-[50px]">
            <div className="flex flex-col">
              <span className="">Secure payment with</span>
              <img src={WorldPay} alt="" className="w-[200px] md:w-[256px] py-[20px] mx-auto" />
            </div>
            <img src={Signature} alt="" className="py-[20px] mx-auto" />
          </div>
        </main >
      }
      <Footer />
      {
        showAvailability &&
        <div className="fixed top-0 bg-black h-[100vh] w-[100vw] z-[8] flex flex-col justify-center items-center">
          <PickupForm formType={formType} setFormType={setFormType} showAvailability={showAvailability} setShowAvailability={setShowAvailability} />
        </div>
      }
    </div >
  );
};

export default Home;
