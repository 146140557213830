import React from "react";

import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "../hooks/ScrollToTop";
import Agents from "../pages/Agents";
import Confirmation from "../pages/Confirmation";
import Contact from "../pages/Contact";
import Modify from "../pages/Contact/Modify";
import ContactIn from "../pages/Contact/SignContact";
import Dispatcher from "../pages/Dispatcher";
import Home from "../pages/Home";
import MyBookings from "../pages/MyBookings";
import Privacy from "../pages/Privacy";
import Refund from "../pages/Refund";
import Service from "../pages/Service";
import Signin from "../pages/Signin";
import OtpPage from "../pages/Signin/Otp";
import Signup from "../pages/Signup";
import Tabs from "../pages/Tabs";
import Terms from "../pages/Terms";
import Welcome from "../welcome";
import PaymentSuccessful from "../pages/PaymentSuccessful";
import PaymentUnsuccessful from "../pages/PaymentUnsuccessful";
import AgentRegSuccessful from "../pages/AgentRegSucessful";
import DispatcherRegSuccessful from "../pages/DispatcherRegSucessful";
import QuoteSuccessful from "../pages/QuoteSuccessful";
import Blog from "../pages/Blog";
import BlogView from "../pages/Blog/BlogView";
import ModifyBookingPage from "../pages/MyBookings/ModifyBookingPage";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <ScrollToTop>
          <Route exact path="/">
            <Home />
            {/* <Welcome /> */}
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/services">
            <Service />
          </Route>
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route exact path="/blog/:id">
            <BlogView />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/tabs/:id">
            <Tabs />
          </Route>
          <Route exact path="/confirm">
            <Confirmation />
          </Route>
          <Route exact path="/signin">
            <Signin />
          </Route>
          <Route exact path="/otp/:email?">
            <OtpPage />
          </Route>
          <Route exact path="/signup">
            <Signup />
          </Route>
          <Route exact path="/my-booking">
            <MyBookings />
          </Route>
          <Route exact path="/my-booking-modify">
            <ModifyBookingPage />
          </Route>
          <Route exact path="/agents">
            <Agents />
          </Route>
          <Route exact path="/dispatcher">
            <Dispatcher />
          </Route>
          <Route exact path="/contact-in">
            <ContactIn />
          </Route>
          <Route exact path="/modify">
            <Modify />
          </Route>
          <Route exact path="/terms-and-conditions">
            <Terms />
          </Route>
          <Route exact path="/privacy-policy">
            <Privacy />
          </Route>
          <Route exact path="/refund-policy">
            <Refund />
          </Route>
          <Route exact path="/welcome">
            <Welcome />
          </Route>
          <Route exact path="/paymentsuccessful">
            <PaymentSuccessful />
          </Route>
          <Route exact path="/paymentunsuccessful">
            <PaymentUnsuccessful />
          </Route>
          <Route exact path="/agentregsuccessful">
            <AgentRegSuccessful />
          </Route>

          <Route exact path="/dispatcherregsuccessful">
            <DispatcherRegSuccessful />
          </Route>
          <Route exact path="/quotesuccessful">
            <QuoteSuccessful />
          </Route>

          {/* <Route path="/about">
        <About />
      </Route>
      <Route path="/:user">
        <User />
      </Route>
      <Route>
        <NoMatch />
      </Route> */}
        </ScrollToTop>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
