import React, { useState } from "react";
import classNames from "classnames";
import Navbar from "../../../common/Navbar";
import FlightDetails from "./FightModify";
import PassagerDetails from "./PassagerModify";
import Footer from "../../../common/Footer";

const Modify = () => {
  const [state, setState] = useState("flight");

  const onTabChange = (value) => {
    setState(value);
  };

  return (
    <div className="flex flex-col  inner-page text-white bg-black ">
      <Navbar />
      <div className="flex flex-col overflow-auto home-height">
        <div className="flex justify-center w-full space-x-2 md:mt-2">
          <div className="flex w-full max-w-3xl space-x-2 justify-evenly ">
            <div
              className={classNames(["text-white  py-1 cursor-pointer"], {
                "border-white border-b-2": state === "flight",
              })}
              onClick={() => onTabChange("flight")}
            >
              Flight Details
            </div>
            <div
              className={classNames(["text-white py-1 cursor-pointer"], {
                "border-white border-b-2": state === "passenger",
              })}
              onClick={() => onTabChange("passenger")}
            >
              Passenger Details
            </div>
            {/* {state == "flight" && (
              <div
                className={classNames(
                  ["text-white py-1 self-end font-semibold mx-10"],
                  {
                    "border-white border-b-2 ": state === "skip",
                  }
                )}
                onClick={() => onTabChange("passenger")}
              >
                Skip
              </div>
            )} */}
          </div>
        </div>
        <div>
          {state === "flight" && (
            <FlightDetails
              onTabChange={onTabChange}
              modify={true}
              state={state}
            />
          )}
        </div>
        <div>
          {state === "passenger" && (
            <PassagerDetails onTabChange={onTabChange} modify={true} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Modify;
