import React, { useRef } from "react";
import Navbar from "../../common/Navbar";
import { Input, Radio, Button } from "@bigbinary/neetoui";
import { Input as FormikInput } from "@bigbinary/neetoui/formik";
import Footer from "../../common/Footer";
import { Formik, Form, Field } from "formik";

const ContactIn = () => {
  const intialState = {
    status: true,
    name: "",
    email: "",
    number: "",
    comments: "",
  };
  const onSubmit = () => {};

  return (
    <div className="flex flex-col  text-white bg-black ">
      <Navbar />
      <div className="flex items-center justify-center ">
        <div className="w-1/2 px-10 py-6 space-y-6">
          <Formik initialValues={intialState} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => {
              return (
                <Form className="p-4 space-y-4 border border-gray-200">
                  <Field name="status">
                    {({ field, form: { touched, errors }, meta }) => (
                      <div className="flex py-4 justify-evenly ">
                        <Radio.Item
                          name="status"
                          label="Say Hello"
                          checked={values.status}
                          className="flex items-center justify-center px-2 space-x-1"
                          onChange={() => {
                            setFieldValue("status", true);
                          }}
                          {...field}
                        />
                        <Radio.Item
                          name="status"
                          label="Modify"
                          className="flex items-center justify-center px-2 space-x-1"
                          checked={!values.status}
                          {...field}
                          onChange={() => {
                            setFieldValue("status", false);
                          }}
                        />

                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <FormikInput
                    name="name"
                    nakedInput
                    label="Name"
                    className="mx-2 border-b border-gray-400"
                  />

                  <FormikInput
                    nakedInput
                    name="email"
                    label="Email"
                    className="mx-2 border-b border-gray-400"
                  />
                  <FormikInput
                    nakedInput
                    label="Number"
                    name="number"
                    className="mx-2 border-b border-gray-400"
                  />
                  <FormikInput
                    nakedInput
                    label="Comments"
                    name="comments"
                    className="mx-2 border-b border-gray-400"
                  />

                  <div className="flex justify-center">
                    <Button size="large" label="Submit" />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactIn;
