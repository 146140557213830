import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";
import { Input, Button } from "@bigbinary/neetoui";
import Footer from "../../common/Footer";
import ServicesApi from "../../api/passengerDetails";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [error] = useState("");
  const history = useHistory(); 

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSubmit = async (data) => {
    const payload = {
      email: email,
      role: "user",
    };
    // console.log(payload);
    if (validateEmail(email)) {
      const res = await ServicesApi.SigninApi(payload);
      history.push("otp/" + email);
    } else {
      // setError("Please enter a valid email");
      toast.warning("Please enter a valid email", {
        // Set to 15sec
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="flex flex-col  inner-page text-white bg-black authentication-page">
      <Navbar />
      <div className="authentication pb-4 lg:py-16">
        <div className="container container-xl">
          <div className="authentication__box">
            <div className="authentication__heading-wrap">
              <h1 className="h2">Sign in</h1>
              <p>to continue to Ridewing</p>
            </div>
            <div>
              <Input
                nakedInput
                placeholder="Email or Phone"
                className="border-b border-gray-400"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <div className="pt-2 text-red-500">{error}</div>
              <div className="pt-4 text-sm">
                By Clicking 'Get Code', you accept our T&C and Data Privacy
                Policy{" "}
                <Link
                  title="Terms & Conditions"
                  onClick={() => history.push("/terms-and-conditions")}
                  className=""
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div className="authentication__button-wrap">
              <Button
                label="Get Code"
                className="authentication__submit"
                onClick={() => onSubmit()}
              />
              <Link
                title="Create account"
                onClick={() => history.push("/signup")}
                className="authentication__alt-link "
              >
                Create account
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signin;
