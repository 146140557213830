import React, { useEffect, useState } from 'react'
import ServicesApi from '../../api/passengerDetails';
// import { useHistory } from "react-router-dom";

const ModifyBooking = ({ modifyTripDetails }) => {
  // useEffect(() => {
  //   console.log(modifyTripDetails);
  // },[modifyTripDetails])
  const [formData, setFormData] = useState({
    email: modifyTripDetails.mail || '',
    name: modifyTripDetails.name || '',
    phone: modifyTripDetails.phone || '',
    note: '',
    booking_id: modifyTripDetails.booking_id || ''
  })
  const [errors, setErrors] = useState({
    email: '',
    phone: ''
  });
  const [sentMessage, setSentMessage] = useState(false)
  // const history = useHistory()
  const handleChange = (e) => {
    const { name, value } = e.target;
    const processedValue = name === 'phone' ? parseInt(value, 10) : value;
    setFormData({ ...formData, [name]: processedValue });
    // setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    // Regular expression for a basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // const validatePhone = (phone) => {
  //   // Regular expression for a basic UK phone number validation
  //   const regex = /^(?:(?:\+44)|(?:0))(?:(?:(?:20|21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|70|71|72|73|74|75|76|77|78|79|80|81|82|83|84|85|86|87|88|89|90|91|92|93|94|95|96|97|98|99)\d{1,8}(?:\s?\d{1,8})?)|(?:7[0-9]{8})|(?:1\d{3}))\d{6}$/;
  //   return regex.test(phone);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email and phone
    const emailError = validateEmail(formData.email) ? '' : 'Invalid email format';
    // const phoneError = validatePhone(formData.phone) ? '' : 'Invalid phone number';

    setErrors({ email: emailError });

    // If there are validation errors, prevent form submission
    if (emailError) {
      return;
    }
    try {
      await ServicesApi.UserSupport(formData);
      setSentMessage(true)
      // setTimeout(() => {
      //   history.push("/");
      // }, 1500);

    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className='flex flex-col items-center'>
      <h4>Modify Booking</h4>
      <div className='p-[20px] flex flex-col items-center justify-center w-[100vw] min-h-[75vh]'>
        <form onSubmit={handleSubmit} className='flex flex-col max-w-[700px] w-[100%] items-center'>
          {sentMessage ?
            <div>
              <span className=''>Message sent successfully</span>
            </div>
            :
            <>
              <div className='border-white border-[1px] rounded-[5px] px-[10px] w-full'>
                {/* <label htmlFor="booking_id">Booking ID:</label> */}
                <input
                  // type="number" 
                  id="booking_id" name="booking_id" value={formData.booking_id} onChange={handleChange}
                  inputMode="numeric"
                  placeholder='Booking ID'
                  className='bg-inherit  placeholder-[slate-400] p-[10px] w-full'
                  style={{ outline: 'none' }}
                  required />
              </div>
              <div className='border-white border-[1px] rounded-[5px] p-[20px] flex flex-col mt-[10px] w-full'>
                {/* <label htmlFor="email">Email:</label> */}
                <input id="email" name="email" value={formData.email} onChange={handleChange}
                  placeholder='Email'
                  className='bg-inherit border-b-[1px] border-white placeholder-[slate-400] py-[10px] '
                  style={{ outline: 'none' }}
                  required />
                <div className='error-message text-[red]'>{errors.email}</div>
                {/* <label htmlFor="name">Name:</label> */}
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange}
                  placeholder='Name'
                  className='bg-inherit border-b-[1px] border-white placeholder-[slate-400] py-[10px] mt-[10px]'
                  style={{ outline: 'none' }}
                  required />

                {/* <label htmlFor="phone">Phone:</label> */}
                <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange}
                  placeholder='Phone'
                  className='bg-inherit border-b-[1px] border-white placeholder-[slate-400] py-[10px] mt-[10px]'
                  style={{ outline: 'none' }}
                  required />
                {/* <div className='error-message text-[red]'>{errors.phone}</div> */}
                {/* <label htmlFor="note">Note:</label> */}
                <textarea id="note" name="note" value={formData.note} onChange={handleChange}
                  placeholder='Message'
                  className='bg-inherit border-b-[1px] border-white placeholder-[slate-400] py-[10px] mt-[10px]'
                  style={{ outline: 'none' }}
                  rows="4" required></textarea>


              </div>
              <input type="submit" value="Send Mail" className='w-[300px] h-[63px] bg-[#FFD369] text-[#393E46] rounded-[6px] mt-[60px] font-bold hover:cursor-pointer' />
            </>}
        </form>
      </div>
    </div>
  )
}

export default ModifyBooking