import calendar from '../../assets/calendarIcon.png'
import clock from '../../assets/clockIcon.png'
import start from '../../assets/startLocation.png'
import midLocation from '../../assets/midLocation.png'
import destination from '../../assets/destination.png'
import distance from '../../assets/distanceIcon.png'
import duration from '../../assets/durationIcon.png'
import profile1 from '../../assets/profileIcon1.png'
import profile2 from '../../assets/profileIcon2.png'
import profile3 from '../../assets/profileIcon3.png'
import text from '../../assets/textIcon.png'
import call from '../../assets/callIcon.png'
import luggage from '../../assets/luggageIcon.png'
import child from '../../assets/childIcon.png'
import flight from '../../assets/flightIcon.png'
import arrival from '../../assets/arrivalIcon.png'

const BookingRow = (props) => {
  // console.log("YP69KD", props);
  const handleModifyButton = () => {
    // console.log("handleModifyTripDetails");
    // console.log("Modify Button");
    props.setShowBookingModify(true)
    props.setModifyTripDetails({
      booking_id: props.booking_number,
      mail: props.cust_email,
      name: props.cust_name,
      phone: props.cust_phone
    })
  }
  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8  text-black font-semibold bg-[#403d4d] mt-[40px] rounded-[10px] border-[1px] border-white text-[14px] py-[10px]" key={props.id}>
        <div className="col-span-1 h-[69px] flex pl-[20px] text-white flex-col pt-2">
          <div className="flex items-center"><img src={calendar} alt="date" className="h-[14px] pr-[5px]" />{props.pickup_date}</div>
          <div className="border-b-[1px] border-dotted border-gray-500 w-[50%] pt-[5px]"></div>
          <div className="flex items-center pt-2"><img src={clock} alt="date" className="h-[14px] pr-[5px]" />{props.pickup_time}</div>
        </div>
        <div className="col-span-1 pl-[20px] flex text-white flex-col pt-2">
          <div className="flex ">{props.booking_number}</div>
          <div className="border-b-[1px] border-dotted border-gray-500 w-[50%] pt-[5px]"></div>
          <div className="flex pt-2">{props.vehicle_cat}</div>
        </div>
        <div className="col-span-2 pl-[20px] flex text-white flex-col pt-2">
          <div className="flex flex-col gap-[10px] items-center md:items-start">
            <div className="flex"><img src={start} alt="date" className="h-[14px] pr-[5px]" />{props.pick_up_loc}</div>
            {props.extra_stop1 !== "" &&
              <div className="flex"><img src={midLocation} alt="date" className="h-[14px] pr-[5px]" />{props.extra_stop1}</div>
            }
            {props.extra_stop2 !== "" &&
              <div className="flex"><img src={midLocation} alt="date" className="h-[14px] pr-[5px]" />{props.extra_stop2}</div>
            }
            {props.extra_stop3 !== "" &&
              <div className="flex"><img src={midLocation} alt="date" className="h-[14px] pr-[5px]" />{props.extra_stop3}</div>
            }
            <div className="flex"><img src={destination} alt="date" className="h-[14px] pr-[5px]" />{props.drop_off_loc}</div>
          </div>
          <div className="border-b-[1px] border-dotted border-gray-500 w-full pt-[5px]"></div>
          <div className="flex mt-[10px]">
            <div className="flex justify-center items-center p-2 rounded-[10px] bg-[gray] mr-[10px]"><img src={distance} alt="" className="h-[20px]" /> {props.distance} Miles </div>
            <div className="flex justify-center items-center p-2 rounded-[10px] bg-[gray]"><img src={duration} alt="" className="h-[20px]" /> {props.duration} </div>
          </div>
          {/* <span className="font-semibold italic text-[#FFD369] hover:cursor-pointer py-2">
            Open in Map
          </span> */}
        </div>
        <div className="col-span-1 pl-[20px] flex text-white flex-col pt-2">
          <div className="flex flex-col text-right max-w-[60px]">
            <span>£ {props.fare}</span>
            <span>£ {props.extra_fare}</span>
          </div>
        </div>
        <div className="col-span-2 pl-[20px] flex text-white flex-col pt-2 pr-[4%]">
          <span className="text-[9px] text-[#AAAAAA] font-semibold border-b-[1px] border-dotted border-gray-500 w-full pb-[5px]">Passenger Details</span>
          <div className="py-[5px]">
            <div className="flex items-center justify-between">
              <div className="flex pr-[20px] items-center"><img src={profile1} alt="date" className="h-[14px] pr-[5px]" />{props.cust_name}</div>
              <img src={text} alt="date" className="h-[14px] pr-[20px]" onClick={() => window.location.href = 'sms:' + props.cust_phone_ext + props.cust_phone} />
              <img src={call} alt="date" className="h-[14px] pr-[5px]" onClick={() => window.location.href = 'tel:' + props.cust_phone_ext + props.cust_phone} />
            </div>
            <div className="py-2 flex">
              <div className="bg-[gray] rounded-[10px] p-2 flex gap-4">
                <div className="flex items-center justify-center"><img src={profile2} alt="date" className="h-[14px] pr-[5px]" />{props.passenger_no}</div>
                <div className="flex items-center justify-center"><img src={luggage} alt="date" className="h-[14px] pr-[5px]" />{props.luggage_no}</div>
                <div className="flex items-center justify-center"><img src={child} alt="date" className="h-[14px] pr-[5px]" />{props.child_seat_no}</div>
              </div>
            </div>
          </div>
          <span className="text-[9px] text-[#AAAAAA] font-semibold border-b-[1px] border-dotted border-gray-500 w-full pb-[5px]">Flight Details</span>
          {props.is_source_airpot === 1 &&
            <div className="py-[5px]">
              <div className="flex items-center">
                <div className="flex pr-[20px] "><img src={flight} alt="date" className="h-[14px] pr-[5px]" />{props.flight_no}</div>
                <div className="flex pr-[20px] "><img src={arrival} alt="date" className="h-[14px] pr-[5px]" />{props.flight_date_time}</div>
                <span className="font-semibold text-[#FFD369] hover:cursor-pointer py-2"
                  // onClick={() => window.location.href = `https://planefinder.net/flight/${props.flight_no}`}
                  onClick={() => window.open(`https://planefinder.net/flight/${props.flight_no}`, '_blank')}
                >
                  Flight Status
                </span>
              </div>
              <div className="py-2 flex items-center">
                <div className="flex pr-[20px] "><img src={profile3} alt="date" className="h-[14px] pr-[5px]" />{props.cust_name}</div>
                {props.buffer_time !== null &&
                  <div className="bg-[gray] rounded-[10px] p-1 flex justify-center items-center">
                    <div className="flex items-center">Buffer Time : {props.buffer_time_mins}</div>
                  </div>
                }
              </div>
            </div>
          }
          <span className="text-[9px] text-[#AAAAAA] font-semibold border-b-[1px] border-dotted border-gray-500 w-full pb-[5px]">Comments Details</span>
          <span className="text-[12px] pt-2">
            {props.note === "" ? "No comments" : props.note}
          </span>
        </div>
        <div className="col-span-1 pl-[20px] flex text-white flex-col pt-2 gap-[10px]">
          {props.setShowBookingModify !== undefined &&
            <>
              <div
                className="w-[80%] max-w-[108px] h-[28px] bg-[#FFD369] text-black hover:cursor-pointer flex justify-center items-center text-semibold rounded-[10px]"
                onClick={() => {
                  handleModifyButton()
                }}
              >Modify</div>
            </>
          }
        </div>
      </div>

    </>
  );
};

export default BookingRow;
