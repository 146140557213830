import React from "react";
import { Button } from "@bigbinary/neetoui";
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";
import { Link, useHistory } from "react-router-dom";

const QuoteSuccessful = () => {
  const history = useHistory();

  return (
    <div className="flex flex-col  inner-page text-white bg-black authentication-page">
      <Navbar />
      <div className="authentication payment pb-4 lg:py-16">
        <div className="container container-xl">
          <div className="authentication__box">
            <h1 className="h2">Quote Request Successful</h1>
            <p>
              We appreciate your interest in our services and look forward to
              assisting you with your needs. We'll be in touch shortly with the
              quote.
            </p>
            <div className="payment__button-options">
              <Link
                title="Home page"
                onClick={() => history.push("/")}
                className="authentication__alt-link "
              >
                Home
              </Link>
              <Link
                title="Your account"
                onClick={() => history.push("/signup")}
                className="authentication__alt-link "
              >
                My Account
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default QuoteSuccessful;
