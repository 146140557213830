import React from "react";
import Navbar from "../../common/Navbar";
import { Input, Button } from "@bigbinary/neetoui";
import EconomyImage from "../../assets/car/Economy.svg";
import Footer from "../../common/Footer";

const Confirmation = () => {
  return (
    <div className="flex flex-col  inner-page text-white bg-black ">
      <Navbar />
      <div className="flex flex-col items-center justify-center px-4 overflow-auto">
        <div className="max-w-7xl">
          <div className="flex justify-center w-full text-xl font-semibold md:pt-24">
            Confirmation
          </div>
          <div className="items-center w-full md:flex ">
            <div className="md:w-1/2 md:px-16">
              <div>
                <div className="flex flex-col self-center justify-center w-full space-y-6">
                  <Input disabled value="Booking Id" className="w-full" />
                </div>
                <div className="py-6 text-lg font-semibold">
                  Journey Details
                </div>
                <div className="w-full px-2 py-4 border border-gray ">
                  <div className="px-8">
                    <div className="flex justify-between w-full text-center">
                      <div className="py-2 space-y-2">
                        <img src={EconomyImage} width="150px" height="150px" />
                        <div>First Class</div>
                      </div>
                      <div className="flex items-center ">
                        <div>
                          <span className="px-2 py-1 text-black bg-white rounded-full">
                            {String.fromCharCode(163)}
                          </span>{" "}
                          30.00
                        </div>
                      </div>
                    </div>
                    <div className="pt-4 space-y-2">
                      <div className="flex items-center">
                        <i class="ri-star-line pr-2"></i>
                        London Heathrow Airport
                      </div>
                      <div className="flex items-center">
                        <i class="ri-star-line pr-2"></i>London City Airport
                      </div>
                      <div className="flex items-center">
                        <i class="ri-star-line pr-2"></i>York City Airport
                      </div>
                    </div>
                    <div className="flex justify-between w-2/3 pt-4 pb-2 ">
                      <div className="flex items-center">
                        <i className="pr-2 ri-calendar-line" />
                        22 Dec 2021
                      </div>
                      <div className="flex items-center">
                        <i className="pr-2 ri-map-pin-time-line" /> 17:15
                      </div>
                    </div>
                    <div className="flex justify-between w-2/3 py-2 ">
                      <div className="flex items-center">
                        <i className="pr-2 ri-pin-distance-line" />
                        50 Miles
                      </div>
                      <div className="flex items-center">
                        <i className="pr-2 ri-time-line" />1 Hour
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="py-6 text-lg font-semibold">Flight Details</div>
                <div className="w-full px-2 px-8 py-4 space-y-4 border border-gray ">
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <i className="pr-2 ri-car-line" />
                      Flight Number
                    </div>
                    <div>CDX 112233:53 </div>
                  </div>
                  <div className="flex justify-between ">
                    <div className="flex items-center">
                      <i className="pr-2 ri-time-line"></i>Flight Arrival Time
                    </div>
                    <div>16:00</div>
                  </div>
                  <div className="flex justify-between ">
                    <div className="flex items-center">
                      <i className="pr-2 ri-time-line"></i>Buffer Time
                    </div>
                    <div>1 hour 15 minutes</div>
                  </div>
                  <div className="flex justify-between p ">
                    <div className="flex items-center">
                      <i className="pr-2 ri-car-line" />
                      Name on Board
                    </div>
                    <div> ri-passport-fillMathew</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 md:px-16">
              <div className="md:mt-24">
                <div className="self-center w-full">
                  <Button size="large" label="Modify Booking" fullWidth />
                </div>
                <div className="py-6 text-lg font-semibold">
                  Passenger Details
                </div>

                <div className="w-full px-2 px-8 py-4 space-y-4 border border-gray">
                  <div className="flex items-center">
                    <i className="pr-2 ri-passport-fill" /> Mathew Rutherford
                  </div>
                  <div className="flex items-center">
                    <i className="pr-2 ri-user-fill" /> 2 Passengers
                  </div>
                  <div className="flex items-center">
                    <i className="pr-2 ri-luggage-deposit-line " />1 Baggage
                  </div>
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <i className="pr-2 ri-user-smile-fill" />
                      <div>2 Child Seats</div>
                    </div>
                    <span>0-36 Kg</span>
                    <span>9-36 Kg</span>
                  </div>
                  <div>Comments</div>
                  <div>
                    Lorem Ipsum is simply dummy text ofLorem Ipsum is simply
                    dummy tex the printing and typesetting industry.
                  </div>
                </div>
              </div>
              <div className="py-6">
                <div className="w-full border border-gray">
                  <div className="p-2 text-xl text-center text-black bg-yellow-1000">
                    Open In Map
                  </div>
                  <div className="h-60"></div>
                </div>
                <div className="w-full py-4 md:flex md:justify-center">
                  <div className="w-60">
                    <Button
                      label="Contact Driver"
                      size="large"
                      fullWidth
                      className="rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Confirmation;
