import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Headroom from "react-headroom";
import FlightDetails from "./FightDetails";
import Navbar from "../../common/Navbar";
import VehicleDetails from "./Vehicle";
import Footer from "../../common/Footer";
import PassagerDetails from "./PassagerDetails";
import PaymentrDetails from "./Payments";
import { useHistory, useParams } from "react-router-dom";
import { useUserState } from "../../Context";
import CheckoutIntegrationSample from "./worldpay";

const Tabs = () => {
  const { id } = useParams();

  const [state, setState] = useState("vehicle");
  const [warning, setWarning] = useState(false)
  const [bookingDetails, setBookingDetails] = useState({
    bookingNumber: "",
    fare: ""
  })
  const userState = useUserState();

  const onTabChange = (value) => {
    if (value !== "confirm") {
      history.push("/tabs/" + value);
      setState(value);
    } else {
      history.push("/confirm");
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to refresh? You will lose current data.";
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (id) {
      setState(id);
    }
  }, [id]);

  const closeButtonHandler = () => {
    setWarning(false)
    // history.push("/");
  }

  const history = useHistory();

  return (
    <div className="flex flex-col  inner-page text-white bg-black ">
      <Navbar />
      <main className="main_inner container container-l">
        <Headroom className="booking-tabs-wrap">
          <div className="flex self-center w-full space-x-2 justify-evenly md:w-1/2 mx-auto booking-tabs">
            <div
              className={classNames(["text-white py-1 cursor-pointer "], {
                "border-white border-b-2": state === "vehicle",
              })}
              onClick={() => onTabChange("vehicle")}
            >
              Vehicle Details
            </div>
            {userState.journeyInfo.isAirport && (
              <div
                className={classNames(["text-white  py-1 cursor-pointer "], {
                  "border-white border-b-2": state === "flight",
                })}
                onClick={() => onTabChange("flight")}
              >
                Flight Details
              </div>
            )}
            <div
              className={classNames(["text-white py-1 cursor-pointer"], {
                "border-white border-b-2": state === "passenger",
              })}
              onClick={() => onTabChange("passenger")}
            >
              Passenger Details
            </div>
          </div>
        </Headroom>

        <>
          {state === "vehicle" && <VehicleDetails onTabChange={onTabChange} />}
        </>
        <>
          {state === "flight" && <FlightDetails onTabChange={onTabChange} />}
        </>
        <>
          {state === "passenger" && (
            <PassagerDetails onTabChange={onTabChange} setWarning={setWarning} setBookingDetails={setBookingDetails} bookingDetails={bookingDetails} />
          )}
        </>
        <>
          {state === "payment" && <PaymentrDetails onTabChange={onTabChange} />}
        </>
      </main>
      {warning &&
        <div className="fixed h-[100vh] w-[100vw] bg-black bg-opacity-70 top-0 z-[320] overflow-hidden flex justify-center items-center" >
          <div className="h-[300px] w-[90%] max-w-[500px] bg-black rounded-[5px] border-gray-500 border-[1px] flex flex-col justify-center items-center gap-3 text-center px-[20px]">
            <form action="https://secure-test.worldpay.com/wcc/purchase" method="POST" className="flex flex-col gap-[10px]">
              {/* <input type="hidden" name="cartId" value={bookingDetails.bookingNumber}></input>
              <input type="hidden" name="amount" value={bookingDetails.fare}></input> */}
              {/* <input type="submit" value="Buy This "></input> */}
              {/* <input type="hidden" name="testMode" value="100"></input> */}
              <input type='hidden' name='instId' value='E767379E0A5F31CDA9BDA7D1FF5' />
              <label htmlFor="cartId" className="text-yellow-1000 text-left">Booking Number</label>
              <input
                name="cartId"
                value={bookingDetails.bookingNumber}
                className="bg-inherit border-[1px] border-yellow-1000 p-[10px] rounded-[5px]"
              />
              <label htmlFor="amount" className="text-yellow-1000 text-left">Fare</label>
              <input
                name="amount"
                value={bookingDetails.fare}
                className="bg-inherit border-[1px] border-yellow-1000 p-[10px] rounded-[5px]"
              />
              <input type="hidden" name="currency" value="GBP"></input>
              <p>You will be redirected to the payment page</p>
              <button className="px-[10px] py-[5px] bg-yellow-1000 rounded-[2px] text-black mt-[25px]"
                type="submit"
              // onClick={closeButtonHandler}
              >Pay Now</button>
            </form>
          </div>
          {/* <CheckoutIntegrationSample /> */}
        </div>
      }
      <Footer />
      {/* <p>Unfortunately we are facing temporary issue accepting payments right now.</p>
    <p>Your booking is confirmed, Expect a call or message from our representative shortly to assist you personally.</p>
    <p>Thank you for your patience and understanding!</p> */}

    </div >
  );
};

export default Tabs;
