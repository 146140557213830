import React from "react";
import Navbar from "../../common/Navbar";
import { Button } from "@bigbinary/neetoui";
import { Input as FormikInput } from "@bigbinary/neetoui/formik";
import Footer from "../../common/Footer";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ServicesApi from "../../api/passengerDetails";
import { useHistory } from "react-router-dom";

const Dispatcher = () => {
  const history = useHistory();

  const initialState = {
    company_name: "",
    name: "",
    contact_number: "",
    email: "",
    operating_city: "",
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Company Name is required"),
    name: Yup.string().required("Name is required"),
    contact_number: Yup.string()
      .required("Contact Number is required")
      .matches(/^\d{10}$/, "Invalid Contact Number"),
    email: Yup.string().required("Email is required").email("Invalid Email"),
    operating_city: Yup.string().required("Operating City is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      // console.log(values);
      // Make the POST request to the server
      const response = await ServicesApi.DispatcherRegEmail(values);
      // Handle the response as needed
      // console.log("Form submitted successfully!", response);
      history.push("/dispatcherregsuccessful");
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="flex flex-col  text-white bg-black ">
      <Navbar />
      <main className="dispatcher-main">
        <div className="container container-xl flex flex-col items-center w-full lg:flex-row justify-evenly lg:justify-start lg:items-start">
          <div className="dispatcher-first-col flex flex-col flex-1 items-start w-full px-4 lg:pl-0 py-16 justify-items-start lg:w-1/2">
            <h1 className="h2">Partner Registration</h1>
            <h2 className="h3 mt-5">Your advantages as a partner</h2>
            <ul className="dispatcher-advantages-list mt-10 lg:mt-14">
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>
                  Flexible scheduling- there is no minimum or maximum number of
                  rides, choose the rides that best fit your convenience.
                </p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>
                  No missing out- Get notified when transfers are available and
                  accept the ones that best fit your schedule.
                </p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>
                  Bonus opportunities- attractive bonus opportunities for top
                  performing chauffeurs.
                </p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>
                  Reduce downtime- accept as many requests as you can handle,
                  there is no minimum or maximum number of transfers that you
                  have to do.
                </p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>
                  Elite account management- stress free management of all your
                  rides with a few clicks.
                </p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>
                  Innovative system –get all the necessary information through
                  email or text. Receive a text message with clients flight is
                  delayed.
                </p>
              </li>
              <li className="dispatcher-advantages-list__li">
                <i className="ri-checkbox-line "></i>
                <p>
                  Easy payment handling – transparent payment process and no
                  hidden charges.
                </p>
              </li>
            </ul>
          </div>
          <div className="lg:pl-4 py-16 space-y-6 flex-1">
            <h2 className="hidden lg:block">&nbsp;</h2>
            <h2 className="h3">Register as a partner and work with us</h2>
            <p className="pb-4">
              Fill out the form below and become an Ridewing partner. In case of
              any further queries, feel free to contact us.
            </p>
            <Formik
              initialValues={initialState}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form className="space-y-4 form">
                  <FormikInput
                    name="name"
                    nakedInput
                    label="Name"
                    className="mx-2 border-b border-gray-400"
                    error={touched.name && errors.name}
                  />
                  <FormikInput
                    nakedInput
                    label="Contact Number"
                    name="contact_number"
                    className="mx-2 border-b border-gray-400"
                    error={touched.contact_number && errors.contact_number}
                  />
                  <FormikInput
                    nakedInput
                    name="email"
                    label="Email"
                    className="mx-2 border-b border-gray-400"
                    error={touched.email && errors.email}
                  />
                  <FormikInput
                    nakedInput
                    name="company_name"
                    label="Company Name"
                    className="mx-2 border-b border-gray-400"
                    error={touched.company_name && errors.company_name}
                  />
                  <FormikInput
                    nakedInput
                    name="operating_city"
                    label="Operating City"
                    className="mx-2 border-b border-gray-400"
                    error={touched.operating_city && errors.operating_city}
                  />
                  <div className="mx-1">
                    {/* Privacy Policy, Terms and Conditions links */}
                  </div>
                  <div className="flex justify-center">
                    <Button
                      className="btn-register"
                      size="large"
                      label="Register"
                      type="submit"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Dispatcher;
