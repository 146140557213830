import { LoadScript } from "@react-google-maps/api";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import React from "react";
import { DarkTheme, BaseProvider } from "baseui";
import { UserProvider } from "./Context";
import Routes from "./routes";

const engine = new Styletron();

const App = () => {
  return (
    <UserProvider>
      <StyletronProvider value={engine}>
        <BaseProvider theme={DarkTheme} zIndex={9}>
          <Routes />
        </BaseProvider>
      </StyletronProvider>
    </UserProvider>
  );
};

export default App;
