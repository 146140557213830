import React from "react";
import { Button } from "@bigbinary/neetoui";

const EachVehicle = ({ item, isAirport, onTabChange, onSubmit, formType, busOnly }) => {

  const vehicles = (type) => {
    if (type === 'ECONOMY')
      return 'Toyota Prius or Similar'
    else if (type === 'STANDARD MPV')
      return 'Volkswagen Sharan or Similar'
    else if (type === 'BUSINESS')
      return 'Mercedes Benz E Class or Similar'
    else if (type === 'LUXURY MPV')
      return 'Mercedes Benz V Class or Similar'
    else if (type === 'FIRST')
      return 'Mercedes Benz S Class or Similar'
    else if (type === '8 SEATER MINI-VAN')
      return 'Mercedes Benz Vito or Similar'
    else if (type === 'STANDARD MINIBUS' || 'LUXURY MINIBUS' || 'LUXURY COACHES')
      return 'or Similar'
  }
  return (
    <div className="w-full py-7 lg:py-10 border-b border-gray-600">
      <div className="flex flex-col items-center justify-center">
        <div className="text-lg font-semibold">{item.vehicle_cat_dt.name}</div>
        <div className="py-4">
          <img src={item.vehicle_cat_dt.image} width="200px" height="200px" />
        </div>
        <div className="vehicle-card__stats">
          <p>{vehicles(item.vehicle_cat_dt.name)}</p>
          <div className="vehicle-card__row">
            <div className="vehicle-card__item">
              <i className="ri-user-fill"></i>
              {item.vehicle_cat_dt.passenger_no}
            </div>
            <div className="vehicle-card__item">
              <i className="ri-luggage-cart-line"></i>
              {item.vehicle_cat_dt.luggage_no}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-center px-10 py-2 space-y-3">
        {isAirport && formType !== 'hourly' && !busOnly && (
          <div className="flex items-center space-x-2">
            <i className="px-1 text-base font-semibold text-green-500 border rounded-full ri-check-line"></i>
            <span>Free 60 mins wait time</span>
          </div>
        )}
        {!isAirport && formType !== 'hourly' && !busOnly && (
          <div className="flex items-center space-x-2">
            <i className="px-1 text-base font-semibold text-green-500 border rounded-full ri-check-line"></i>
            <span>Free 15 mins wait time</span>
          </div>
        )}
        {!busOnly &&
          <div className="flex items-center space-x-2">
            <i className="px-1 text-base font-semibold text-green-500 border rounded-full ri-check-line"></i>
            <span>{formType === 'hourly' ? "Free cancellation before 24 hours" : "Free cancellation"}</span>
          </div>
        }
        <div className="flex items-center space-x-2">
          <i className="px-1 text-base font-semibold text-green-500 border rounded-full ri-check-line"></i>
          <span>Meet and Greet</span>
        </div>
        {item?.vehicle_cat_dt?.is_premium &&
          item.vehicle_cat_dt.passenger_no < 9 && (
            <div className="flex items-center space-x-2">
              <i className="px-1 text-base font-semibold text-green-500 border rounded-full ri-check-line"></i>
              <span>Professional chauffeur driven</span>
            </div>
          )}
        {!item?.vehicle_cat_dt?.is_premium &&
          item.vehicle_cat_dt.passenger_no < 9 && (
            <div className="flex items-center space-x-2">
              <i className="px-1 text-base font-semibold text-green-500 border rounded-full ri-check-line"></i>
              <span>Experienced driver</span>
            </div>
          )}
        {item.vehicle_cat_dt.passenger_no > 8 && (
          <div className="flex items-center space-x-2">
            <i className="px-1 text-base font-semibold text-green-500 border rounded-full ri-check-line"></i>
            <span>We are acting as an agent for this category <span className="text-[12px] block">*more details in service and terms pages</span></span>
          </div>
        )}
      </div>
      {!item?.vehicle_cat_dt?.is_quote && (
        <div className="flex flex-col justify-end px-12 py-4 space-y-4 text-right">
          <div className="space-x-2 text-lg font-semibold">
            <span className="px-2 py-1 text-black bg-white rounded-full">
              {String.fromCharCode(163)}
            </span>{" "}
            <span>{Math.round(item.fare * 100) / 100}</span>
          </div>
          <div className="opacity-80">All prices include VAT, fees & tolls</div>
        </div>
      )}
      <div className="flex justify-center vehicle-card__button">
        <div className="w-60">
          <Button
            label={item?.vehicle_cat_dt?.is_quote ? "Quote" : "Select"}
            fullWidth
            className="self-auto"
            onClick={() => onSubmit(item)}
          />
        </div>
      </div>
    </div>
  );
};

export default EachVehicle;
