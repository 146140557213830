import React, { useState, useEffect } from 'react'
import { BLOG } from "./constants";
import arrow from '../../assets/arrowr.svg'
import { useLocation, useHistory } from 'react-router-dom';
import ServicesApi from "../../api/passengerDetails";

const BlogListing = () => {
  const [data, setData] = useState([])
  const history = useHistory()
  // Access the location object using the useLocation hook
  const location = useLocation();

  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = async () => {
    try {
      const res = await ServicesApi.Blogs();
      setData(res);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  return (
    <div className="container-xl mx-auto px-[5%] py-[50px] mt-[40px]">
      <p className="text-[36px] w-[100%] border-b-[1px] border-b-white">Latest News</p>
      {data && data
        .slice(0, location.pathname === '/services' ? 4 : undefined)
        .map((blog) => {
          return (
            <>
              <div className="w-[100%] border-b-[1px] border-b-white text-[16px] flex flex-col-reverse md:flex-row justify-between py-[17px] " key={blog.id}>
                <div className="md:w-[70%] pt-[20px] md:pt-0">
                <p>{new Date(blog.create_date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>

                  <p className='text-[#FFD369] text-[24px]'>{blog.title}</p>
                  <p className='ellipsis h-[50px]'>{blog.content}</p>
                  <div
                    className="mt-[12px] flex items-center hover:cursor-pointer"
                    onClick={() => {
                      // Blog redirection
                      history.push(`/blog/${blog.id}`)
                    }}
                  >
                    <span className="text-[#FFD369] ">Find out more</span>
                    <img src={arrow} alt="" className="h-[16px] ml-[10px]" />
                  </div>
                </div>
                <div className="w-[100%] md:w-[268px] h-[240px]  mx-auto md:mx-0 flex justify-end">
                  <img src={blog.image_url} alt="blog picture" className="h-[100%]  object-cover" />
                </div>
              </div>
            </>
          )
        })}

    </div>
  )
}

export default BlogListing