import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";

const Privacy = () => {
  return (
    <div className="flex flex-col  inner-page text-white bg-black ">
      <Navbar />
      <div className="flex flex-col items-center justify-center px-4 overflow-auto">
        <div className="px-2 md:pt-4 space-x-2 justify-evenly md:w-3/5 self-center">
          <div>
            <h3 className="text-center font-bold">REFUND POLICY</h3>
            <h3>BOOKING CANCELLATION CHARGES</h3>
            <div className="p-2 content-padding-left">
              <ul className="list-outside">
                <li className="p-2 text-justify">
                  Cancellation Notice Periods and Fees are as follows:
                </li>
              </ul>
              <ul>
                <li className="p-2 text-justify">
                  FREE Cancellation If, Cancellation Made More Than 24 Hours
                  Before The Transfer Time.
                </li>
                <li className="p-2 text-justify">
                  We Will Charge 10% of Administration Fee For The Refund As
                  This Processing (Interchange) Fee, Charged By Merchant
                  Account.
                </li>
                <li className="p-2 text-justify">
                  50% Cancellation Charge If Cancellation Made between 12 hours
                  and 24 Hours Before Pick Up Time.
                </li>
                <li className="p-2 text-justify">
                  100% Cancellation Charge If Cancellation Made Within 12 Hours
                  Of Pick Up Time.
                </li>
                <li className="p-2 text-justify">
                  REFUNDS Could Take Up To 15 Working Days To Process.
                </li>
                <li className="p-2 text-justify">
                  <i>
                    IF YOU DO NOT RECEIVE AN EMAIL FROM US CONFIRMING THE
                    CANCELLATION ,THEN WE HAVE NOT AWARE OF THE CANCELLATION ,in
                    this case please give us a call on our out of hours number
                    which is{" "}
                    <a href="tel:07884488717">
                      07884488717
                    </a>{" "}
                    as soon as possible.
                  </i>
                </li>
              </ul>
            </div>
            <br />
            <h3>NO-SHOW CHARGES</h3>
            <div className="p-2 content-padding-left">
              <ul className="list-outside">
                <li className="p-2 text-justify">
                  If Customer Does Not Show Up For a Ride, We will Apply the
                  Booking As No-Show and Will Charge the Full Amount of booking.
                </li>
              </ul>
              <ul>
                <li className="p-2 text-justify">
                  Waiting Times and Pickup Rules Are Listed Above Of These Terms
                  and Conditions.
                </li>
                <li className="p-2 text-justify">
                  Fares inclusive all taxes and toll & congestion charge & meet
                  and greet & car parking
                </li>
                <li className="p-2 text-justify">
                  Any amendment must be made via an email or by telephone to us
                  to which you will receive an email confirming the amendment.
                </li>
                <li className="p-2 text-justify">
                  amendments must not be made with your driver.
                </li>
                <li className="p-2 text-justify">
                  All bookings must be made through our website or telephone or
                  email. In this way confirmations are sent out and the journey
                  is insured.
                </li>
                <li className="p-2 text-justify">
                  Any complaints regarding service should be raised in writing
                  to our office, preferably by reply to your confirmation email
                  .All complaints must be submitted within 30 days of the event
                  giving rise to the event.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
