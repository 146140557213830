import React, { useEffect, useState } from "react";
import { Select, TimePicker, Button } from "@bigbinary/neetoui";
import { Input as FormikInput } from "@bigbinary/neetoui/formik";
import { Field, Form, Formik } from "formik";
import * as dayjs from "dayjs";
import ServicesApi from "../../../../api/passengerDetails";
import { FLIGHT_SCHEMA } from "../../../../Utils/formikValidation";
import classNames from "classnames";

const colourStyles = {
  indicatorsContainer: (base, state) => ({
    background: "#393e46",
  }),
  control: (base, state) => ({
    ...base,
    background: "#393e46",
  }),
};

const FlightForm = ({ modify, state, onTabChange }) => {
  const [bufferTimeList, setBufferTimeList] = useState([]);
  const intialState = {
    flightNumber: "",
    arrivalTime: "",
    pickupText: bufferTimeList[0],
    // pickUpTime: new Date(),
    nameBoard: "",
  };
  const onSubmit = () => {};

  const fetchBufferTime = async () => {
    try {
      const List = await ServicesApi.BuffertimeApi();
      const transformedList = List.map((item) => {
        return {
          label: item.buffer_time_str,
          value: item.buffer_time_mins,
          id: item._id,
        };
      });
      setBufferTimeList(transformedList);
    } catch (error) {
      console.debug("api error", error);
    }
  };

  useEffect(() => {
    fetchBufferTime();
  }, []);

  const calculateTime = (values) => {
    let updatedTime = dayjs(values.arrivalTime);
    if (values?.pickupText?.value) {
      updatedTime = updatedTime.add(values.pickupText.value, "minute");
      return updatedTime.format("HH:mm");
    } else {
      if (updatedTime.format("HH:mm") !== "Invalid Date")
        return updatedTime.format("HH:mm");
      else {
        return null;
      }
    }
  };

  return (
    <div className="w-full px-6 pt-4 pb-8 border-b border-gray-600 md:border-0">
      <Formik
        initialValues={intialState}
        onSubmit={onSubmit}
        validationSchema={FLIGHT_SCHEMA}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="flex flex-col justify-center space-y-10 md:w-96">
                <FormikInput
                  name="flightNumber"
                  label="Flight Number"
                  required
                  placeholder="Flight Number"
                  // prefix={<i className="ri-map-pin-line"></i>}
                  // suffix={<i className="ri-focus-3-line"></i>}
                  className="w-full"
                />
                <div className="w-1/2">
                  <Field name="arrivalTime">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }) => (
                      <div>
                        <TimePicker
                          label="Flight Arrival Time"
                          className="text-white bg-transparent "
                          placeholder="Date"
                          {...field}
                          onSelect={(e) => {
                            setFieldValue("arrivalTime", e);
                          }}
                        />
                        {meta.touched && meta.error && (
                          <p
                            className={classNames([
                              "text-red-500 text-xs pt-2",
                            ])}
                          >
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>

                <Field name="pickupText">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <div>
                      <Select
                        {...field}
                        styles={colourStyles}
                        size="small"
                        label="When should your driver pick you up
                      from the airport?*"
                        options={bufferTimeList}
                        onChange={(e) => {
                          setFieldValue("pickupText", e);
                        }}
                      />
                      {meta.touched && meta.error && (
                        <p
                          className={classNames(["text-red-500 text-xs pt-2"])}
                        >
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>

                <div className="w-1/2">
                  Updated pickup time:
                  {calculateTime(values)}
                </div>
                <FormikInput
                  name="nameBoard"
                  label="Name on the Board"
                  placeholder="Pickup Location"
                  prefix={<i className="ri-map-pin-line"></i>}
                  // suffix={<i className="ri-focus-3-line"></i>}
                  className="w-full"
                />
              </div>
              <div className="flex px-2 py-4 my-4 text-xs border border-gray-200">
                <div className="pr-4">
                  <i className="ri-time-line"></i>
                </div>
                <div>
                  We will adjust your pickup time to your actual landing time
                  plus the additional time you have selected ( 1 hour 15 minutes
                  after landing ) so that we can pick you on time even if your
                  flight is delayed.
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <div className="flex justify-center w-full">
        <div className="flex justify-between w-full py-4 ">
          {state === "flight" && (
            <div className="w-52">
              <Button
                label="Skip"
                fullWidth
                size="large"
                onClick={() => onTabChange("passenger")}
              />
            </div>
          )}
          <div className="w-52">
            <Button
              label="Continue"
              fullWidth
              size="large"
              onClick={() => onTabChange("passenger")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightForm;
