import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";

const Privacy = () => {
  return (
    <div className="flex flex-col  inner-page text-white bg-black ">
      <Navbar />
      <div className="flex flex-col items-center justify-center px-4 overflow-auto">
        <div className="px-2 pt-[100px] md:pt-4 space-x-2 justify-evenly md:w-3/5 self-center">
          <div>
            <h3 className="text-center font-bold">PRIVACY POLICY</h3>
            <br />
            <p className="py-2">
              This privacy policy applies between you, the User of this Website,
              and SS Carriage Ltd, the owner and provider of this Website. SS
              Carriage Ltd takes the privacy of your information very seriously.
              This privacy policy applies to our use of any and all Data
              collected by us or provided by you in relation to your use of the
              Website.
            </p>
            <div className="flex flex-wrap flex-row">
              <p>
                This privacy policy should be read alongside, and in addition
                to, our Terms and Conditions, which can be found at:&nbsp;
              </p>
              <a href="https://ridewing.co.uk/privacy-policy">
                Privacy Policy
              </a>
            </div>
            <br />
            <h3>Please read this privacy policy carefully.</h3>
            <br />
            <h3>Definitions and interpretation</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li className="text-justify p-2">
                  In this privacy policy, the following definitions are used:
                  <div className="p-4">
                    <ol className="list-alphabetic list-outside">
                      <li className="text-justify p-2">
                        <b>Data</b> means Collectively all information that you
                        submit to SS Carriage Ltd via the Website. This
                        definition incorporates, where applicable, the
                        definitions provided in the Data Protection Laws.
                      </li>
                      <li className="text-justify p-2">
                        <b>DataService</b> means the &nbsp;
                        <a href="https://www.ridewing.co.uk">
                            www.ridewing.co.uk
                        </a>{" "}
                        website operated by SS Carriages Limited.
                      </li>
                      <li className="text-justify p-2">
                        <b>Personal Data</b> means data about a living
                        individual who can be identified from those data (or
                        from those and other information either in our
                        possession or likely to come into our possession).
                      </li>
                      <li className="text-justify p-2">
                        <b>Usage Data</b> is data collected automatically either
                        generated by the use of Service or from Service
                        infrastructure itself (for example, the duration of a
                        page visit).
                      </li>
                      <li className="text-justify p-2">
                        <b>Cookies</b> are small files stored on your device
                        (computer or mobile device).
                      </li>
                      <li className="text-justify p-2">
                        <b>Data Controller</b> means a natural or legal person
                        who (either alone or jointly or in common with other
                        persons) determines the purposes for which and the
                        manner in which any personal data are, or are to be,
                        processed. For the purpose of this Privacy Policy, we
                        are a Data Controller of your data.
                      </li>
                      <li className="text-justify p-2">
                        <b>Data Processors (Or Service Providers)</b> mean any
                        natural or legal person who processes the data on behalf
                        of the Data Controller. We may use the services of
                        various Service Providers in order to process your data
                        more effectively.
                      </li>
                      <li className="text-justify p-2">
                        <b>Data Subject</b> is any living individual who is the
                        subject of Personal Data.
                      </li>
                      <li className="text-justify p-2">
                        <b>The User</b> is the individual using our Service. The
                        User corresponds to the Data Subject, who is the subject
                        of Personal Data.
                      </li>
                      <li className="text-justify p-2">
                        <b>Website</b> - The website that you are currently
                        using,&nbsp;
                        <a href="https://www.ridewing.co.uk">
                            www.ridewing.co.uk
                        </a>
                        , and any sub-domains of this site unless expressly
                        excluded by their own terms and conditions.
                      </li>
                    </ol>
                  </div>
                </li>
                <li className="pt-2">
                  In this privacy policy, unless the context requires a
                  different interpretation:
                  <div className="p-4">
                    <ol className="list-alphabetic list-outside">
                      <li className="text-justify p-2">
                        the singular includes the plural and vice versa.
                      </li>
                      <li className="text-justify p-2">
                        references to sub-clauses, clauses, schedules or
                        appendices are to sub-clauses, clauses, schedules or
                        appendices of this privacy policy.
                      </li>
                      <li className="text-justify p-2">
                        a reference to a person includes firms, companies,
                        government entities, trusts and partnerships.
                      </li>
                      <li className="text-justify p-2">
                        "including" is understood to mean "including without
                        limitation".
                      </li>
                      <li className="text-justify p-2">
                        reference to any statutory provision includes any
                        modification or amendment of it.
                      </li>
                    </ol>
                    <p>
                      the headings and subheadings do not form part of this
                      privacy policy.
                    </p>
                  </div>
                </li>
              </ol>
            </div>
            <h3>Scope of this privacy policy</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={3} className="text-justify p-2">
                  This privacy policy applies only to the actions of SS Carriage
                  Ltd and Users with respect to this Website. It does not extend
                  to any websites that can be accessed from this Website
                  including, but not limited to, any links we may provide to
                  social media websites.
                </li>
                <li className="text-justify p-2">
                  For purposes of the applicable Data Protection Laws, SS
                  Carriage Ltd is the "data controller". This means that SS
                  Carriage Ltd determines the purposes for which, and the manner
                  in which, your Data is processed.
                </li>
              </ol>
            </div>
            <br />
            <h3>Data collected</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={5} className="text-justify p-2">
                  Personal Data
                  <ul className="list-outside">
                    <li className="text-justify p-2">
                      While using our Service, we may ask you to provide us with
                      certain personally identifiable information that can be
                      used to contact or identify you (“Personal Data”).
                      Personally identifiable information may include, but is
                      not limited to:
                    </li>
                    <li className="text-justify p-2">
                      <ol className="list-alphabetic list-inside">
                        <li className="text-justify p-2">Email address</li>
                        <li className="text-justify p-2">
                          First name and last name
                        </li>
                        <li className="text-justify p-2">Phone number</li>
                        <li className="text-justify p-2">
                          Address, Country, State, Province, ZIP/Postal code,
                          City
                        </li>
                        <li className="text-justify p-2">
                          Cookies and Usage Data
                        </li>
                      </ol>
                    </li>
                  </ul>
                  <p>
                    We may use your Personal Data to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link.
                  </p>
                  <p>Location data</p>
                  <p>
                    We may use and store information about your location if you
                    give us permission to do so (“Location Data”). We use this
                    data to provide features of our Service, to improve and
                    customize our Service.
                  </p>
                  <p>
                    You can enable or disable location services when you use our
                    Service at any time by way of your device settings.
                  </p>
                </li>
              </ol>
            </div>
            <h3>How we collect data</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={6} className="text-justify p-2">
                  We collect Data in the following ways:
                  <ol className="list-alphabetic list-outside content-padding-left">
                    <li className="text-justify p-2">
                      data is given to us by you.
                    </li>
                    <li className="text-justify p-2">
                      data is collected automatically.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <h3>Data that is given to us by you</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={7} className="text-justify p-2">
                  SS Carriage Ltd will collect your Data in a number of ways,
                  for example:
                  <ol className="list-alphabetic list-outside content-padding-left">
                    <li className="text-justify p-2">
                      when you contact us through the Website, by telephone,
                      post, e-mail or through any other means;
                    </li>
                    <li className="text-justify p-2">
                      when you register with us and set up an account to receive
                      our products/services;in each case, in accordance with
                      this privacy policy.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <h3>Usage data</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={8} className="text-justify p-2">
                  We may also collect information that your browser sends
                  whenever you visit our Service or when you access Service by
                  or through any device (“Usage Data”).
                </li>
              </ol>
              <ul className="list-outside">
                <li className="text-justify p-2">
                  This Usage Data may include information such as your
                  computer’s Internet Protocol address (e.g., IP address),
                  browser type, browser version, the pages of our Service that
                  you visit, the time and date of your visit, the time spent on
                  those pages, unique device identifiers and other diagnostic
                  data.
                </li>
                <li className="text-justify p-2">
                  When you access Service with a device, this Usage Data may
                  include information such as the type of device you use, your
                  device unique ID, the IP address of your device, your device
                  operating system, the type of Internet browser you use, unique
                  device identifiers and other diagnostic data.
                </li>
              </ul>
            </div>
            <h3>Tracking Cookie Data</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={9} className="text-justify p-2">
                  We use cookies and similar tracking technologies to track the
                  activity on our Service and we hold certain information.
                </li>
                <ul>
                  <li className="text-justify p-2">
                    Cookies are files with a small amount of data which may
                    include an anonymous unique identifier. Cookies are sent to
                    your browser from a website and stored on your device. Other
                    tracking technologies are also used such as beacons, tags
                    and scripts to collect and track information and to improve
                    and analyze our Service.
                  </li>
                  <li className="text-justify p-2">
                    You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to use some portions of
                    our Service.
                  </li>
                  <li className="text-justify p-2">
                    <b>Examples of Cookies we use</b>
                    <ol className="list-alphabetic list-outside content-padding-left">
                      <li className="text-justify p-2">
                        Session Cookies: We use Session Cookies to operate our
                        Service.
                      </li>
                      <li className="text-justify p-2">
                        Preference Cookies: We use Preference Cookies to
                        remember your preferences and various settings.
                      </li>
                      <li className="text-justify p-2">
                        Security Cookies: We use Security Cookies for security
                        purposes.
                      </li>
                      <li className="text-justify p-2">
                        Advertising Cookies: Advertising Cookies are used to
                        serve you with advertisements that may be relevant to
                        you and your interests.
                      </li>
                      <li className="text-justify p-2">
                        Cookies and Usage Data
                      </li>
                    </ol>
                  </li>
                </ul>
              </ol>
            </div>
            <h3>Other Data</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={10} className="text-justify p-2">
                  While using our Service, we may also collect the following
                  information: sex, age, date of birth, place of birth, passport
                  details, citizenship, registration at place of residence and
                  actual address, telephone number (work, mobile), details of
                  documents on education, qualification, professional training,
                  employment agreements, NDA agreements, information on bonuses
                  and compensation, information on marital status, family
                  members, social security (or other taxpayer identification)
                  number, office location and other data.
                </li>
              </ol>
            </div>
            <h3>Uses of our data</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={11} className="text-justify p-2">
                  SS Carriages Limited uses the collected data for various
                  purposes:
                  <ol className="list-alphabetic list-outside content-padding-left">
                    <li className="text-justify p-2">
                      to provide and maintain our Service;
                    </li>
                    <li className="text-justify p-2">
                      to notify you about changes to our Service;
                    </li>
                    <li className="text-justify p-2">
                      to allow you to participate in interactive features of our
                      Service when you choose to do so;
                    </li>
                    <li className="text-justify p-2">
                      to provide customer support;
                    </li>
                    <li className="text-justify p-2">
                      to gather analysis or valuable information so that we can
                      improve our Service;
                    </li>

                    <li className="text-justify p-2">
                      to monitor the usage of our Service;
                    </li>
                    <li className="text-justify p-2">
                      to detect, prevent and address technical issues;
                    </li>
                    <li className="text-justify p-2">
                      to fulfil any other purpose for which you provide it;
                    </li>
                    <li className="text-justify p-2">
                      to carry out our obligations and enforce our rights
                      arising from any contracts entered into between you and
                      us, including for billing and collection;
                    </li>
                    <li className="text-justify p-2">
                      to provide you with notices about your account and/or
                      subscription, including expiration and renewal notices,
                      email-instructions, etc.;
                    </li>
                    <li className="text-justify p-2">
                      to provide you with news, special offers and general
                      information about other goods, services and events which
                      we offer that are similar to those that you have already
                      purchased or enquired about unless you have opted not to
                      receive such information;
                    </li>
                    <li className="text-justify p-2">
                      in any other way we may describe when you provide the
                      information;
                    </li>
                    <li className="text-justify p-2">
                      for any other purpose with your consent.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <h3>Who do we share Data with?</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={12} className="text-justify p-2">
                  We may share your Data with the following groups of people for
                  the following reasons:
                  <ol className="list-alphabetic list-outside content-padding-left">
                    <li className="text-justify p-2">
                      Customer’s name, contact number, email and location will
                      be visible to the driver and admin.
                    </li>
                    <li className="text-justify p-2">
                      Customer details will be shared once they confirm the
                      service, so that the drivers can contact them.
                    </li>
                    in each case, in accordance with this privacy policy.
                  </ol>
                </li>
              </ol>
            </div>
            <h3>Security of data</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={13} className="text-justify p-2">
                  The security of your data is important to us but remember that
                  no method of transmission over the Internet or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security
                </li>
              </ol>
            </div>
            <h3>Data retention</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={14} className="text-justify p-2">
                  We will retain your Personal Data only for as long as is
                  necessary for the purposes set out in this Privacy Policy. We
                  will retain and use your Personal Data to the extent necessary
                  to comply with our legal obligations (for example, if we are
                  required to retain your data to comply with applicable laws),
                  resolve disputes, and enforce our legal agreements and
                  policies.
                </li>
              </ol>
              <ul>
                <li className="text-justify p-2">
                  We will also retain Usage Data for internal analysis purposes.
                  Usage Data is generally retained for a shorter period, except
                  when this data is used to strengthen the security or to
                  improve the functionality of our Service, or we are legally
                  obligated to retain this data for longer time periods.
                </li>
              </ul>
            </div>
            <h3>Transfer of data</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={15} className="text-justify p-2">
                  Your information, including Personal Data, may be transferred
                  to – and maintained on – computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ from those of your
                  jurisdiction. If you are located outside UK and choose to
                  provide information to us, please note that we transfer the
                  data, including Personal Data, to UK and process it there.
                </li>
              </ol>
              <ul>
                <li className="text-justify p-2">
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </li>
                <li className="text-justify p-2">
                  SS Carriages Limited will take all the steps reasonably
                  necessary to ensure that your data is treated securely and in
                  accordance with this Privacy Policy and no transfer of your
                  Personal Data will take place to an organization or a country
                  unless there are adequate controls in place including the
                  security of your data and other personal information.
                </li>
              </ul>
            </div>
            <h3>Disclosure of data</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={16} className="text-justify p-2">
                  We may disclose personal information that we collect, or you
                  provide:
                  <ol className="list-alphabetic list-outside content-padding-left">
                    <li className="text-justify p-2">
                      Business Transaction: If we or our subsidiaries are
                      involved in a merger, acquisition or asset sale, your
                      Personal Data may be transferred.
                    </li>
                    <li className="text-justify p-2">
                      Other cases: We may disclose your information also:
                      <ol className="list-[upper-roman] list-outside content-padding-left">
                        <li className="text-justify p-2">
                          to our subsidiaries and affiliates;
                        </li>
                        <li className="text-justify p-2">
                          to contractors, service providers, and other third
                          parties we use to support our business;
                        </li>
                        <li className="text-justify p-2">
                          to fulfill the purpose for which you provide it;
                        </li>
                        <li className="text-justify p-2">
                          for the purpose of including your company’s logo on
                          our website;
                        </li>
                        <li className="text-justify p-2">
                          for any other purpose disclosed by us when you provide
                          the information;
                        </li>
                        <li className="text-justify p-2">
                          with your consent in any other cases;
                        </li>
                        <li className="text-justify p-2">
                          if we believe disclosure is necessary or appropriate
                          to protect the rights, property, or safety of the
                          Company, our customers, or others.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <h3>Your rights</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={17} className="text-justify p-2">
                  You have the following rights in relation to your Data:
                  <ol className="list-alphabetic list-outside content-padding-left">
                    <li className="text-justify p-2">
                      <b>Right to access</b> - the right to request (i) copies
                      of the information we hold about you at any time, or (ii)
                      that we modify, update or delete such information. If we
                      provide you with access to the information we hold about
                      you, we will not charge you for this, unless your request
                      is "manifestly unfounded or excessive." Where we are
                      legally permitted to do so, we may refuse your request. If
                      we refuse your request, we will tell you the reasons why.
                    </li>
                    <li className="text-justify p-2">
                      <b>Right to correct</b> - the right to have your Data
                      rectified if it is inaccurate or incomplete.
                    </li>
                    <li className="text-justify p-2">
                      <b>Right to erase</b> - the right to request that we
                      delete or remove your Data from our systems.
                    </li>
                    <li className="text-justify p-2">
                      <b>Right to restrict our use of your Data</b> - the right
                      to "block" us from using your Data or limit the way in
                      which we can use it.
                    </li>
                    <li className="text-justify p-2">
                      <b>Right to data portability</b> - the right to request
                      that we move, copy or transfer your Data.
                    </li>
                    <li className="text-justify p-2">
                      <b>Right to erase</b> - the right to object to our use of
                      your Data including where we use it for our legitimate
                      interests.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={18} className="text-justify p-2">
                  To make enquiries, exercise any of your rights set out above,
                  or withdraw your consent to the processing of your Data (where
                  consent is our legal basis for processing your Data), please
                  contact us via this e-mail address:
                  <a href="mailto:concierge@ridewing.co.uk">
                      concierge@ridewing.co.uk
                  </a>
                  If you are not satisfied with the way a complaint you make in
                  relation to your Data is handled by us, you may be able to
                  refer your complaint to the relevant data protection
                  authority. For the UK, this is the Information Commissioner's
                  Office (ICO). The ICO's contact details can be found on their
                  website at{" "}
                  <a href="https://ico.org.uk/ ">
                    https://ico.org.uk/
                  </a>
                </li>
              </ol>
            </div>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={19} className="text-justify p-2">
                  It is important that the Data we hold about you is accurate
                  and current. Please keep us informed if your Data changes
                  during the period for which we hold it.
                </li>
              </ol>
            </div>
            <h3>
              Your data protection rights under the California Privacy
              Protection Act (CalOPPA)
            </h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={20} className="text-justify p-2">
                  CalOPPA is the first state law in the nation to require
                  commercial websites and online services to post a privacy
                  policy. The law’s reach stretches well beyond California to
                  require a person or company in the United States (and
                  conceivable the world) that operates websites collecting
                  personally identifiable information from California consumers
                  to post a conspicuous privacy policy on its website stating
                  exactly the information being collected and those individuals
                  with whom it is being shared, and to comply with this policy.
                  According to CalOPPA we agree to the following:
                  <ol className="list-alphabetic list-outside content-padding-left">
                    <li className="text-justify p-2">
                      users can visit our site anonymously;
                    </li>
                    <li className="text-justify p-2">
                      our Privacy Policy link includes the word “Privacy”, and
                      can easily be found on the homepage of our website;
                    </li>
                    <li className="text-justify p-2">
                      users will be notified of any privacy policy changes on
                      our Privacy Policy Page;
                    </li>
                    <li className="text-justify p-2">
                      users are able to change their personal information by
                      emailing us at{" "}
                      <a href="mailto:concierge@ridewing.co.uk">
                          concierge@ridewing.co.uk
                      </a>.
                    </li>
                  </ol>
                </li>
              </ol>
              <ul className="list-outside">
                <li className="text-justify p-2">
                  Our Policy on “Do Not Track” Signals: We honor Do Not Track
                  signals and do not track, plant cookies, or use advertising
                  when a Do Not Track browser mechanism is in place. Do Not
                  Track is a preference you can set in your web browser to
                  inform websites that you do not want to be tracked.
                </li>
                <li className="text-justify p-2">
                  You can enable or disable Do Not Track by visiting the
                  Preferences or Settings page of your web browser.
                </li>
              </ul>
            </div>
            <h3>
              Your data protection rights under the California Consumer Privacy
              Act (CCPA)
            </h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={21} className="text-justify p-2">
                  If you are a California resident, you are entitled to learn
                  what data we collect about you, ask to delete your data and
                  not to sell (share) it. To exercise your data protection
                  rights, you can make certain requests and ask us,
                  <ol className="list-alphabetic list-outside content-padding-left">
                    <li className="text-justify p-2">
                      What personal information we have about you. If you make
                      this request, we will return to you:
                      <ol className="list-[upper-roman] list-outside content-padding-left">
                        <li className="text-justify p-2">
                          The categories of personal information we have
                          collected about you.
                        </li>
                        <li className="text-justify p-2">
                          The categories of sources from which we collect your
                          personal information.
                        </li>
                        <li className="text-justify p-2">
                          The business or commercial purpose for collecting or
                          selling your personal information.
                        </li>
                        <li className="text-justify p-2">
                          The categories of third parties with whom we share
                          personal information.
                        </li>
                        <li className="text-justify p-2">
                          The specific pieces of personal information we have
                          collected about you.
                        </li>
                        <li className="text-justify p-2">
                          A list of categories of personal information that we
                          have sold, along with the category of any other
                          company we sold it to. If we have not sold your
                          personal information, we will inform you of that fact.
                        </li>
                        <li className="text-justify p-2">
                          A list of categories of personal information that we
                          have disclosed for a business purpose, along with the
                          category of any other company we shared it with.
                        </li>
                      </ol>
                      Please note, you are entitled to ask us to provide you
                      with this information up to two times in a rolling
                      twelve-month period. When you make this request, the
                      information provided may be limited to the personal
                      information we collected about you in the previous 12
                      months.
                    </li>
                    <li className="text-justify p-2">
                      To delete your personal information. If you make this
                      request, we will delete the personal information we hold
                      about you as of the date of your request from our records
                      and direct any service providers to do the same. In some
                      cases, deletion may be accomplished through
                      de-identification of the information. If you choose to
                      delete your personal information, you may not be able to
                      use certain functions that require your personal
                      information to operate.
                    </li>
                    <li className="text-justify p-2">
                      To stop selling your personal information. We don’t sell
                      or rent your personal information to any third parties for
                      any purpose. We do not sell your personal information for
                      monetary consideration. However, under some circumstances,
                      a transfer of personal information to a third party, or
                      within our family of companies, without monetary
                      consideration may be considered a “sale” under California
                      law. You are the only owner of your Personal Data and can
                      request disclosure or deletion at any time. If you submit
                      a request to stop selling your personal information, we
                      will stop making such transfers.
                    </li>
                  </ol>
                </li>
              </ol>
              <ul className="list-outside">
                <li className="text-justify p-2">
                  Please note, if you ask us to delete or stop selling your
                  data, it may impact your experience with us, and you may not
                  be able to participate in certain programs or membership
                  services which require the usage of your personal information
                  to function. But in no circumstances, we will discriminate
                  against you for exercising your rights. To exercise your
                  California data protection rights described above, please send
                  your request(s) by email:{" "}
                  <a href="mailto:concierge@ridewing.co.uk">
                      concierge@ridewing.co.uk
                  </a>
                </li>
                <li className="text-justify p-2">
                  Your data protection rights, described above, are covered by
                  the CCPA, short for the California Consumer Privacy Act. To
                  find out more, visit the official California Legislative
                  Information website. The CCPA took effect on 01/01/2020.
                </li>
              </ul>
            </div>
            <h3>Service Providers</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={22} key={22} className="text-justify p-2">
                  We may employ third party companies and individuals to
                  facilitate our Service (“Service Providers”), provide Service
                  on our behalf, perform Service-related services or assist us
                  in analyzing how our Service is used. These third parties have
                  access to your Personal Data only to perform these tasks on
                  our behalf and are obligated not to disclose or use it for any
                  other purpose.
                </li>
              </ol>
            </div>
            <h3>Analytics</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={23} key={23} className="text-justify p-2">
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </li>
              </ol>
            </div>
            <h3>CI/CD Tools</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={24} key={24} className="text-justify p-2">
                  We may use third-party Service Providers to automate the
                  development process of our Service.
                </li>
              </ol>
            </div>
            <h3>Behavioral Remarketing</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={25} key={25} className="text-justify p-2">
                  We may use remarketing services to advertise on third party
                  websites to you after you visited our Service. We and our
                  third-party vendors use cookies to inform, optimize and serve
                  ads based on your past visits to our Service.
                </li>
              </ol>
            </div>
            <h3>Payments</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={26} key={26} className="text-justify p-2">
                  We may provide paid products and/or services within Service.
                  In that case, we use third-party services for payment
                  processing (e.g. payment processors). We will not store or
                  collect your payment card details. That information is
                  provided directly to our third-party payment processors whose
                  use of your personal information is governed by their Privacy
                  Policy. These payment processors adhere to the standards set
                  by PCI-DSS as managed by the PCI Security Standards Council,
                  which is a joint effort of brands like Visa, Mastercard,
                  American Express and Discover. PCI-DSS requirements help
                  ensure the secure handling of payment information.
                </li>
              </ol>
            </div>
            <h3>Links to other websites</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={27} key={27} className="text-justify p-2">
                  Our Service may contain links to other sites that are not
                  operated by us. If you click a third-party link, you will be
                  directed to that third party’s site. We strongly advise you to
                  review the Privacy Policy of every site you visit. We have no
                  control over and assume no responsibility for the content,
                  privacy policies or practices of any third-party sites or
                  services. For example, the outlined privacy policy has been
                  made using PolicyMaker.io, a free tool that helps create
                  high-quality legal documents. Policymaker’s privacy policy
                  generator is an easy-to-use tool for creating a privacy policy
                  for blog, website, e-commerce store or mobile app.
                </li>
              </ol>
            </div>
            <h3>Changes of business ownership and control</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={29} key={29} className="text-justify p-2">
                  SS Carriage Ltd may, from time to time, expand or reduce our
                  business and this may involve the sale and/or the transfer of
                  control of all or part of SS Carriage Ltd. Data provided by
                  Users will, where it is relevant to any part of our business
                  so transferred, be transferred along with that part and the
                  new owner or newly controlling party will, under the terms of
                  this privacy policy, be permitted to use the Data for the
                  purposes for which it was originally supplied to us.
                </li>
              </ol>
            </div>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={30} key={30} className="text-justify p-2">
                  We may also disclose Data to a prospective purchaser of our
                  business or any part of it.
                </li>
              </ol>
            </div>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={31} key={31} className="text-justify p-2">
                  In the above instances, we will take steps with the aim of
                  ensuring your privacy is protected.
                </li>
              </ol>
            </div>
            <h3>General</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={32} key={32} className="text-justify p-2">
                  You may not transfer any of your rights under this privacy
                  policy to any other person. We may transfer our rights under
                  this privacy policy where we reasonably believe your rights
                  will not be affected.
                </li>
              </ol>
            </div>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={33} key={33} className="text-justify p-2">
                  If any court or competent authority finds that any provision
                  of this privacy policy (or part of any provision) is invalid,
                  illegal or unenforceable, that provision or part-provision
                  will, to the extent required, be deemed to be deleted, and the
                  validity and enforceability of the other provisions of this
                  privacy policy will not be affected.
                </li>
              </ol>
            </div>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={34} key={34} className="text-justify p-2">
                  Unless otherwise agreed, no delay, act or omission by a party
                  in exercising any right or remedy will be deemed a waiver of
                  that, or any other, right or remedy.
                </li>
              </ol>
            </div>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={35} key={35} className="text-justify p-2">
                  This Agreement will be governed by and interpreted according
                  to the law of England and Wales. All disputes arising under
                  the Agreement will be subject to the exclusive jurisdiction of
                  the English and Welsh courts.
                </li>
              </ol>
            </div>
            <h3>Changes to this privacy policy</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-decimal list-outside">
                <li value={36} key={36} className="text-justify p-2">
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page. We will let you know via email and/or a prominent
                  notice on our Service, prior to the change becoming effective
                  and update “effective date” at the top of this Privacy Policy.
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </li>
              </ol>
            </div>
            <h3>Contact Us </h3>
            <div className="p-2 content-padding-left">
              <ul className="list-outside">
                <li className="text-justify p-2">
                  If you have any questions about this Privacy Policy, please
                  contact us by email:{" "}
                  <a href="mailto:concierge@ridewing.co.uk">
                      concierge@ridewing.co.uk{" "}
                  </a>
                  or Call Us:{" "}
                  <a href="tel:02084324325">
                    02084324325
                  </a>
                  This privacy policy was created on 1/05/2022
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
