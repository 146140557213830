import * as yup from "yup";
import * as dayjs from "dayjs";

// source: "",
// destination: "",
// stops: [],
// returnBooking: false,
// date: new Date(),
// time: new Date(),
// returnDate: new Date(),
// returnTime: new Date(),

export const PICKUP_SCHEMA = yup.object({
  source: yup.object().required("Pickup location is required"),
  destination: yup.object().required("Drop off location is required"),
  stops: yup.array().of(yup.object().required("Stops location is required")),
  // date: yup
  //   .object()
  //   .test("test", "Enter a valid date", (value) =>
  //     dayjs(value).isAfter(dayjs().subtract(1, "day"))
  //   )
  //   .required("Date is required"),
  // time: yup.object().required("Time is required"),
  // returnDate: yup
  //   .object()
  //   .when("returnBooking", {
  //     is: (aCheckbox) => aCheckbox === true,
  //     then: yup.object().required("Return date is required").nullable(),
  //   })
  //   .nullable(),
  // returnTime: yup
  //   .object()
  //   .when("returnBooking", {
  //     is: (aCheckbox) => aCheckbox === true,
  //     then: yup.object().required("Return time is required").nullable(),
  //   })
  //   .nullable(),
  //   .boolean()
  //   .when(true, yup.object().required("Time is required")),
  //   street_number: yup.string().required("Address Line 1 is required"),
  //   area: yup.string(),
  //   city: yup.string().required("City is required"),
  //   zip_code: yup
  //     .string()
  //     .test("len", "Please enter valid US zip code.", (val) => val.length === 5)
  //     .required("Zip code is required"),
  //   state: yup.string().required("State is required"),
  //   phone_number: yup.string().required("Phone number is required"),
});

export const HOURLY_SCHEMA = yup.object({
  source: yup.object().required("Pickup location is required"),
});

// flightNumber: "CDX 112233",
// arrivalTime: new Date(),
// pickupText: { label: "1 hour 15 minutes after landing", value: "1" },
// pickUpTime: new Date(),
// nameBoard: "John Mack",

export const FLIGHT_SCHEMA = yup.object({
  flightNumber: yup.string().required("Flight Number is required"),
  arrivalTime: yup.object().required("Arrival time is required").nullable(),
  pickupText: yup.object().required("Pickup Date is required"),
  nameBoard: yup.string().required("Name on board is required"),
});

export const CONTACTUS_SCHEMA = yup.object({
  flightNumber: yup.string().required("Flight Number is required"),
  arrivalTime: yup.object().required("Arrival time is required").nullable(),
  pickupText: yup.object().required("Pickup Date is required"),
  nameBoard: yup.string().required("Name on board is required"),
});

export const PASSENGER_SCHEMA = yup.object({
  passengerName: yup.string().required("Passenger name is required"),
  passengerEmail: yup
    .string()
    .email()
    .required("Passenger Email is required")
    .nullable(),
  phoneNumber: yup
    .object()
    .shape({
      id: yup.object().required("Required"),
      number: yup.number().required("Required"),
    })
    .required("Phone Number is required"),
  passengerCount: yup.object().required("Passenger Count is required"),
  luggageCount: yup.object().required("Luggage Count is required"),
  childCount: yup.object().required("Child Count is required"),
  childWeight: yup.array().when("Child Count", (value) => {
    if (value > 0) return yup.array().required("Child Count is required");
  }),
  isSomeOneElse: yup.boolean().required("Is SomeOne Else is required"),
  someoneElseEmail: yup.string().when("isSomeOneElse", {
    is: true,
    then: yup.string().required("Email address is required"),
  }),
  someoneElsePhone: yup.object().when("isSomeOneElse", {
    is: true,
    then: yup.object().required("Phone number is required"),
  }),
  isVat: yup.boolean().required("is vat is required"),
  vatNumber: yup.string().when("isVat", {
    is: true,
    then: yup.string().required("VAT number is required"),
  }),
  vatAddress: yup.string().when("isVat", {
    is: true,
    then: yup.string().required("VAT address is required"),
  }),
});
