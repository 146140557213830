import React, { useEffect, useState } from "react";
// import { MAPDETAILS } from "../Vehicle/constants";
import { Button, Checkbox } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import DistanceIcon from "../../../assets/distance.svg";
import DurationIcon from "../../../assets/duration.svg";
import { useUserDispatch, useUserState } from "../../../Context";
import { useHistory } from "react-router-dom";
import GoogleMaps from "./GoogleMap";
import Maestro from "../../../assets/maestro.svg";
import Jcb from "../../../assets/jcb.svg";
import Mastercard from "../../../assets/mastercard.svg";
import Americanexpress from "../../../assets/americanexpress.svg";
import Visa from "../../../assets/visacard.svg";
// import config from "../../../config";
// import { LoadScript } from "@react-google-maps/api";
import { Link } from "react-router-dom";

const JourneyMapDetails = ({ journeyEdit, data, distance, duration }) => {
  useEffect(() => {
    // console.log("duration",data);
  }, [])
  const formType = useUserState().formType

  return (
    <>
      <div className="flex justify-between align-center">
        <div className="text-base" id="journey-details-title">
          Journey Details
        </div>
        <div
          className="flex text-lg font-semibold cursor-pointer "
          onClick={() => journeyEdit()}
        >
          Edit
        </div>
      </div>
      <table
        className="journey-details-table"
        aria-describedby="journey-details-title"
      >
        <tbody>
          <tr>
            <th scope="col">
              <i className="pr-2 ri-map-pin-line"></i>From
            </th>
            <td>{data?.source?.label}</td>
          </tr>
          {formType !== 'hourly' &&
            < tr >
              <th scope="col">
                <i className="pr-2 ri-map-pin-line"></i>To
              </th>
              <td>{data?.destination?.label}</td>
            </tr>
          }
          <tr>
            <th scope="col">
              <i className="pr-2 ri-calendar-line"></i>Date
            </th>
            <td>{dayjs(new Date(data?.date)).format("DD/MM/YYYY")}</td>
          </tr>
          <tr>
            <th scope="col">
              <i className="pr-2 ri-time-line"></i>Time
            </th>
            <td>{dayjs(new Date(data?.time)).format("HH:mm")}</td>
          </tr>
          <tr>
            <th scope="col">
              <div className="flex">
                <img
                  src={formType !== 'hourly' ? DistanceIcon : DurationIcon}
                  className="journey-details-table__icon"
                  alt="icon"
                />
                {formType !== 'hourly' ? 'Distance' : 'Duration'}
              </div>
            </th>
            <td>{formType !== 'hourly' ? distance : duration}</td>
          </tr>
        </tbody>
      </table >
    </>
  );
};

const VehicleMapDetails = ({ page, vehicleEdit, duration, car }) => (
  <>
    {(page === "flight" || page === "passenger") && (
      <div className="py-2 border-t border-gray-50">
        <div className="flex justify-between py-4 ">
          <div className="text-base">Vehicle Details</div>
          <div
            className="flex text-lg font-semibold cursor-pointer "
            onClick={() => {
              vehicleEdit();
            }}
          >
            Edit
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex justify-between font-semibold">
            <span className="flex space-x-2">
              <img src={DurationIcon} width="10px" height="10px" alt="icon"/>
              <span>Duration</span>
            </span>
            <span>{duration}</span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>
              <i className="pr-2 ri-car-line"></i>Car
            </span>
            <span>{car}</span>
          </div>
        </div>
      </div>
    )}
  </>
);

const FlightDetails = ({ page, FlightEdit, data }) => (
  <>
    {page === "passenger" && (
      <div className="py-2 border-t border-gray-50">
        <div className="flex justify-between py-4 ">
          <div className="text-base">Flight Details</div>
          <div
            className="flex text-lg font-semibold cursor-pointer "
            onClick={() => {
              FlightEdit();
            }}
          >
            Edit
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex justify-between font-semibold">
            <span className="flex space-x-2">
              <img src={DurationIcon} width="10px" height="10px" alt="icon"/>
              <span>Flight Number</span>
            </span>
            <span>{data?.flightNumber}</span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>
              <i className="pr-2 ri-car-line"></i>Flight Arrival Time
            </span>
            <span>{new Date(data?.arrivalTime).toLocaleString()}</span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>
              <i className="pr-2 ri-car-line"></i>Buffer Time
            </span>
            <span>{data?.pickupText?.label}</span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>
              <i className="pr-2 ri-car-line"></i>Name on Board
            </span>
            <span>{data?.nameBoard}</span>
          </div>
        </div>
      </div>
    )}
  </>
);

const Map = ({ onTabChange, page, formRef }) => {
  // const history = useHistory();
  const userState = useUserState();
  const userDispatch = useUserDispatch();

  const [checkBox, setCheckbox] = useState(false);
  // const [verified, setVerified] = useState(false)

  const history = useHistory();

  // const onChange = () => {
  //   setVerified(true)
  // }

  const nextPage =
    page === "flight"
      ? "passenger"
      : page === "passenger"
        ? "payment"
        : "confirm";

  const handleSubmit = () => {
    if (formRef.current) {
      if (page === "passenger") {
        if (checkBox) {
          formRef.current.handleSubmit();
        }
      } else {
        formRef.current.handleSubmit();
      }
    }
  };

  const handleSkip = () => {
    userDispatch({ type: "SKIPFLIGHT" });
    onTabChange(nextPage);
  };

  useEffect(() => {
    // console.log("Here", page);
  }, []);

  const journeyEdit = () => {
    history.push("/");
  };

  const vehicleEdit = () => {
    history.push("/tabs/vehicle");
  };

  const FlightEdit = () => {
    history.push("/tabs/flight");
  };

  // console.debug("user", userState);

  return (
    <div className="">
      <div className="text-lg font-semibold">Your Transfers</div>
      <div className="mt-2 border border-gray ">
        {(page === "vehicle" || page === "flight" || page === "passenger") && userState.formType !== "hourly" && (
          <div className="w-full border-b border-gray-300">
            <GoogleMaps
              source={userState.journeyInfo.source.label}
              destination={userState.journeyInfo.destination.label}
            />
          </div>
        )}
        <div className="">
          <div className="space-y-3 px-4 py-6">
            <JourneyMapDetails
              journeyEdit={journeyEdit}
              data={userState.journeyInfo}
              distance={userState.distance}
              duration={userState.duration}
            />
            <VehicleMapDetails
              page={page}
              vehicleEdit={vehicleEdit}
              duration={userState?.duration}
              car={userState?.vechileInfo?.vehicle_cat_dt?.name}
            />
            {!userState.flightSkip && userState.journeyInfo.isAirport && (
              <FlightDetails
                FlightEdit={FlightEdit}
                page={page}
                data={userState.flightInfo}
              />
            )}
            {page !== "vehicle" &&
              !userState?.vechileInfo?.vehicle_cat_dt?.is_quote && (
                <div className="flex justify-between pt-4 font-semibold border-t border-gray-400">
                  <div className="text-lg">Total Price</div>
                  <div className="space-x-2 text-lg ">
                    <span className="px-2 py-1 text-black bg-white rounded-full">
                      {String.fromCharCode(163)}
                    </span>{" "}
                    <span>
                      {Math.round(userState.vechileInfo.fare * 100) / 100}
                    </span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-space-between my-2">
        {page === "passenger" && (
          <div className="flex items-start justify-center w-full py-4 space-x-2">
            <div>
              <Checkbox
                checked={checkBox}
                onChange={() => {
                  setCheckbox((state) => !state);
                }}
              />
            </div>
            <div className="flex flex-row flex-wrap">
              <p>
                Accept the &nbsp;
                <Link to="/terms-and-conditions" target="_blank">
                  Terms And Conditions
                </Link>
                &nbsp;and the&nbsp;
                <Link to="/privacy-policy" target="_blank">
                  Privacy policy
                </Link>
              </p>
            </div>
          </div>
        )}
        {page !== "vehicle" && (
          <div className="flex justify-between items-center w-full py-6 space-x-1 ">
            {page === "flight" && (
              <div className="w-52">
                <Button
                  label="Skip"
                  fullWidth
                  size="large"
                  onClick={() => handleSkip()}
                  className="mt-[10px]"
                />
              </div>
            )}
            <div className="w-52">
              {page === "passenger" &&
                !userState?.vechileInfo?.vehicle_cat_dt?.is_quote && (
                  <div className="flex p-4 space-x-2">
                    <img src={Americanexpress} width="25px" height="25px" alt="icon"/>
                    <img src={Mastercard} width="25px" height="25px" alt="icon"/>
                    <img src={Maestro} width="25px" height="25px" alt="icon"/>
                    <img src={Visa} width="25px" height="25px" alt="icon"/>
                    <img src={Jcb} width="25px" height="25px" alt="icon"/>
                  </div>
                )}
              <Button
                label={
                  page === "passenger"
                    ? !userState?.vechileInfo?.vehicle_cat_dt?.is_quote
                      ? "Pay Now"
                      : "Get Quote"
                    : "Continue"
                }
                fullWidth
                disabled={page === "passenger" && !checkBox}
                // disabled={page == "passenger" && !checkBox && verified}
                size="large"
                onClick={() => handleSubmit()}
                className="mt-[10px]"
              />
            </div>
          </div>
        )}
        {/* {page === "passenger" ? (
          <div>
            <p>We support the below payment methods</p>
            <div class="flex payment-logo justify-center">
              <img
                class="flex-auto pr-1"
                src={Maestro}
                width="150rem"
                height="52"
              />
              <img
                class="flex-auto pr-1"
                src={Jcb}
                width="150rem"
                height="52"
              />
              <img
                class="flex-auto pr-1"
                src={Mastercard}
                width="150rem"
                height="52"
              />
              <img
                class="flex-auto pr-1"
                src={Americanexpress}
                width="150rem"
                height="52"
              />
              <img
                class="flex-auto pr-1"
                src={Visa}
                width="150rem"
                height="52"
              />
            </div>
          </div>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
};
export default Map;
